import axiosInstance from 'api/axiosConfig'

import {
  PATH_SEGMENTS_CAR_PARKS,
  PARAMS_CAR_PARK,
  PARAMS_BARRIERS,
  RESIDENTIAL_PARKING_CAR_PARKS,
  CHARGEPOINT_CAR_PARKS,
} from 'api/carParkMgmt/carParkMgmtModels'
import { getQueryString } from 'util/core'
import { pack, packBarrier } from 'api/carParkMgmt/carParkMgmtUnpackers'
import { PARAMS_PAGINATION } from 'api/backendConstants'
/* -------------------------------------------------------------------------- */

/**
 * Api functions for car parks
 * @namespace carParksMgmtApi
 */

/**
 * Get all car parks
 * @function
 * @memberof carParksMgmtApi
 * @param {Object} params
 * @returns {Object[]}
 */
export const getCarParksReq = ({ cities, isVisible, operatorUuid, page, rows }) => {
  const { CITIES, IS_VISIBLE, OPERATOR_UUID, PAGE_NUMBER, PAGE_SIZE } = PARAMS_CAR_PARK
  const qs = getQueryString({
    [CITIES]: cities,
    [IS_VISIBLE]: isVisible,
    [OPERATOR_UUID]: operatorUuid,
    [PAGE_NUMBER]: page,
    [PAGE_SIZE]: rows,
  })
  const url = `${PATH_SEGMENTS_CAR_PARKS.CAR_PARKS_BASE}${qs}`
  return axiosInstance.get(url).then((res) => res.data)
}

/**
 * Get car park by uuid
 * @function
 * @memberof carParksMgmtApi
 * @param {string} uuid
 * @returns {Object}
 */
export const getCarParkByUuidReq = (uuid) =>
  axiosInstance.get(`${PATH_SEGMENTS_CAR_PARKS.CAR_PARKS_BASE}/${uuid}`).then((res) => {
    const { data: carPark } = res
    return carPark
  })

/**
 * Modify car park detail by uuid
 * @function
 * @memberof carParksMgmtApi
 * @param {string} uuid
 * @param {Object} carParkUpdatable
 * @returns {Object}
 */
export const editCarParkByUuidReq = (uuid, carParkUpdatable) => {
  const url = `${PATH_SEGMENTS_CAR_PARKS.CAR_PARKS_BASE}/${uuid}`

  const data = pack(carParkUpdatable)

  return axiosInstance.patch(url, data).then((res) => {
    const { data } = res
    return data
  })
}

/**
 * Create car park
 * @function
 * @memberof carParksMgmtApi
 * @param {Object} carParkCreatable
 * @returns {Object}
 */
export const createCarParkReq = (carParkCreatable) => {
  const url = PATH_SEGMENTS_CAR_PARKS.CAR_PARKS_BASE

  const data = pack(carParkCreatable)

  return axiosInstance.post(url, data).then((res) => {
    const { data } = res
    return data
  })
}

/* ----- BARRIERS ----------------------------------------------------------- */

/**
 * Get all barriers
 * @function
 * @memberof carParksMgmtApi
 * @param {Object} params
 * @returns {Object[]}
 */
export const getBarriersReq = ({ carParkUuids, isEntrance, page, rows, isOnline, operatorUuid, operatingState }) => {
  const { CAR_PARK_UUIDS, IS_ENTRANCE, IS_ONLINE, OPERATOR_UUID, PAGE_NUMBER, PAGE_SIZE, OPERATING_STATE } =
    PARAMS_BARRIERS
  const qs = getQueryString({
    [CAR_PARK_UUIDS]: carParkUuids,
    [IS_ENTRANCE]: isEntrance,
    [IS_ONLINE]: isOnline,
    [OPERATOR_UUID]: operatorUuid,
    [PAGE_NUMBER]: page,
    [PAGE_SIZE]: rows,
    [OPERATING_STATE]: operatingState,
  })

  const url = `${PATH_SEGMENTS_CAR_PARKS.BARRIERS_BASE}${qs}`
  return axiosInstance.get(url).then((res) => res.data)
}

/**
 * Get barrier detail by uuid
 * @function
 * @memberof carParksMgmtApi
 * @param {string} uuid
 * @returns {Object}
 */
export const getBarrierByIdReq = (uuid) =>
  axiosInstance.get(`${PATH_SEGMENTS_CAR_PARKS.BARRIERS_BASE}/${uuid}`).then((res) => {
    const { data: barrier } = res
    return barrier
  })

/**
 * Update barrier partially
 * @function
 * @memberof carParksMgmtApi
 * @param {string} uuid
 * @param {Object} barrierUpdatable
 * @returns {Object}
 */
export const editBarrierReq = (uuid, barrierUpdatable) => {
  const url = `${PATH_SEGMENTS_CAR_PARKS.BARRIERS_BASE}/${uuid}`
  const data = packBarrier(barrierUpdatable)

  return axiosInstance.patch(url, data).then((res) => {
    const { data: barrier } = res
    return barrier
  })
}

/* ----- RESIDENTIAL PARKING ----------------------------------------------------------- */

/**
 * Modify car park detail by adding userEmail to residential parking
 * @function
 * @memberof carParksMgmtApi
 * @param {string} userEmail
 * @param {string} carParkUuid
 * @returns {Object}
 */
export const addUseremailToCarParkResidentialParkingReq = (userEmail, carParkUuid) => {
  const url = `${RESIDENTIAL_PARKING_CAR_PARKS.ADD_SINGLE_USER_EMAIL_TO_RESIDENTIAL_PARKING}`

  const data = {
    userEmail: userEmail,
    carParkUuid: carParkUuid,
  }

  return axiosInstance.post(url, data).then((res) => {
    const { data } = res
    return data
  })
}

/**
 * Request all assignments for the given car parks uuid
 * @function
 * @memberof carParksMgmtApi
 * @param {string} assignmentUuid
 * @returns {Object}
 */
export const getCarParkResidentialParkingAssignmentsReq = ({ carParkUuid, page, rows }) => {
  console.log('getCarParkResidentialParkingAssignmentsReq')
  const { PAGE_NUMBER, PAGE_SIZE } = PARAMS_PAGINATION
  const qs = getQueryString({
    [PAGE_NUMBER]: page,
    [PAGE_SIZE]: rows,
  })

  const url = `${RESIDENTIAL_PARKING_CAR_PARKS.GET_CAR_PARK_RESIDENTIAL_PARKING_ASSIGNMENTS}${carParkUuid}${qs}`
  return axiosInstance.get(url).then((res) => {
    return res
  })
}

/**
 * Request all assignments for the given car parks uuid
 * @function
 * @memberof carParksMgmtApi
 * @param {string} assignmentUuid
 * @returns {Object}
 */
export const removeUserFromCarParkAssignment = (assignmentUuid) => {
  const url = `${RESIDENTIAL_PARKING_CAR_PARKS.GET_CAR_PARK_RESIDENTIAL_PARKING_ASSIGNMENTS}${assignmentUuid}`
  return axiosInstance.delete(url).then((res) => {
    return res
  })
}

/* ----- CHARGING ----------------------------------------------------------- */

/**
 * Request all assignments for the given car parks uuid
 * @function
 * @memberof carParksMgmtApi
 * @param {string} carparkUuid
 * @returns {Object}
 */
export const getChargepointForCarParkUuidReq = (carparkUuid) => {
  const url = `${CHARGEPOINT_CAR_PARKS.GET_CHARGEPOINT}${carparkUuid}`
  return axiosInstance.get(url).then((res) => {
    return res
  })
}
