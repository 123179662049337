import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import { SnackbarProvider } from 'notistack'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import lightTheme from 'themes/lightTheme'
import createStore from 'util/store'

import 'util/i18n'

/* -------------------------------------------------------------------------- */

export default function AppProviders(props: React.PropsWithChildren<{}>) {
  const { children } = props

  return (
    <HelmetProvider>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={lightTheme}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Provider store={createStore()}>
              <SnackbarProvider anchorOrigin={{ horizontal: 'right', vertical: 'top' }}>
                <Router>{children}</Router>
              </SnackbarProvider>
            </Provider>
          </LocalizationProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </HelmetProvider>
  )
}
