import React from 'react'
import * as PropTypes from 'prop-types'
import { Grid } from '@mui/material'

import OperatorGeneralCard from 'components/Operator/OperatorGeneralCard'
import OperatorContactCard from 'components/Operator/OperatorContactCard'
import OperatorStripeCard from 'components/Operator/OperatorStripeCard'
/* -------------------------------------------------------------------------- */

const OperatorGrid = (props) => {
  const { isCreate, isEdit } = props

  return (
    <>
      <Grid item xs={12} sm={12} md={4}>
        <OperatorGeneralCard isCreate={isCreate} isEdit={isEdit} />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <OperatorContactCard isCreate={isCreate} isEdit={isEdit} />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <OperatorStripeCard isCreate={isCreate} isEdit={isEdit} />
      </Grid>
    </>
  )
}

OperatorGrid.propTypes = {
  isEdit: PropTypes.bool,
  isCreate: PropTypes.bool,
}

OperatorGrid.defaultProps = {
  isEdit: false,
  isCreate: false,
}

export default OperatorGrid
