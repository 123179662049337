import React from 'react'
import { Event as EventIcon } from '@mui/icons-material'
import { IconButton, InputAdornment } from '@mui/material'
import * as PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { DateTimePicker } from '@mui/x-date-pickers'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { localeMap } from 'util/i18n'
/* -------------------------------------------------------------------------- */

const CustomDateTimePicker = (props) => {
  const { disableFuture, disablePast, label, onChange, inputVariant, dateType, clearable } = props
  const [open, setOpen] = React.useState(false)
  const [value, setValue] = React.useState(Date.now())
  const { t, i18n } = useTranslation()

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={localeMap[i18n.language]}>
      <DateTimePicker
        showToolbar={true}
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        label={label || t('CustomDateTimePicker.dateAndTime')}
        value={value}
        onChange={(newValue) => {
          onChange(newValue)
          setValue(newValue)
        }}
        onAccept={(value) => {
          dateType ? onChange(value) : onChange(value.toISOString())
        }}
        hideTabs={false}
        slotProps={{ textField: { variant: inputVariant } }}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton size='large' onClick={handleOpen}>
                <EventIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        ampm={false}
        showTodayButton
        disableFuture={disableFuture}
        disablePast={disablePast}
        okText={t('CustomDateTimePicker.ok')}
        cancelText={t('CustomDateTimePicker.cancel')}
        todayText={t('CustomDateTimePicker.today')}
        clearable={clearable}
        clearText={t('CustomDateTimePicker.delete')}
      />
    </LocalizationProvider>
  )
}
/* -------------------------------------------------------------------------- */
CustomDateTimePicker.propTypes = {
  date: PropTypes.string,
  disableFuture: PropTypes.bool,
  disablePast: PropTypes.bool,
  errorText: PropTypes.string,
  hasError: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  inputVariant: PropTypes.string,
  dateType: PropTypes.bool,
  clearable: PropTypes.bool,
}

CustomDateTimePicker.defaultProps = {
  date: null,
  disableFuture: true,
  disablePast: false,
  errorText: null,
  hasError: false,
  helperText: null,
  label: null,
  inputVariant: 'standard',
  dateType: false,
  clearable: false,
}
export default CustomDateTimePicker
