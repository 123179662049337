import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-mui'
import { Grid, Button, Link as MuiLink } from '@mui/material'
import { MailOutline as MailOutlineIcon } from '@mui/icons-material'

import AuthPageContainer from 'layouts/AuthPageContainer/AuthPageContainer'
import { resetPasswordRequestAct, resetAccountMgmtFlagsAct } from 'redux/Actions'
import ROUTES from 'routes/routes'
import { hasError } from 'util/core'
import { initialValues, validationSchema } from 'components/Password/resetPasswordRequestForm'

const buttonStyle = {
  borderRadius: '20px',
  textTransform: 'none',
  color: '#fff',
}

const ResetPasswordRequestContainer = () => {
  const { errResetPasswordRequesting, isResetPasswordRequestingSuccess, isResetPasswordRequesting } = useSelector(
    (state) => state.account,
  )
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const navigate = useNavigate()
  const location = useLocation()
  const emailHistory = navigate?.location?.state?.email

  useEffect(() => {
    if (isResetPasswordRequestingSuccess) {
      enqueueSnackbar(t('ResetPasswordRequestContainer.checkEmail'), { variant: 'success' })
      navigate(
        {
          pathname: ROUTES.AUTH.SIGN_IN,
        },
        { state: { from: location } },
      )
    } else if (hasError(errResetPasswordRequesting)) {
      enqueueSnackbar(t('ResetPasswordRequestContainer.unknownEmail'), { variant: 'error' })
    }
    return () => dispatch(resetAccountMgmtFlagsAct())
  }, [dispatch, enqueueSnackbar, errResetPasswordRequesting, navigate, isResetPasswordRequestingSuccess, t, location])

  const handleSubmit = (values) => dispatch(resetPasswordRequestAct(values))

  return (
    <AuthPageContainer isLoading={isResetPasswordRequesting}>
      <Formik
        initialValues={initialValues(emailHistory)}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {(formikProps) => {
          const { isValidating, isSubmitting, isValid } = formikProps
          return (
            <Form>
              <Grid container justifyContent='center' spacing={3}>
                <Grid item xs={10} sm={8}>
                  <Grid container spacing={1} alignItems='flex-end'>
                    <Grid item>
                      <MailOutlineIcon />
                    </Grid>
                    <Grid item xs>
                      <Field
                        component={TextField}
                        type='email'
                        name='email'
                        autoComplete='username'
                        label={t('ResetPasswordRequestContainer.email')}
                        fullWidth
                        variant='standard'
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container justifyContent='flex-end' item xs={10} sm={8}>
                  <MuiLink
                    component={RouterLink}
                    to={ROUTES.AUTH.SIGN_IN}
                    state={{ email: formikProps?.values?.email, from: location }}
                    underline='hover'
                    variant='body2'
                  >
                    {t('ResetPasswordRequestContainer.logIn')}
                  </MuiLink>
                </Grid>
                <Grid item xs={10} sm={8}>
                  <Button
                    type='submit'
                    disabled={isValidating || isSubmitting || !isValid || !formikProps?.values?.email}
                    color='primary'
                    variant='contained'
                    fullWidth
                    sx={buttonStyle}
                  >
                    {t('ResetPasswordRequestContainer.resetPassword')}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )
        }}
      </Formik>
    </AuthPageContainer>
  )
}

export default ResetPasswordRequestContainer
