import React from 'react'
import * as PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Check as CheckIcon, Settings as SettingsIcon, Build as BuildIcon } from '@mui/icons-material'

import IconChip from 'fragments/IconChips/IconChip'
import { BARRIER_STATE_VALUES } from 'api/carParkMgmt/carParkMgmtModels'
import { getBarrierOperatingStateAsString } from 'util/custom'
/* -------------------------------------------------------------------------- */

const OperatingStateChip = (props) => {
  const { t } = useTranslation()
  const { state } = props

  const { notInstalled, operating, underMaintenance } = BARRIER_STATE_VALUES

  const obj = {
    [notInstalled]: {
      icon: <BuildIcon style={{ color: 'white' }} />,
      style: { color: 'white', background: 'grey' },
    },
    [underMaintenance]: {
      icon: <SettingsIcon style={{ color: 'white' }} />,
      style: { color: 'white', background: '#f5b942' },
    },
    [operating]: {
      icon: <CheckIcon style={{ color: 'white' }} />,
      style: { color: 'white', background: '#3ccc37' },
    },
  }

  return (
    <IconChip
      icon={obj?.[state]?.icon ?? null}
      label={getBarrierOperatingStateAsString(t, state)}
      chipStyle={obj?.[state]?.style ?? null}
    />
  )
}

OperatingStateChip.propTypes = {
  state: PropTypes.string.isRequired,
}

export default OperatingStateChip
