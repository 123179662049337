import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import * as PropTypes from 'prop-types'
import { Grid } from '@mui/material'

import { BasicPageContainerLoadable } from 'fragments/Loadables'
import PageGrid from 'fragments/Grid/PageGrid'
import ErrorAlertContainer from 'fragments/Alerts/ErrorAlertContainer'
import SalesFilterCard from 'components/Sales/SalesFilterCard'
import SalesTabBar from 'components/Sales/SalesTabBar'
import { resetSalesFiltersAct } from 'redux/Actions'
/* -------------------------------------------------------------------------- */

const SalesContainer = ({ children }) => {
  const { errGettingSales } = useSelector((state) => state.sales)
  const { selectedOperator } = useSelector((state) => state.globalFilters)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const location = useLocation()

  const lastLocation = location.state?.from

  const baseSegmentCurrentLocation = (location?.pathname ?? '').split('/')[1]
  const baseSegmentLastLocation = (lastLocation?.pathname ?? '').split('/')[1]

  useEffect(() => {
    if (baseSegmentCurrentLocation !== baseSegmentLastLocation) {
      dispatch(resetSalesFiltersAct())
    }
  }, [baseSegmentLastLocation, baseSegmentCurrentLocation, dispatch, selectedOperator])

  return (
    <BasicPageContainerLoadable helmetTitle={t('SalesContainer.title')} pageTitle={t('SalesContainer.title')}>
      <PageGrid>
        <Grid item xs={12}>
          <SalesTabBar />
        </Grid>
        <ErrorAlertContainer errors={[errGettingSales]} />
        <Grid item xs={12}>
          <SalesFilterCard />
        </Grid>
        {children}
      </PageGrid>
    </BasicPageContainerLoadable>
  )
}

SalesContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
}

export default SalesContainer
