import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Grid, Button } from '@mui/material'
import { Link, useLocation } from 'react-router-dom'

import PageGrid from 'fragments/Grid/PageGrid'
import { BasicPageContainerLoadable } from 'fragments/Loadables'
import ErrorAlertContainer from 'fragments/Alerts/ErrorAlertContainer'
import OperatorsTableCard from 'components/Operators/OperatorsTableCard'
import { APP } from 'routes/routes'
import LastUpdateInfo from 'fragments/Misc/LastUpdateInfo'
/* -------------------------------------------------------------------------- */

const OperatorsContainer = () => {
  const { t } = useTranslation()
  const { errGettingOperators, lastSuccessDate } = useSelector((state) => state.operators)
  const location = useLocation()

  const createOperator = () => (
    <Button
      type='button'
      component={Link}
      to={APP.OPERATOR_CREATE}
      state={{ from: location }}
      color='primary'
      variant='outlined'
      style={{ textTransform: 'none' }}
    >
      {t('OperatorContainer.createOperator')}
    </Button>
  )

  return (
    <BasicPageContainerLoadable
      helmetTitle={t('OperatorsContainer.operators')}
      pageTitle={t('OperatorsContainer.operators')}
      titleBarEndAdornment={createOperator}
    >
      <ErrorAlertContainer errors={[errGettingOperators]} />
      <PageGrid>
        <Grid item xs={12}>
          <OperatorsTableCard />
        </Grid>
        <Grid item xs={12}>
          <LastUpdateInfo lastSuccessDate={lastSuccessDate} />
        </Grid>
      </PageGrid>
    </BasicPageContainerLoadable>
  )
}

export default OperatorsContainer
