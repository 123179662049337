import React, { useEffect } from 'react'
import { styled } from '@mui/material/styles'
import { useSelector, useDispatch } from 'react-redux'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { Formik, Form, Field } from 'formik'
import { Checkbox } from 'formik-mui'
import { Grid, FormControlLabel, Button, Typography, Box, Link as MuiLink } from '@mui/material'
import { LockOutlined as LockOutlinedIcon } from '@mui/icons-material'

import AuthPageContainer from 'layouts/AuthPageContainer/AuthPageContainer'
import PasswordField from 'fragments/Forms/Formik/PasswordField'
import { initialValues, validationSchema } from 'components/Admin/Activate/activateAdminForm'
import ROUTES, { QUERY_PARAMS } from 'routes/routes'
import { getParsedQueryParam, jwtDecodeClaim } from 'util/core'
import { TOKEN_CLAIMS } from 'api/backendConstants'
import { activateAdminAct } from 'redux/Actions'
import { isResolved, isPending, isRejected } from 'redux/Status'
const PREFIX = 'ActivateAdminContainer'

const classes = {
  button: `${PREFIX}-button`,
  icon: `${PREFIX}-icon`,
  checkbox: `${PREFIX}-checkbox`,
  root: `${PREFIX}-root`,
}

const StyledAuthPageContainer = styled(AuthPageContainer)(({ theme }) => ({
  [`& .${classes.button}`]: {
    borderRadius: 20,
    textTransform: 'none',
    color: 'white',
  },

  [`& .${classes.icon}`]: {
    paddingRight: theme.spacing(1),
  },

  [`& .${classes.checkbox}`]: {
    marginTop: -9,
    marginLeft: 2,
  },

  [`& .${classes.root}`]: {
    alignItems: 'flex-start',
  },
}))

const ActivateAdminContainer = () => {
  const { statusActivatingAdmin } = useSelector((state) => state.account)
  const { statusLoggingIn } = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const location = useLocation()
  const { enqueueSnackbar } = useSnackbar()
  const emailHistory = location.state?.email
  const token = getParsedQueryParam(location.search, QUERY_PARAMS.TOKEN)
  const email = jwtDecodeClaim(token, TOKEN_CLAIMS.EMAIL)

  useEffect(() => {
    if (isResolved(statusActivatingAdmin)) {
      enqueueSnackbar(t('ActivateAdminContainer.success'), { variant: 'success' })
    }
    if (isRejected(statusActivatingAdmin)) {
      enqueueSnackbar(t('ActivateAdminContainer.errorActivation'), { variant: 'error' })
    }
  }, [enqueueSnackbar, statusActivatingAdmin, t])

  const handleSubmit = ({ password }) => dispatch(activateAdminAct({ password, token }))

  const form = () => (
    <Formik
      initialValues={initialValues(emailHistory)}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {(formikProps) => {
        const { isValidating, isSubmitting, isValid, dirty } = formikProps
        return (
          <Form>
            <Grid container justifyContent='center' spacing={3}>
              <Grid item xs={12} sm={8}>
                <Typography variant='body1' style={{ padding: '12px 0px 12px 0px' }}>
                  {t('ActivateAdminContainer.info')}
                </Typography>
                <Box fontWeight='fontWeightBold'>{email}</Box>
                <Grid container>
                  <Grid container alignItems='flex-end'>
                    <Grid item className={classes.icon}>
                      <LockOutlinedIcon />
                    </Grid>
                    <Grid item xs>
                      <PasswordField
                        name='password'
                        autoComplete='new-password'
                        label={t('ActivateAdminContainer.password')}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Box p={2} />
                  <FormControlLabel
                    classes={{ root: classes.root }}
                    control={
                      <Field
                        component={Checkbox}
                        name='acceptedPrivacyPolicy'
                        color='primary'
                        className={classes.checkbox}
                        type='checkbox'
                      />
                    }
                    label={
                      <Trans i18nKey='ActivateAdminContainer.privacyPolicy'>
                        <MuiLink
                          href='https://trivepark.de/datenschutzerklaerung/'
                          id='datenschutzerklaerung'
                          target='_blank'
                          rel='noopener noreferrer'
                          underline='hover'
                        />
                      </Trans>
                    }
                  />
                </Grid>
              </Grid>
              <Grid item xs={10} sm={8}>
                <Button
                  type='submit'
                  disabled={isValidating || isSubmitting || !isValid || !dirty}
                  className={classes.button}
                  color='primary'
                  variant='contained'
                  fullWidth
                >
                  {t('ActivateAdminContainer.activate')}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )

  const errorInfo = () => (
    <Grid container justifyContent='center' spacing={3}>
      <Grid item xs={10} sm={8}>
        <Typography variant='h5'>{t('ActivateAdminContainer.errorLoginTitle')}</Typography>
      </Grid>
      <Grid item xs={10} sm={8}>
        <Typography variant='body1'>{t('ActivateAdminContainer.errorLogin')}</Typography>
      </Grid>
      <Grid container justifyContent='flex-end' item xs={10} sm={8}>
        <MuiLink component={RouterLink} to={ROUTES.AUTH.SIGN_IN} state={{ email, from: location }} underline='hover'>
          {t('ActivateAdminContainer.login')}
        </MuiLink>
      </Grid>
    </Grid>
  )

  return (
    <StyledAuthPageContainer isLoading={isPending(statusActivatingAdmin) || isPending(statusLoggingIn)}>
      {isResolved(statusActivatingAdmin) && isRejected(statusLoggingIn) ? errorInfo() : form()}
    </StyledAuthPageContainer>
  )
}

export default ActivateAdminContainer
