import React from 'react'
import { useSelector } from 'react-redux'
import * as PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { ContactPhone as ContactPhoneIcon } from '@mui/icons-material'

import { BasicCardLoadable } from 'fragments/Loadables'
import InfoRows from 'fragments/Cards/InfoRows'
import TextField from 'fragments/Forms/Formik/TextField'
/* -------------------------------------------------------------------------- */

const OperatorContactCard = (props) => {
  const {
    operator: { street, houseNumber, postalCode, city, phoneNumber, email },
    isGettingOfferByUuid,
  } = useSelector((state) => state.operator)
  const { t } = useTranslation()
  const { isCreate, isEdit } = props

  const inputPostalCodeAndCity = () => (
    <span style={{ display: 'flex' }}>
      <TextField name='postalCode' placeholder={t('OperatorContactCard.postalCode')} variant='standard' />
      <TextField name='city' placeholder={t('OperatorContactCard.city')} variant='standard' />
    </span>
  )

  const inputStreetAndHouseNumber = () => (
    <span style={{ display: 'flex' }}>
      <TextField name='street' placeholder={t('OperatorContactCard.street')} variant='standard' />
      <TextField name='houseNumber' placeholder={t('OperatorContactCard.houseNumber')} variant='standard' />
    </span>
  )

  const rows = [
    {
      label: t('OperatorContactCard.address') + (isCreate || isEdit ? ' *' : ''),
      value: isCreate || isEdit ? inputStreetAndHouseNumber() : street && houseNumber && `${street} ${houseNumber}`,
    },
    {
      label: '',
      value: isCreate || isEdit ? inputPostalCodeAndCity() : postalCode && city && `${postalCode} ${city}`,
    },
    {
      label: t('OperatorContactCard.phoneNumber'),
      value: isCreate || isEdit ? <TextField name='phoneNumber' variant='standard' /> : phoneNumber,
    },
    {
      label: t('OperatorContactCard.email') + (isCreate || isEdit ? ' *' : ''),
      value: isCreate || isEdit ? <TextField name='email' variant='standard' /> : email,
    },
  ]

  return (
    <BasicCardLoadable
      title={t('OperatorContactCard.title')}
      action={<ContactPhoneIcon />}
      isLoading={isGettingOfferByUuid}
    >
      <Grid container spacing={1}>
        <InfoRows rows={rows} />
      </Grid>
    </BasicCardLoadable>
  )
}

OperatorContactCard.propTypes = {
  isEdit: PropTypes.bool,
  isCreate: PropTypes.bool,
}

OperatorContactCard.defaultProps = {
  isEdit: false,
  isCreate: false,
}

export default OperatorContactCard
