import React from 'react'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { reverse } from 'lodash'
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material'

import { BasicCardLoadable } from 'fragments/Loadables'
import TicketPaymentRow from 'components/Ticket/TicketPaymentRow'
import DotChip from 'fragments/IconChips/DotChip'
import { Ticket } from 'services/tickets/tickets-service'
const PREFIX = 'TicketPaymentsCard'

const classes = {
  scroll: `${PREFIX}-scroll`,
  itemIcon: `${PREFIX}-itemIcon`,
}

const StyledBasicCardLoadable = styled(BasicCardLoadable)(({ theme }) => ({
  [`& .${classes.scroll}`]: {
    minHeight: 130,
    maxHeight: 410,
    overflow: 'auto',
  },

  [`& .${classes.itemIcon}`]: {
    minWidth: 12 + parseInt(theme.spacing(1)),
  },
}))

/* -------------------------------------------------------------------------- */

type TicketPaymentsCardProps = {
  isLoading: boolean
  ticket?: Ticket
}

export default function TicketPaymentsCard(props: TicketPaymentsCardProps) {
  const { isLoading, ticket } = props
  const { t } = useTranslation()

  const payments = ticket?.payments ?? []

  const totalPriceInCent = ticket?.totalPriceInCent ?? 0
  const paidPriceInCent = ticket?.paidPriceInCent ?? 0

  const remainingAmountInCentToPay = totalPriceInCent - paidPriceInCent

  return (
    <StyledBasicCardLoadable title={t('TicketPaymentsCard.title')} isLoading={isLoading}>
      <p>{t('TicketPaymentsCard.totalCost', { cent: totalPriceInCent })}</p>
      <div className={classes.scroll}>
        <List>
          {remainingAmountInCentToPay ? (
            <ListItem>
              <ListItemIcon classes={{ root: classes.itemIcon }}>
                <DotChip color={'#ffb70f'} />
              </ListItemIcon>
              <ListItemText
                primary={t('TicketPaymentsCard.upcomingPayment', {
                  date: new Date().toISOString(),
                  amount: remainingAmountInCentToPay,
                })}
                primaryTypographyProps={{ variant: 'body2' }}
              />
            </ListItem>
          ) : null}
          {reverse([...payments]).map((payment) => (
            <TicketPaymentRow key={payment.uuid} payment={payment} />
          ))}
        </List>
      </div>
    </StyledBasicCardLoadable>
  )
}
