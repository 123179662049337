import React, { useEffect, useCallback, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useNavigate, useLocation } from 'react-router-dom'

import { APP, QUERY_PARAMS } from 'routes/routes'
import { getQueryString, getParsedQueryParam } from 'util/core'
/* -------------------------------------------------------------------------- */

const OldOfferSnackbar = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const { newestVersionUuid } = useSelector((state) => state.offer.offer)
  const snackbarKey = useRef()

  const uuid = getParsedQueryParam(location.search, QUERY_PARAMS.UUID)

  const versionUuid = getParsedQueryParam(location.search, QUERY_PARAMS.VERSION)
  const isOldVersion = !!newestVersionUuid && !!versionUuid && newestVersionUuid !== versionUuid

  const action = useCallback(
    (key) => (
      <>
        <Button
          onClick={() => {
            navigate(
              {
                pathname: APP.OFFER,
                search: getQueryString({ [QUERY_PARAMS.UUID]: uuid }),
              },
              { state: { from: location } },
            )
          }}
        >
          {t('OldOfferSnackbar.switchToNewestOffer').toUpperCase()}
        </Button>
        <Button onClick={() => closeSnackbar(key)}>{t('OldOfferSnackbar.dismissNotification').toUpperCase()}</Button>
      </>
    ),
    [closeSnackbar, navigate, t, uuid, location],
  )

  useEffect(() => {
    if (isOldVersion) {
      snackbarKey.current = enqueueSnackbar(t('OldOfferSnackbar.oldVersion'), {
        anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
        variant: 'info',
        persist: true,
        action,
      })
    }
    return () => {
      if (isOldVersion) {
        closeSnackbar(snackbarKey.current)
      }
    }
  }, [action, closeSnackbar, enqueueSnackbar, isOldVersion, t])

  return null
}

export default OldOfferSnackbar
