import React from 'react'
import { useSelector } from 'react-redux'
import * as PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { ContactPhone as ContactPhoneIcon } from '@mui/icons-material'

import { BasicCardLoadable } from 'fragments/Loadables'
import InfoRows from 'fragments/Cards/InfoRows'
import TextField from 'fragments/Forms/Formik/TextField'
/* -------------------------------------------------------------------------- */

const InfoContactCard = (props) => {
  const {
    isGettingCarPark,
    carPark: { telephoneNumber: phone, houseNumber, city, postalCode, street },
  } = useSelector((state) => state.carPark)
  const { t } = useTranslation()
  const { isEdit, isCreate } = props

  const inputPhoneNumber = () => <TextField name='telephoneNumber' variant='standard' />

  const inputPostalCodeAndCity = () => (
    <span style={{ display: 'flex' }}>
      <TextField name='postalCode' placeholder={t('InfoContactCard.postalCode')} variant='standard' />
      <TextField name='city' placeholder={t('InfoContactCard.city')} variant='standard' />
    </span>
  )

  const inputStreetAndHouseNumber = () => (
    <span style={{ display: 'flex' }}>
      <TextField name='street' placeholder={t('InfoContactCard.street')} variant='standard' />
      <TextField name='houseNumber' placeholder={t('InfoContactCard.houseNumber')} variant='standard' />
    </span>
  )

  const rows = [
    {
      label: t('InfoContactCard.address'),
      value: isEdit || isCreate ? inputStreetAndHouseNumber() : street && houseNumber && `${street} ${houseNumber}`,
    },
    {
      label: '',
      value: isEdit || isCreate ? inputPostalCodeAndCity() : postalCode && city && `${postalCode} ${city}`,
    },
    {
      label: t('InfoContactCard.telephone'),
      value: isEdit || isCreate ? inputPhoneNumber() : phone,
    },
  ]

  return (
    <BasicCardLoadable title={t('InfoContactCard.title')} action={<ContactPhoneIcon />} isLoading={isGettingCarPark}>
      <Grid container spacing={1}>
        <InfoRows rows={rows} />
      </Grid>
    </BasicCardLoadable>
  )
}

InfoContactCard.propTypes = {
  isEdit: PropTypes.bool,
  isCreate: PropTypes.bool,
}

InfoContactCard.defaultProps = {
  isEdit: false,
  isCreate: false,
}

export default InfoContactCard
