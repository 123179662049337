import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'

import { BasicCardLoadable } from 'fragments/Loadables'
import MuiDataTableThemed from 'fragments/tables/MuiDataTableThemed'
import options from 'components/BarrierOpenings/barrierOpeningsTableOptions'
import columns from 'components/BarrierOpenings/BarrierOpeningsTableColumns'
import { isTriveParkAdmin } from 'util/custom'
import { BarrierOpening } from 'services/barrier-openings/barrier-openings-service'
import { useNavigate } from 'react-router-dom'
/* -------------------------------------------------------------------------- */

type BarrierOpeningsTableCardProps = {
  barrierOpenings: BarrierOpening[]
  page?: number
  setPage?: (n: number) => void
  rows?: number
  setRows?: (n: number) => void
  totalCount?: number
  isLoading: boolean
  dashboardUsage?: boolean
}

type RootState = {
  globalFilters: {
    selectedOperator: string
  }
  auth: {
    user: {
      role: string
    }
  }
}

export default function BarrierOpeningsTableCard(props: BarrierOpeningsTableCardProps) {
  const { barrierOpenings = [], page, setPage, rows, setRows, totalCount, isLoading, dashboardUsage } = props
  const { enqueueSnackbar } = useSnackbar()
  const { selectedOperator } = useSelector((state: RootState) => state.globalFilters)
  const {
    user: { role },
  } = useSelector((state: RootState) => state.auth)
  let navigate = useNavigate()
  const { t } = useTranslation()
  const isTpAdmin = !selectedOperator && isTriveParkAdmin(role)

  return (
    <BasicCardLoadable
      isLoading={isLoading}
      title={dashboardUsage ? t('BarrierOpeningsTableCard.lastOpenings') : undefined}
    >
      <MuiDataTableThemed
        data={barrierOpenings}
        columns={columns({ barrierOpenings, isTpAdmin, t })}
        options={options({
          barrierOpenings,
          dashboardUsage,
          enqueueSnackbar,
          navigate,
          onChangePage: setPage,
          onChangeRowsPerPage: setRows,
          page,
          rows,
          t,
          totalCount,
        })}
      />
    </BasicCardLoadable>
  )
}
