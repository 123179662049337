import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { Grid } from '@mui/material'

import { BasicPageContainerLoadable } from 'fragments/Loadables'
import ErrorAlertContainer from 'fragments/Alerts/ErrorAlertContainer'
import PageGrid from 'fragments/Grid/PageGrid'
import AppUserTabBar from 'components/AppUser/AppUserTabBar'
import AppUserIdentity from 'components/AppUser/AppUserIdentity'
import BarrierConnectionsTable from 'components/AppUser/BarrierConnections/BarrierConnectionsTable'
import { getAppUserByUuidAct, resetAppUserAct } from 'redux/Actions'
import { getParsedQueryParam } from 'util/core'
import { QUERY_PARAMS } from 'routes/routes'
/* -------------------------------------------------------------------------- */
const BarrierConnectionsContainer = () => {
  const { errGettingAppUser, isGettingAppUser } = useSelector((state) => state.appUser)
  const dispatch = useDispatch()
  const location = useLocation()
  const { t } = useTranslation()

  const uuid = getParsedQueryParam(location.search, QUERY_PARAMS.UUID)

  useEffect(() => {
    dispatch(getAppUserByUuidAct(uuid))
    return () => {
      dispatch(resetAppUserAct())
    }
  }, [dispatch, uuid])

  return (
    <BasicPageContainerLoadable
      helmetTitle={t('BarrierConnectionsContainer.title')}
      pageTitle={t('BarrierConnectionsContainer.title')}
      isLoading={isGettingAppUser}
    >
      <ErrorAlertContainer errors={[errGettingAppUser]} />
      <PageGrid>
        <Grid item xs={12}>
          <AppUserTabBar />
        </Grid>
        <Grid item xs={12}>
          <AppUserIdentity />
        </Grid>
        <Grid item xs={12}>
          <BarrierConnectionsTable />
        </Grid>
      </PageGrid>
    </BasicPageContainerLoadable>
  )
}

export default BarrierConnectionsContainer
