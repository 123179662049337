import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'

import BarrierInfoCard from 'components/Barrier/BarrierInfoCard'
import BarrierStateCard from 'components/Barrier/BarrierStateCard'
import BarrierAddressCard from 'components/Barrier/BarrierAddressCard'
import BarrierUpdateCard from 'components/Barrier/BarrierUpdateCard'
import BarrierControlCard from 'components/Barrier/BarrierControlCard'
/* -------------------------------------------------------------------------- */

const BarrierGrid = (props) => {
  const { isEdit } = props

  return (
    <>
      <Grid item xs={12} sm={6} md={4}>
        <BarrierInfoCard isEdit={isEdit} />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <BarrierStateCard isEdit={isEdit} />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <BarrierAddressCard isEdit={isEdit} />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <BarrierUpdateCard isEdit={isEdit} />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <BarrierControlCard isEdit={isEdit} />
      </Grid>
    </>
  )
}

BarrierGrid.propTypes = {
  isEdit: PropTypes.bool,
}

BarrierGrid.defaultProps = {
  isEdit: false,
}

export default BarrierGrid
