import { createTheme, responsiveFontSizes } from '@mui/material/styles'
/* -------------------------------------------------------------------------- */

const baseTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#26a1f1',
    },
    secondary: {
      main: '#677080',
    },
    background: {
      default: '#F4F6F7',
    },
  },
  typography: {
    fontFamily: ['Lato', 'Helvetica', 'Arial', 'sans-serif'].join(','),
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '0.75rem',
        },
        defaultProps: {
          enterDelay: 1000,
        },
      },
    },
    MUIDataTableBodyRow: {
      styleOverrides: {
        hover: {
          cursor: 'pointer',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontWeight: 600,
          color: '#464d69',
        },
        root: {
          padding: '8px 0px 8px 24px',
        },
      },
    },
    // mui-datatables
    MUIDataTableToolbar: {
      styleOverrides: {
        root: {
          minHeight: 56,
          maxHeight: 64,
        },
      },
    },
    MUIDataTableHeadRow: {
      styleOverrides: {
        root: {
          height: 48,
        },
      },
    },
    MUIDataTable: {
      styleOverrides: {
        responsiveScroll: {
          maxHeight: 'none',
        },
      },
    },
    MUIDataTableHeadCell: {
      styleOverrides: {
        fixedHeader: {
          zIndex: '0',
          paddingTop: 4,
        },
      },
    },
    MUIDataTablePagination: {
      styleOverrides: {
        root: {
          maxHeight: 48,
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          zIndex: '1300',
        },
      },
    },
  },
})

const custom = {
  header: {
    background: '#fff',
  },
  sidebar: {
    width: 260,
    widthCollapsed: 65,
    background: 'linear-gradient(#26a1f1, #0074bf)',
    color: '#f2f2f2',
    active: 'rgba(0, 0, 0, 0.1)',
    logoBackground: '#0074bf',
  },
}

const lightTheme = {
  ...baseTheme,
  ...custom,
}

export default responsiveFontSizes(lightTheme)
