import React from 'react'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
const PREFIX = 'NoRole'

const classes = {
  wrapper: `${PREFIX}-wrapper`,
}

const Root = styled('div')({
  [`&.${classes.wrapper}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
})

const NoRole = () => {
  const { t } = useTranslation()

  return (
    <Root className={classes.wrapper}>
      <h1>{t('NoRole.youHaveNoRole')}</h1>
      <p>{t('NoRole.contactAdmin')}</p>
    </Root>
  )
}

export default NoRole
