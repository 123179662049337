import React from 'react'
import * as PropTypes from 'prop-types'
import { TextField } from 'formik-mui'
import { Field } from 'formik'
/* -------------------------------------------------------------------------- */
const NumberField = (props) => {
  const { endAdornment, name, label, max, min, step, variant, placeholder } = props

  return (
    <Field
      component={TextField}
      name={name}
      type='number'
      label={label}
      fullWidth
      variant={variant}
      placeholder={placeholder}
      InputProps={{
        inputProps: { min, max, step },
        type: 'number',
        endAdornment,
      }}
    />
  )
}
/* -------------------------------------------------------------------------- */
NumberField.propTypes = {
  endAdornment: PropTypes.shape(),
  label: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number,
  name: PropTypes.string.isRequired,
  step: PropTypes.number,
  variant: PropTypes.string,
  placeholder: PropTypes.string,
}

NumberField.defaultProps = {
  endAdornment: null,
  label: null,
  max: null,
  min: null,
  step: null,
  placeholder: null,
  variant: 'outlined',
}

export default NumberField
