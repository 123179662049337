import React from 'react'
import { styled } from '@mui/material/styles'
import { TFunction, useTranslation } from 'react-i18next'
import { ListItem, ListItemIcon, ListItemText } from '@mui/material'

import DotChip from 'fragments/IconChips/DotChip'
import { getTimeDifferenceInSec } from 'util/core'
import { getBarrierOpeningStatusObj, getBarrierOpeningStatusLabel } from 'util/custom'
import { BarrierOpening, BarrierOpeningsStatus } from 'services/barrier-openings/barrier-openings-service'
const PREFIX = 'TicketBarrierOpeningRow'

const classes = {
  itemIcon: `${PREFIX}-itemIcon`,
}

const StyledListItem = styled(ListItem)(({ theme }) => ({
  [`& .${classes.itemIcon}`]: {
    minWidth: 12 + parseInt(theme.spacing(1)),
  },
}))

/* -------------------------------------------------------------------------- */

type TicketBarrierOpeningRowProps = {
  opening: BarrierOpening
}

function getAccessType(t: TFunction, isEntrance?: boolean) {
  return isEntrance ? t('barrierOpening.entrance') : t('barrierOpening.exit')
}

function isManualOpening(status: BarrierOpeningsStatus) {
  return ['MANUAL_UNSPECIFIED', 'MANUAL_BARRIER_ERROR', 'MANUAL_APP_ERROR', 'MANUAL_NO_PERMISSION'].includes(status)
}

export default function TicketBarrierOpeningRow(props: TicketBarrierOpeningRowProps) {
  const { opening } = props
  const { t } = useTranslation()

  const barrierNumber = opening?.barrier?.beaconMinor || ''
  const accessType = getAccessType(t, opening?.isEntrance)
  const activityString = t('TicketBarrierOpeningRow.activityRow', {
    date: opening?.processStartAt,
    accessType,
    barrierNumber,
  })
  const processTimeInS = getTimeDifferenceInSec(opening?.processEndAt, opening?.processStartAt)
  const { color, label, info: errorMessage } = getBarrierOpeningStatusObj(t, opening?.status)

  const renderStatusError = () =>
    errorMessage && (
      <>
        <span>{`${t('TicketBarrierOpeningRow.failure')} ${errorMessage}`}</span>
        <br />
      </>
    )

  const renderProcessTime = () => {
    const processTime = t('TicketBarrierOpeningRow.processTime', {
      processTimeInS,
    })

    return (
      <>
        <span>{`${label} - ${processTime}`}</span>
        <br />
      </>
    )
  }

  const renderManualOpeningInfo = () =>
    isManualOpening(opening?.status) && (
      <>
        <span>{`${t('TicketBarrierOpeningRow.manualOpening')} - ${getBarrierOpeningStatusLabel(
          t,
          opening?.status,
        )}`}</span>
        <br />
      </>
    )

  return (
    <StyledListItem alignItems='flex-start'>
      <ListItemIcon classes={{ root: classes.itemIcon }}>
        <DotChip color={color} />
      </ListItemIcon>
      <ListItemText
        primary={activityString}
        primaryTypographyProps={{ variant: 'body2' }}
        secondary={
          <>
            {renderStatusError()}
            {!isManualOpening(opening?.status) && renderProcessTime()}
            {renderManualOpeningInfo()}
          </>
        }
      />
    </StyledListItem>
  )
}
