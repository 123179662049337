import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'

import PageGrid from 'fragments/Grid/PageGrid'
import { BasicPageContainerLoadable } from 'fragments/Loadables'
import AppUsersTableCard from 'components/Clients/AppUsers/AppUsersTableCard'
import { getAppUsersAct } from 'redux/Actions'
import ErrorAlertContainer from 'fragments/Alerts/ErrorAlertContainer'
import LastUpdateInfo from 'fragments/Misc/LastUpdateInfo'
/* -------------------------------------------------------------------------- */
const ClientsContainer = () => {
  const { errGettingAppUsers, lastSuccessDate } = useSelector((state) => state.appUsers)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(getAppUsersAct())
  }, [dispatch])

  return (
    <BasicPageContainerLoadable helmetTitle={t('ClientsContainer.title')} pageTitle={t('ClientsContainer.title')}>
      <ErrorAlertContainer errors={[errGettingAppUsers]} />
      <PageGrid>
        <Grid item xs={12}>
          <AppUsersTableCard />
        </Grid>
        <Grid item xs={12}>
          <LastUpdateInfo lastSuccessDate={lastSuccessDate} />
        </Grid>
      </PageGrid>
    </BasicPageContainerLoadable>
  )
}

export default ClientsContainer
