import React from 'react'
import { styled } from '@mui/material/styles'
import * as PropTypes from 'prop-types'
import { Typography, Link as MuiLink } from '@mui/material'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { format } from 'date-fns'

import SidebarNav from 'layouts/AppLayout/Sidebar/SidebarNav'
import { parkmotiveIconWhite, parkmotiveLogoWhite, triveParkIconWhite, triveParkLogoWhite } from 'assets/svg'
import { APP } from 'routes/routes'
const PREFIX = 'Sidebar'

const classes = {
  sidebar: `${PREFIX}-sidebar`,
  sidebarHeader: `${PREFIX}-sidebarHeader`,
  headerSvg: `${PREFIX}-headerSvg`,
  sidebarfooter: `${PREFIX}-sidebarfooter`,
  footerSvg: `${PREFIX}-footerSvg`,
  footerText: `${PREFIX}-footerText`,
}

const Root = styled('aside')(({ theme }) => ({
  [`&.${classes.sidebar}`]: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
    color: theme.sidebar.color,
    background: theme.sidebar.background,
    overflowX: 'hidden',
    overflowY: 'auto',
  },

  [`& .${classes.sidebarHeader}`]: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    maxHeight: 64,
    padding: theme.spacing(2),
    background: theme.sidebar.logoBackground,
    ...theme.mixins.toolbar,
  },

  [`& .${classes.headerSvg}`]: {
    display: 'inline-block',
    verticalAlign: 'text-bottom',
    height: 28,
    minWidth: 32,
  },

  [`& .${classes.sidebarfooter}`]: {
    padding: theme.spacing(2),
  },

  [`& .${classes.footerSvg}`]: {
    width: '100%',
    minWidth: 32,
    maxWidth: 200,
    minHeight: 23,
  },

  [`& .${classes.footerText}`]: {
    marginTop: 10,
    whiteSpace: 'nowrap',
    fontSize: 14,
  },
}))

const Sidebar = (props) => {
  const { isCollapsed, isDesktop, onSelect } = props
  const location = useLocation()

  const footerText = isCollapsed
    ? `© ${format(new Date(), 'yy')}`
    : `© ${format(new Date(), 'yyyy')} v${process.env.REACT_APP_PACKAGE_VERSION}`

  return (
    <Root className={classes.sidebar}>
      <div>
        <div className={classes.sidebarHeader}>
          <MuiLink component={RouterLink} to={APP.HOME} state={{ from: location }} underline='hover'>
            <img
              src={isDesktop && isCollapsed ? triveParkIconWhite : triveParkLogoWhite}
              className={classes.headerSvg}
              style={{ marginLeft: isCollapsed ? 0 : 8 }}
              alt='trive.park logo'
            />
          </MuiLink>
        </div>
        <SidebarNav isCollapsed={isCollapsed} onSelect={onSelect} />
      </div>
      <div className={classes.sidebarfooter}>
        <MuiLink href='https://parkmotive.com' id='privacy' target='_blank' rel='noopener noreferrer' underline='hover'>
          <img
            src={isDesktop && isCollapsed ? parkmotiveIconWhite : parkmotiveLogoWhite}
            className={classes.footerSvg}
            style={{ marginLeft: isCollapsed ? 0 : 8 }}
            alt='parkmotive logo'
          />
        </MuiLink>
        <Typography
          className={classes.footerText}
          style={{ marginLeft: isCollapsed ? 0 : 8 }}
          variant={isCollapsed ? 'caption' : 'subtitle1'}
        >
          {footerText}
        </Typography>
      </div>
    </Root>
  )
}

Sidebar.propTypes = {
  isCollapsed: PropTypes.bool.isRequired,
  isDesktop: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
}

Sidebar.defaultProps = {
  onSelect: () => {},
}

export default Sidebar
