import axiosInstance from 'api/axiosConfig'

import { getQueryString } from 'util/core'
import { BODY_APP_USER, PATH_SEGMENTS_APP_USERS } from 'api/appUserMgmt/appUserMgmtModels'
/* -------------------------------------------------------------------------- */

// export const getAppUsersReq = () => mockDelayedApiCall(appUsers)

// export const getAppUserReq = async (uuid) => mockDelayedApiCall(appUsers.find((u) => u.uuid === uuid))

/* -------------------------------------------------------------------------- */

/**
 * Api functions for car parks
 * @namespace carParksMgmtApi
 */

/**
 * Get all app users
 * @function
 * @memberof appUserMgmtApi
 * @param {Object} params
 * @returns {Object[]}
 */
export const getAppUsersReq = ({
  city,
  deleted,
  email,
  firstName,
  lastName,
  houseNumber,
  licensePlate,
  pageNumber,
  pageSize,
  postalCode,
  street,
  page,
  rows,
}) => {
  const {
    CITY,
    DELETED,
    EMAIL,
    FIRST_NAME,
    LAST_NAME,
    HOUSE_NUMBER,
    LICENCE_PLATE,
    POSTAL_CODE,
    STREET,
    PAGE_NUMBER,
    PAGE_SIZE,
    ORDER,
  } = BODY_APP_USER
  const qs = getQueryString({
    [CITY]: city,
    [DELETED]: deleted,
    [EMAIL]: email,
    [FIRST_NAME]: firstName,
    [LAST_NAME]: lastName,
    [HOUSE_NUMBER]: houseNumber,
    [LICENCE_PLATE]: licensePlate,
    [PAGE_NUMBER]: pageNumber,
    [PAGE_SIZE]: pageSize,
    [POSTAL_CODE]: postalCode,
    [STREET]: street,
    [PAGE_NUMBER]: page,
    [PAGE_SIZE]: rows,
    [ORDER]: 'Ascending',
  })
  const url = `${PATH_SEGMENTS_APP_USERS.APP_USERS_BASE}${qs}`
  return axiosInstance.get(url).then((res) => {
    const { data } = res
    return data
  })
}

/**
 * Get app user by uuid
 * @function
 * @memberof appUserMgmtApi
 * @param {Object} params
 * @returns {Object[]}
 */
export const getAppUserReq = (uuid) => {
  const url = `${PATH_SEGMENTS_APP_USERS.APP_USERS_BASE}/${uuid}`
  return axiosInstance.get(url).then((res) => {
    const { data } = res
    return data
  })
}

/**
 * Get app user by email
 * @function
 * @memberof appUserMgmtApi
 * @param {Object} params
 * @returns {Object[]}
 */
export const getAppUserByEmailReq = (email) => {
  const url = `${PATH_SEGMENTS_APP_USERS.APP_USERS_BASE}/email/${email}`
  return axiosInstance.get(url).then((res) => {
    const { data } = res
    return data
  })
}

/**
 * Delete app user by uuid
 * @function
 * @memberof appUserMgmtApi
 * @param {Object} params
 * @returns {Object[]}
 */
export const deleteAppUserByUuidReq = (uuid) => {
  const url = `${PATH_SEGMENTS_APP_USERS.APP_ADMINS_BASE}/${uuid}`
  return axiosInstance.delete(url).then((res) => {
    const { data } = res
    return data
  })
}
