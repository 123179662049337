import React from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import * as PropTypes from 'prop-types'
import { LocationCity as LocationCityIcon } from '@mui/icons-material'

import NumbersWidget from 'fragments/widgets/NumbersWidget'
/* -------------------------------------------------------------------------- */

const CarParksNumbersCard = (props) => {
  const { isGettingGeneralStatistics, countTotal, countYear } = props
  const { t } = useTranslation()

  return (
    <NumbersWidget
      title={t('CarParksNumbersCard.digitalCarParks')}
      isLoading={isGettingGeneralStatistics}
      action={<LocationCityIcon />}
      label1={t('CarParksNumbersCard.total')}
      val1={countTotal}
      label2={t('CarParksNumbersCard.thisYear')}
      val2={countYear}
    />
  )
}

/* ----- SETUP -------------------------------------------------------------- */
CarParksNumbersCard.propTypes = {
  isGettingGeneralStatistics: PropTypes.bool.isRequired,
  countTotal: PropTypes.number,
  countYear: PropTypes.number,
}

CarParksNumbersCard.defaultProps = {
  countTotal: null,
  countYear: null,
}

const mapStateToProps = ({ statistics }) => {
  const {
    carParksCount: { total, year },
    isGettingGeneralStatistics,
  } = statistics
  return {
    isGettingGeneralStatistics,
    countTotal: total,
    countYear: year,
  }
}

export default connect(mapStateToProps, null)(CarParksNumbersCard)
