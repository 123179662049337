import React from 'react'
import { styled } from '@mui/material/styles'
import * as PropTypes from 'prop-types'
import { Grid, Typography } from '@mui/material'
import CountUp from 'react-countup'

import { BasicCardLoadable } from 'fragments/Loadables'
const PREFIX = 'NumbersWidget'

const classes = {
  countUp: `${PREFIX}-countUp`,
}

const StyledCardWrapper = styled('div')({
  [`& .${classes.countUp}`]: {
    fontSize: '1.5rem',
    color: '#727891',
  },
})

const NumbersWidget = (props) => {
  const { title, action, isLoading, label1, val1, label2, val2 } = props

  const renderCountUp = (val) => {
    return (
      <>
        {Number.isInteger(val) ? (
          <CountUp separator=',' className={classes.countUp} start={0} end={val} duration={3} useEasing />
        ) : (
          <p>-</p>
        )}
      </>
    )
  }

  return (
    <BasicCardLoadable title={title} action={action} isLoading={isLoading}>
      <StyledCardWrapper>
        <Grid container>
          <Grid container direction='column' item xs={3}>
            <Grid item>
              <Typography variant='body2'>
                <span>{label1}</span>
              </Typography>
            </Grid>
            <Grid item>{renderCountUp(val1)}</Grid>
          </Grid>
          <Grid container direction='column' item xs={9}>
            <Grid item>
              <Typography variant='body2'>
                <span>{label2}</span>
              </Typography>
            </Grid>
            <Grid item>{renderCountUp(val2)}</Grid>
          </Grid>
        </Grid>
      </StyledCardWrapper>
    </BasicCardLoadable>
  )
}

NumbersWidget.propTypes = {
  title: PropTypes.string.isRequired,
  action: PropTypes.node.isRequired,
  isLoading: PropTypes.bool.isRequired,
  label1: PropTypes.string.isRequired,
  label2: PropTypes.string.isRequired,
  val1: PropTypes.number,
  val2: PropTypes.number,
}

NumbersWidget.defaultProps = {
  val1: null,
  val2: null,
}

export default NumbersWidget
