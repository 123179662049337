import React from 'react'
import loadable from '@loadable/component'
import { CircularProgress } from '@mui/material'

import WidgetSkeleton from 'fragments/Skeletons/WidgetSkeleton'
import { Box } from '@mui/system'

const CircularProgressLoader = () => {
  return (
    <Box sx={circularProgressLoaderStyle}>
      <CircularProgress />
    </Box>
  )
}

const circularProgressLoaderStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
}

/* -------------------------------------------------------------------------- */

export const BasicCardLoadable = loadable(() => import('fragments/Cards/BasicCard'), {
  // FIXME: add updated BasicCard skeleton
  fallback: <WidgetSkeleton />,
})

export const BasicPageContainerLoadable = loadable(() => import('layouts/BasicPageContainer/BasicPageContainer'), {
  fallback: <CircularProgressLoader />,
})
