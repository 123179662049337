import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { isEmpty } from 'lodash'
import { LocalParking as LocalParkingIcon } from '@mui/icons-material'

import { getCarParksAct } from 'redux/Actions'
import { BasicCardLoadable } from 'fragments/Loadables'
import PlaceHolderText from 'fragments/PlaceHolderText'
/* -------------------------------------------------------------------------- */

const CarParksCard = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const user = useSelector((state) => state.auth.user)
  const { carParks, isGettingCarParks } = useSelector((state) => state.carParks)

  const operatorUuid = user.operatorUuid
  const carParkUuids = user.carParkUuids ?? []
  const carParksList = carParkUuids.map((uuid) => {
    return {
      uuid: uuid,
      name: carParks.find((cp) => cp.uuid === uuid)?.name ?? null,
    }
  })

  useEffect(() => {
    dispatch(getCarParksAct({ operatorUuid }))
  }, [dispatch, operatorUuid])

  const carParkList = () =>
    isEmpty(carParksList) ? (
      <PlaceHolderText text={t('CarParksCard.noCarParks')} />
    ) : (
      carParksList.map((carPark) => {
        return (
          <Grid key={carPark.uuid} item xs={12}>
            {carPark.name ?? <PlaceHolderText text={t('CarParksCard.unknownCarPark')} />}
          </Grid>
        )
      })
    )

  return (
    <BasicCardLoadable title={t('CarParksCard.title')} action={<LocalParkingIcon />} isLoading={isGettingCarParks}>
      <Grid container spacing={1}>
        {carParkList()}
      </Grid>
    </BasicCardLoadable>
  )
}

export default CarParksCard
