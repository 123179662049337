import React from 'react'
import * as PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { CloudDownload as CloudDownloadIcon } from '@mui/icons-material'

import { BasicCardLoadable } from 'fragments/Loadables'
import InfoRows from 'fragments/Cards/InfoRows'
import ToggleBarrierUpdate from 'components/Barrier/ToggleBarrierUpdate'
import TextField from 'fragments/Forms/Formik/TextField'
/* -------------------------------------------------------------------------- */
const BarrierUpdateCard = (props) => {
  const { t } = useTranslation()
  const {
    barrier: { softwareVersion, updateUrl, lastUpdateCheckAt },
    isEditingBarrier,
  } = useSelector((state) => state.barrier)

  const { isEdit } = props

  const updateUrlInput = () => <TextField name='updateUrl' variant='standard' fullWidth />

  const rows = [
    {
      label: t('BarrierUpdateCard.lastCheck'),
      value: t('BarrierUpdateCard.dateMediumTimeShort', { date: lastUpdateCheckAt }),
    },
    {
      label: t('BarrierUpdateCard.softwareVersion'),
      value: softwareVersion,
    },
    {
      label: t('BarrierUpdateCard.url') + (isEdit ? ' *' : ''),
      value: isEdit ? updateUrlInput() : updateUrl,
    },
    {
      label: t('BarrierUpdateCard.allowUpdate'),
      value: <ToggleBarrierUpdate isEdit={isEdit} />,
    },
  ]

  return (
    <BasicCardLoadable title={t('BarrierUpdateCard.title')} action={<CloudDownloadIcon />} isLoading={isEditingBarrier}>
      <Grid container spacing={1}>
        <InfoRows rows={rows} />
      </Grid>
    </BasicCardLoadable>
  )
}

BarrierUpdateCard.propTypes = {
  isEdit: PropTypes.bool,
}

BarrierUpdateCard.defaultProps = {
  isEdit: false,
}

export default BarrierUpdateCard
