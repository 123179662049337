import React from 'react'
import { styled } from '@mui/material/styles'
import { CircularProgress } from '@mui/material'
const PREFIX = 'CircularProgressOverlay'

const classes = {
  loadingOverlay: `${PREFIX}-loadingOverlay`,
  loadingSpinner: `${PREFIX}-loadingSpinner`,
}

const Root = styled('div')(() => ({
  [`&.${classes.loadingOverlay}`]: {
    position: 'absolute',
    background: 'rgba(255, 255, 255, 0.6)',
    zIndex: 1150,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },

  [`& .${classes.loadingSpinner}`]: {
    zIndex: 99,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}))

const CircularProgressOverlay = () => {
  return (
    <Root className={classes.loadingOverlay}>
      <CircularProgress className={classes.loadingSpinner} />
    </Root>
  )
}

export default CircularProgressOverlay
