import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { useLocation } from 'react-router-dom'
import debounceFn from 'debounce-fn'

import { BasicPageContainerLoadable } from 'fragments/Loadables'
import PageGrid from 'fragments/Grid/PageGrid'
import ErrorAlertContainer from 'fragments/Alerts/ErrorAlertContainer'
import TicketsTableCard from 'components/Tickets/TicketsTableCard'
import TicketsFilterCard from 'components/Tickets/TicketsFilterCard'
import { INFREQUENT_UPDATE_TIME_IN_MS } from 'constants/generalConstants'
import LastUpdateInfo from 'fragments/Misc/LastUpdateInfo'
import {
  usePrefetch,
  useGetTicketsQuery,
  TicketUsability,
  TicketUsage,
  TicketType,
} from 'services/tickets/tickets-service'
import { getParsedQueryParam } from 'util/core'
import { QUERY_PARAMS } from 'routes/routes'
/* -------------------------------------------------------------------------- */

type RootState = {
  globalFilters: {
    selectedOperator: string
    selectedCarParks: string[]
  }
}

export default function TicketsContainer() {
  const { t } = useTranslation()
  const { selectedOperator, selectedCarParks } = useSelector((state: RootState) => state.globalFilters)
  const [page, setPage] = React.useState(0)
  const [rows, setRows] = React.useState(10)
  const [usage, setUsage] = React.useState<TicketUsage>()
  const [usability, setUsability] = React.useState<TicketUsability>()
  const [type, setType] = React.useState<TicketType>()
  const location = useLocation()
  const globalSearchTerm = React.useMemo(
    () => getParsedQueryParam(location?.search, QUERY_PARAMS.SEARCH, { parseNumbers: false }) as string,
    [location?.search],
  )
  const [search, setSearch] = React.useState<string>(() => globalSearchTerm)
  const debouncedSetSearch = debounceFn(setSearch, { wait: 300 })

  const prefetchTickets = usePrefetch('getTickets')
  const { data, fulfilledTimeStamp, isLoading, isFetching, error, refetch } = useGetTicketsQuery(
    {
      carParkUuid: selectedCarParks,
      operatorUuid: selectedOperator,
      pageNumber: page,
      pageSize: rows,
      search,
      type,
      usability,
      usage,
    },
    { pollingInterval: INFREQUENT_UPDATE_TIME_IN_MS },
  )

  function resetFilters() {
    setUsage(undefined)
    setUsability(undefined)
    setType(undefined)
  }

  const prefetchNextPage = React.useCallback(
    () =>
      prefetchTickets({
        carParkUuid: selectedCarParks,
        operatorUuid: selectedOperator,
        pageNumber: page + 1,
        pageSize: rows,
        search,
        type,
        usability,
        usage,
      }),
    [prefetchTickets, selectedCarParks, selectedOperator, page, rows, type, usability, usage, search],
  )

  React.useEffect(() => {
    setSearch(globalSearchTerm)
  }, [globalSearchTerm])

  React.useEffect(() => {
    setPage(0)
  }, [selectedOperator, selectedCarParks, type, usability, usage, search, rows])

  React.useEffect(() => {
    if (!data?.isLastPage) {
      prefetchNextPage()
    }
  }, [data, prefetchNextPage])

  return (
    <BasicPageContainerLoadable helmetTitle={t('TicketsContainer.tickets')} pageTitle={t('TicketsContainer.tickets')}>
      <ErrorAlertContainer errors={[error]} />
      <PageGrid>
        <Grid item xs={12}>
          <TicketsFilterCard
            isLoading={isLoading}
            resetFilters={resetFilters}
            ticketType={type}
            usability={usability}
            usage={usage}
            setTicketType={setType}
            setUsage={setUsage}
            setUsability={setUsability}
          />
        </Grid>
        <Grid item xs={12}>
          <TicketsTableCard
            tickets={data?.content ?? []}
            page={page}
            setPage={setPage}
            rows={rows}
            setRows={setRows}
            totalCount={data?.totalElements}
            isLoading={isLoading}
            search={search}
            onSearchChange={debouncedSetSearch}
          />
        </Grid>
        <Grid item xs={12}>
          <LastUpdateInfo
            lastSuccessDate={fulfilledTimeStamp}
            isUpdating={isFetching}
            reloadIntervalInMs={INFREQUENT_UPDATE_TIME_IN_MS}
            refetch={refetch}
          />
        </Grid>
      </PageGrid>
    </BasicPageContainerLoadable>
  )
}
