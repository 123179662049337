import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Public as PublicIcon } from '@mui/icons-material'

import { BasicCardLoadable } from 'fragments/Loadables'
import LanguageFlagIconWithString from 'fragments/Icons/LanguageFlagIconWithString'
import LanguageSelect from 'fragments/Forms/Formik/LanguageSelect'
import { isPending } from 'redux/Status'
/* -------------------------------------------------------------------------- */

const LanguageCard = (props) => {
  const {
    isGettingAdmin,
    isReGettingAdmin,
    statusCreatingAdmin,
    admin: { languageCode },
  } = useSelector((state) => state.admin)
  const { t } = useTranslation()
  const { isCreate, isEdit } = props

  return (
    <BasicCardLoadable
      title={t('LanguageCard.title')}
      action={<PublicIcon />}
      isLoading={isGettingAdmin || isReGettingAdmin || isPending(statusCreatingAdmin)}
    >
      {isCreate || isEdit ? (
        <LanguageSelect name='language' />
      ) : (
        <LanguageFlagIconWithString languageCode={languageCode} />
      )}
    </BasicCardLoadable>
  )
}

LanguageCard.propTypes = {
  isCreate: PropTypes.bool,
  isEdit: PropTypes.bool,
}

LanguageCard.defaultProps = {
  isCreate: false,
  isEdit: false,
}

export default LanguageCard
