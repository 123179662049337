import { BODY_APP_USER, BODY_MOCK_BARRIER_CONNECTION } from 'api/appUserMgmt/appUserMgmtModels'
import { BODY_MOCK_PAYMENT } from 'api/sales/salesModels'
import { appendZeroUTC } from 'util/core'
import { getCarParkByUuidReq } from 'api/requests'
/* -------------------------------------------------------------------------- */

export const unpackAppUsers = (appUsers) => appUsers.map(unpackAppUser)

export const unpackAppUser = (u) => {
  const {
    ACCEPTED_GDPR_AT,
    ACCEPTED_TERMS_AT,
    APP_VERSION,
    CITY,
    CREATED_AT,
    DELETED_AT,
    DEVICE,
    EMAIL,
    FIRST_NAME,
    HOUSE_NUMBER,
    IS_DELETED,
    IS_EMAIL_VERIFIED,
    LANGUAGE_CODE,
    LAST_NAME,
    LICENSE_PLATE,
    OS_VERSION,
    POSTAL_CODE,
    SET_LICENSE_PLATE_AT,
    STREET,
    UPDATED_AT,
    UUID,
    PAYMENTS_ARR,
    BARRIER_CONNECTIONS,
    RESIDENTIAL_PARKING,
  } = BODY_APP_USER
  return {
    acceptedGdprAt: appendZeroUTC(u?.[ACCEPTED_GDPR_AT] ?? null),
    acceptedTermsAt: appendZeroUTC(u?.[ACCEPTED_TERMS_AT] ?? null),
    appVersion: u?.[APP_VERSION] ?? null,
    city: u?.[CITY] ?? null,
    createdAt: appendZeroUTC(u?.account?.[CREATED_AT] ?? null),
    deletedAt: appendZeroUTC(u?.account?.[DELETED_AT] ?? null),
    device: u?.[DEVICE] ?? null,
    email: u?.account?.[EMAIL] ?? null,
    firstName: u?.account?.[FIRST_NAME] ?? null,
    houseNumber: u?.[HOUSE_NUMBER] ?? null,
    isDeleted: u?.account?.[IS_DELETED] ?? null,
    isEmailVerified: u?.account?.[IS_EMAIL_VERIFIED] ?? null,
    languageCode: u?.account?.[LANGUAGE_CODE] ?? null,
    lastName: u?.account?.[LAST_NAME] ?? null,
    licensePlate: u?.[LICENSE_PLATE] ?? null,
    osVersion: u?.[OS_VERSION] ?? null,
    postalCode: u?.[POSTAL_CODE] ?? null,
    setLicensePlateAt: appendZeroUTC(u?.[SET_LICENSE_PLATE_AT] ?? null),
    street: u?.[STREET] ?? null,
    updatedAt: appendZeroUTC(u?.[UPDATED_AT] ?? null),
    uuid: u?.[UUID] ?? null,
    payments: unpackPayments(u[PAYMENTS_ARR] || []),
    barrierConnections: unpackBarrierConnections(u[BARRIER_CONNECTIONS] || []),
    assignedResidentialCarParks: u?.[RESIDENTIAL_PARKING] ?? null,
  }
}

export const getCarParkNamesFromUuid = async (carParks) => {
  if (carParks && carParks.length > 0) {
    let carParkNames = []
    for (let i = 0; i < carParks.length; i++) {
      let carPark = carParks[i]
      let result = await getCarParkByUuidReq(carPark.carParkUuid)
      carParkNames.push({ carParkName: result.name })
    }
    return carParkNames
  } else {
    return null
  }
}

// FIXME: move to sales or ticket??
const unpackPayments = (p) => p.map((obj) => unpackPayment(obj))

const unpackPayment = (p) => {
  const { CAR_PARK_NAME, UPDATED_AT, OFFER_NAME, STATUS, TICKET_ID, TRANSACTION_ID, TRANSACTIONS_SUM_IN_CENT } =
    BODY_MOCK_PAYMENT
  return {
    carParkName: p[CAR_PARK_NAME],
    updatedAt: appendZeroUTC(p[UPDATED_AT]),
    offerName: p[OFFER_NAME],
    status: p[STATUS],
    ticketId: p[TICKET_ID],
    transactionId: p[TRANSACTION_ID],
    transactionSumInCent: p[TRANSACTIONS_SUM_IN_CENT],
  }
}

const unpackBarrierConnections = (arr) => arr.map(unpackBarrierConnection)

const unpackBarrierConnection = (obj) => {
  const { BEACON_MINOR, BEACON_SIGNAL_IN_DBM, CAR_PARK_NAME, CONNECTION_TIME_IN_S, CREATED_AT, STATUS } =
    BODY_MOCK_BARRIER_CONNECTION
  return {
    beaconMinor: obj[BEACON_MINOR],
    beaconSignalInDBM: obj[BEACON_SIGNAL_IN_DBM],
    carParkName: obj[CAR_PARK_NAME],
    connectionTimeInS: obj[CONNECTION_TIME_IN_S],
    createdAt: obj[CREATED_AT],
    status: obj[STATUS],
  }
}
