import React, { useRef, useState } from 'react'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import ListItemText from '@mui/material/ListItemText'
import Select from '@mui/material/Select'
import Checkbox from '@mui/material/Checkbox'
import OutlinedInput from '@mui/material/OutlinedInput'
import * as PropTypes from 'prop-types'

import CircularProgressOverlay from 'fragments/CircularProgressOverlay'
/* -------------------------------------------------------------------------- */

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

/* -------------------------------------------------------------------------- */

const MultipleSelect = (props) => {
  const { label, isLoading, selected, options, onChange } = props

  const [_selectedOptions, setSelectedOptions] = useState(selected)

  const inputLabelRef = useRef(null)

  const handleChange = (event) => setSelectedOptions(event.target.value)

  const handleClose = () => onChange(_selectedOptions)

  return (
    <FormControl variant='outlined' fullWidth>
      {isLoading && <CircularProgressOverlay />}
      <InputLabel id='multiple-checkbox-label' ref={inputLabelRef} htmlFor='select-multiple-checkbox'>
        {label}
      </InputLabel>
      <Select
        labelId='multiple-checkbox-label'
        id='select-multiple-checkbox'
        multiple
        value={_selectedOptions}
        onChange={handleChange}
        onClose={handleClose}
        renderValue={(selected) =>
          selected.reduce((acc, cur) => acc.concat(options.find((p) => p.id === cur).label), []).join(', ')
        }
        MenuProps={MenuProps}
        input={<OutlinedInput label={label} />}
      >
        {options.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            <Checkbox color='primary' checked={_selectedOptions.indexOf(option.id) > -1} disabled={false} />
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
/* -------------------------------------------------------------------------- */

MultipleSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  selected: PropTypes.arrayOf(PropTypes.string).isRequired,
}

MultipleSelect.defaultProps = {
  isLoading: false,
}

export default MultipleSelect
