import { ADMIN_API_BASE_PATH, PARAMS_PAGINATION } from 'api/backendConstants'

export const PATH_SEGMENTS_APP_USERS = {
  APP_USERS_BASE: `${ADMIN_API_BASE_PATH}/users`,
  APP_ACCOUNTS_BASE: `${ADMIN_API_BASE_PATH}/accounts`,
  APP_ADMINS_BASE: `${ADMIN_API_BASE_PATH}/admins`,
}

export const BODY_APP_USER = {
  ACCEPTED_GDPR_AT: 'acceptedGdprAt',
  ACCEPTED_TERMS_AT: 'acceptedTermsAt',
  APP_VERSION: 'appVersion',
  CITY: 'city',
  CREATED_AT: 'createdAt',
  DELETED_AT: 'deletedAt',
  DEVICE: 'device',
  EMAIL: 'email',
  FIRST_NAME: 'firstName',
  HOUSE_NUMBER: 'houseNumber',
  IS_DELETED: 'isDeleted',
  IS_EMAIL_VERIFIED: 'isEmailVerified',
  LANGUAGE_CODE: 'languageCode',
  LAST_NAME: 'lastName',
  LICENSE_PLATE: 'licensePlate',
  OS_VERSION: 'osVersion',
  POSTAL_CODE: 'postalCode',
  SET_LICENSE_PLATE_AT: 'setLicensePlateAt',
  STREET: 'street',
  UPDATED_AT: 'updatedAt',
  UUID: 'uuid',
  PAYMENTS_ARR: 'payments',
  BARRIER_CONNECTIONS: 'barrierConnections',
  RESIDENTIAL_PARKING: 'assignedResidentialCarParks',
  ...PARAMS_PAGINATION,
}

export const BODY_MOCK_BARRIER_CONNECTION = {
  BEACON_MINOR: 'beaconMinor',
  BEACON_SIGNAL_IN_DBM: 'beaconSignalInDBM',
  CAR_PARK_NAME: 'carParkName',
  CONNECTION_TIME_IN_S: 'connectionTimeInS',
  CREATED_AT: 'createdAt',
  STATUS: 'status',
}
