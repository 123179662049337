import { muiDataTableOptions } from 'fragments/tables/TableConfig'
import { APP, QUERY_PARAMS } from 'routes/routes'
import { getAppUsersAct } from 'redux/Actions'
import { getQueryString } from 'util/core'
/* -------------------------------------------------------------------------- */

const options = ({ dispatch, appUsers, history: navigate, page, rows, totalCount }) => ({
  onChangePage: (page) =>
    dispatch(
      getAppUsersAct({
        page,
      }),
    ),
  onChangeRowsPerPage: (rows) =>
    dispatch(
      getAppUsersAct({
        rows,
      }),
    ),
  onRowClick: (_rowData, { dataIndex }) => {
    const appUserUuid = appUsers?.[dataIndex]?.uuid ?? null
    navigate({
      pathname: APP.APP_USER,
      search: getQueryString({ [QUERY_PARAMS.UUID]: appUserUuid }),
    })
  },
  page: page,
  rowsPerPage: rows,
  count: totalCount,
  serverSide: true,
})

export default (props) => ({
  ...muiDataTableOptions(),
  ...options(props),
})
