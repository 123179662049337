import { TFunction } from 'i18next'

import { UserAccount } from './ResidentialParkingTableCard'
/* -------------------------------------------------------------------------- */

function getColumns(data: UserAccount[], language: 'en' | 'de', t: TFunction) {
  return [
    {
      name: 'firstName',
      label: t('ResidentialParkingTableColumns.firstName'),
    },
    {
      name: 'lastName',
      label: t('ResidentialParkingTableColumns.lastName'),
    },
    {
      name: 'email',
      label: t('ResidentialParkingTableColumns.email'),
    },
    {
      name: 'licensePlate',
      label: t('ResidentialParkingTableColumns.userLicensePlate'),
    },
  ]
}

export default getColumns
