import React from 'react'
import * as PropTypes from 'prop-types'
import { Alert } from '@mui/material'
/* -------------------------------------------------------------------------- */

const ErrorAlert = (props) => {
  const { message } = props

  return (
    <Alert severity='error' variant='outlined'>
      {message}
    </Alert>
  )
}

ErrorAlert.propTypes = {
  message: PropTypes.string.isRequired,
}

export default ErrorAlert
