import React from 'react'
import { styled } from '@mui/material/styles'
import * as PropTypes from 'prop-types'
import { Grid, Card, CardContent } from '@mui/material'

import CircularProgressOverlay from 'fragments/CircularProgressOverlay'
import { triveParkIconLogoColored } from 'assets/svg'
const PREFIX = 'AuthPageContainer'

const classes = {
  card: `${PREFIX}-card`,
  tpLogo: `${PREFIX}-tpLogo`,
}

const StyledCard = styled(Card)(() => ({
  [`&.${classes.card}`]: {
    borderRadius: '20px',
    paddingBottom: '40px',
    paddingTop: '40px',
    position: 'relative',
    height: '100%',
  },

  [`& .${classes.tpLogo}`]: {
    maxHeight: '100px',
    maxWidth: '100%',
    marginBottom: '10px',
  },
}))

const AuthPageContainer = (props) => {
  const { children, isLoading } = props

  return (
    <StyledCard raised className={classes.card}>
      {isLoading && <CircularProgressOverlay />}
      <CardContent>
        <Grid container item justifyContent='center'>
          <Grid item xs={4}>
            <img src={triveParkIconLogoColored} className={classes.tpLogo} alt='trive park logo colored' />
          </Grid>
        </Grid>
        {children}
      </CardContent>
    </StyledCard>
  )
}

AuthPageContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  isLoading: PropTypes.bool,
}

AuthPageContainer.defaultProps = {
  isLoading: false,
}

export default AuthPageContainer
