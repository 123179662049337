import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Typography } from '@mui/material'

import DotChip from 'fragments/IconChips/DotChip'
import { getBarrierOpeningStatusObj } from 'util/custom'
import { BarrierOpeningsStatus } from 'services/barrier-openings/barrier-openings-service'
/* -------------------------------------------------------------------------- */

type OpeningsStatusCellProps = {
  status?: BarrierOpeningsStatus
}

export default function OpeningsStatusCell(props: OpeningsStatusCellProps) {
  const { t } = useTranslation()
  const { status } = props

  const { color, label, info } = getBarrierOpeningStatusObj(t, status)

  return (
    <Grid container alignItems='center' style={{ minWidth: 100 }} spacing={1}>
      <Grid item xs={4} md={3} lg={2}>
        <DotChip color={color} />
      </Grid>
      <Grid item xs={8} md={9} lg={10}>
        <Typography variant='body2'>{label}</Typography>
        <Typography variant='caption'>{info}</Typography>
      </Grid>
    </Grid>
  )
}
