import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Grid, Typography } from '@mui/material'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { Formik, Form } from 'formik'

import PageGrid from 'fragments/Grid/PageGrid'
import OperatorGrid from 'components/Operator/OperatorGrid'
import { editOperatorAct, resetEditOperatorAct } from 'redux/Actions'
import { APP, QUERY_PARAMS } from 'routes/routes'
import { getQueryString, getParsedQueryParam, getRequiredInfo } from 'util/core'
import { validationSchema, initialValues } from 'components/Operator/Edit/operatorEdit'
import { SaveButton, CancelButton } from 'fragments/Buttons'
/* -------------------------------------------------------------------------- */

const OperatorEditForm = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { operator, editOperatorSuccess } = useSelector((state) => state.operator)

  const uuid = getParsedQueryParam(location?.search, QUERY_PARAMS.UUID)

  const onKeyDown = (keyEvent) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault()
    }
  }

  const to = React.useMemo(
    () => ({
      pathname: APP.OPERATOR,
      search: getQueryString({ [QUERY_PARAMS.UUID]: uuid }),
    }),
    [uuid],
  )

  useEffect(() => {
    if (editOperatorSuccess) {
      navigate(
        {
          ...to,
        },
        { state: { from: location } },
      )
    }
    return () => {
      if (editOperatorSuccess) {
        dispatch(resetEditOperatorAct())
      }
    }
  }, [editOperatorSuccess, dispatch, navigate, uuid, to, location])

  const handleSubmit = (values) => dispatch(editOperatorAct(values, uuid))

  return (
    <Formik
      initialValues={initialValues(operator)}
      validationSchema={validationSchema(t)}
      onSubmit={handleSubmit}
      enableReinitialize
      validateOnMount
    >
      {(formikProps) => {
        const { isValidating, isSubmitting } = formikProps
        return (
          <Form onKeyDown={onKeyDown}>
            <PageGrid justify='space-between'>
              <OperatorGrid isEdit />
              <Grid container justifyContent='space-between' item>
                <Grid item>
                  <Typography variant='body2'>{getRequiredInfo(t)}</Typography>
                </Grid>
                <Grid item xs>
                  <Grid container spacing={2} justifyContent='flex-end'>
                    <Grid item>
                      <CancelButton component={Link} to={to} state={{ from: location }} />
                    </Grid>
                    <Grid item>
                      <SaveButton disabled={isValidating || isSubmitting} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </PageGrid>
          </Form>
        )
      }}
    </Formik>
  )
}

export default OperatorEditForm
