import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { getCarParkNamesFromUuid } from 'api/appUserMgmt/appUserMgmtUnpackers'
import { BasicCardLoadable } from 'fragments/Loadables'
import MuiDataTableThemed from 'fragments/tables/MuiDataTableThemed'
import columns from 'components/AppUser/ResidentialParking/ResidentialParkingTableColumns'
import { muiDataTableOptions as options } from 'fragments/tables/TableConfig'
/* -------------------------------------------------------------------------- */

const ResidentialParkingTable = () => {
  const {
    isGettingAppUser,
    appUser: { assignedResidentialCarParks },
  } = useSelector((state) => state.appUser)
  const { t } = useTranslation()
  const [carParkNames, setCarParkNames] = useState(null)

  useEffect(() => {
    async function fetchData() {
      setCarParkNames(await getCarParkNamesFromUuid(assignedResidentialCarParks))
    }
    fetchData()
  }, [assignedResidentialCarParks])

  return (
    <BasicCardLoadable isLoading={isGettingAppUser}>
      <MuiDataTableThemed
        data={carParkNames && carParkNames.length > 0 ? carParkNames : []}
        columns={columns(t)}
        options={options()}
      />
    </BasicCardLoadable>
  )
}

export default ResidentialParkingTable
