import React, { useState } from 'react'
import * as PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { FormHelperText } from '@mui/material'

import ConfirmDialog from 'fragments/Dialogs/ConfirmDialog'
import BasicSwitch from 'fragments/Forms/BasicSwitch'
import { editBarrierAct } from 'redux/Actions'
/* -------------------------------------------------------------------------- */

const ToggleBarrierUpdate = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { uuid, isUpdateAllowed, isRebootAllowed } = useSelector((state) => state.barrier.barrier)
  const [open, setOpen] = useState(false)

  const { isEdit } = props

  const handleConfirm = () => {
    dispatch(editBarrierAct(uuid, { isUpdateAllowed: !isUpdateAllowed }))
    setOpen(false)
  }

  return (
    <>
      <ConfirmDialog
        open={open}
        dialogTitle={t('ToggleBarrierUpdate.title')}
        dialogContent={
          isUpdateAllowed ? t('ToggleBarrierUpdate.contentTrigger') : t('ToggleBarrierUpdate.contentCancel')
        }
        onConfirm={handleConfirm}
        onClose={() => setOpen(false)}
      />
      <BasicSwitch disabled={isRebootAllowed || isEdit} checked={isUpdateAllowed} onChange={() => setOpen(true)} />
      <FormHelperText>
        {isEdit
          ? t('ToggleBarrierUpdate.helperText.editMode')
          : isRebootAllowed
          ? t('ToggleBarrierUpdate.helperText.deactivate')
          : null}
      </FormHelperText>
    </>
  )
}

ToggleBarrierUpdate.propTypes = {
  isEdit: PropTypes.bool,
}

ToggleBarrierUpdate.defaultProps = {
  isEdit: false,
}

export default ToggleBarrierUpdate
