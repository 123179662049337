import React from 'react'
import * as PropTypes from 'prop-types'
import { Grid } from '@mui/material'

import OfferInfoCard from 'components/Offer/OfferInfoCard'
import OfferPricingCard from 'components/Offer/OfferPricingCard'
import OfferStatusCard from 'components/Offer/OfferStatusCard'
import OfferTimingCard from 'components/Offer/OfferTimingCard'
import OfferAddInfoCard from 'components/Offer/OfferAddInfoCard'
/* -------------------------------------------------------------------------- */

const OfferGrid = (props) => {
  const { isCreate, isEdit, formikProps } = props

  return (
    <>
      <Grid item xs={12} sm={6} md={4}>
        <OfferInfoCard isCreate={isCreate} isEdit={isEdit} />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <OfferStatusCard isCreate={isCreate} isEdit={isEdit} />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <OfferPricingCard isCreate={isCreate} isEdit={isEdit} formikProps={formikProps} />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <OfferTimingCard isCreate={isCreate} isEdit={isEdit} formikProps={formikProps} />
      </Grid>
      <Grid item xs={12} md={6}>
        <OfferAddInfoCard isCreate={isCreate} isEdit={isEdit} />
      </Grid>
    </>
  )
}

OfferGrid.propTypes = {
  isCreate: PropTypes.bool,
  isEdit: PropTypes.bool,
  formikProps: PropTypes.shape(),
}

OfferGrid.defaultProps = {
  isCreate: false,
  isEdit: false,
  formikProps: null,
}

export default OfferGrid
