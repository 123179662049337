import React from 'react'
import { connect } from 'react-redux'
import * as PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Info as InfoIcon } from '@mui/icons-material'

import LinksCard from 'fragments/Cards/LinksCard'
import { APP, QUERY_PARAMS } from 'routes/routes'
import { getQueryString } from 'util/core'
import { getOperatorNameAsString } from 'util/custom'
/* -------------------------------------------------------------------------- */

const LinksToOperatorCard = (props) => {
  const { operators, selectedOperator } = props
  const { t } = useTranslation()
  const links = [
    {
      linkLabel: t('LinksToOperatorCard.infoLink'),
      helperText: t('LinksToOperatorCard.infoText'),
      linkTo: {
        pathname: APP.OPERATOR,
        search: getQueryString({ [QUERY_PARAMS.UUID]: selectedOperator }),
      },
    },
    {
      linkLabel: t('LinksToOperatorCard.teamLink'),
      helperText: t('LinksToOperatorCard.teamText'),
      linkTo: {
        pathname: APP.ADMINS,
      },
    },
  ]
  return (
    <LinksCard title={getOperatorNameAsString(t, selectedOperator, operators)} action={<InfoIcon />} links={links} />
  )
}
/* -------------------------------------------------------------------------- */
LinksToOperatorCard.propTypes = {
  operators: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selectedOperator: PropTypes.string,
}

LinksToOperatorCard.defaultProps = {
  selectedOperator: null,
}

const mapStateToProps = (state) => {
  const {
    globalFilters: { selectedOperator },
    operators: { operators },
  } = state
  return { selectedOperator, operators }
}

export default connect(mapStateToProps)(LinksToOperatorCard)
