import { ADMIN_API_BASE_PATH, PARAMS_PAGINATION } from 'api/backendConstants'
/* -------------------------------------------------------------------------- */

export const PATH_SEGMENTS_CAR_PARKS = {
  CAR_PARKS_BASE: `${ADMIN_API_BASE_PATH}/car-parks`,
  BARRIERS_BASE: `${ADMIN_API_BASE_PATH}/barriers`,
}

export const PARAMS_CAR_PARK = {
  CITIES: 'cities',
  IS_VISIBLE: 'isVisible',
  OPERATOR_UUID: 'operatorUuid',
  ...PARAMS_PAGINATION,
}

export const BODY_CAR_PARKS = {
  ASSIGNED_USERS: 'assignedUsers',
  BARRIERS: 'barriers',
  BEACON_MAJOR: 'beaconMajor',
  CITY: 'city',
  CONTINUITY_PERIOD_IN_MIN: 'continuityPeriodInMin',
  CONTINUITY_PRICE_IN_CENT: 'continuityPriceInCent',
  CREATED_AT: 'createdAt',
  HOUSE_NUMBER: 'houseNumber',
  IS_VISIBLE: 'isVisible',
  LATITUDE: 'latitude',
  LONGITUDE: 'longitude',
  NAME: 'name',
  OPENING_HOURS: 'openingHours',
  OPERATOR_UUID: 'operatorUuid',
  PARKING_LOTS: 'parkingLots',
  POSTAL_CODE: 'postalCode',
  PRICE_MAX_PER_DAY_IN_CENT: 'priceMaxPerDayInCent',
  STREET: 'street',
  TELEPHONE_NUMBER: 'telephoneNumber',
  TIMEZONE: 'timezone',
  UPDATED_AT: 'updatedAt',
  USER_INSIDE_COUNT: 'userInsideCount',
  UUID: 'uuid',
}

/* ----- RESIDENTIAL PARKING ----------------------------------------------------------- */

export const RESIDENTIAL_PARKING_CAR_PARKS = {
  ADD_SINGLE_USER_EMAIL_TO_RESIDENTIAL_PARKING: `${ADMIN_API_BASE_PATH}/residential-car-park-email-assignment`,
  ADD_BULK_SINGLE_USER_EMAIL_TO_RESIDENTIAL_PARKING: `${ADMIN_API_BASE_PATH}/residential-car-park-bulk-assignment`,
  GET_CAR_PARK_RESIDENTIAL_PARKING_ASSIGNMENTS: `${ADMIN_API_BASE_PATH}/residential-car-park-assignment/`,
}

export const BODY_ASSIGNMENTS = {
  CAR_PARK_UUID: 'carParkUuid',
  USER_UUID: 'userUuid',
  UUID: 'uuid',
}
export const PARAMS_ASSIGNMENTS = {
  CAR_PARK_UUID: 'carParkUuid',
  ...PARAMS_PAGINATION,
}

/* ----- CHARGEPOINT ----------------------------------------------------------- */

export const CHARGEPOINT_CAR_PARKS = {
  GET_CHARGEPOINT: `${ADMIN_API_BASE_PATH}/chargepoint/car-park/`,
}

/* ----- BARRIERS ----------------------------------------------------------- */

export const PARAMS_BARRIERS = {
  CAR_PARK_UUIDS: 'carParkUuid',
  IS_ENTRANCE: 'isEntrance',
  IS_ONLINE: 'isOnline',
  OPERATOR_UUID: 'operatorUuid',
  OPERATING_STATE: 'state',
  ...PARAMS_PAGINATION,
}

export const BODY_BARRIERS = {
  BEACON_MAJOR: 'beaconMajor',
  BEACON_MINOR: 'beaconMinor',
  CAR_PARK_NAME: 'carParkName',
  CAR_PARK_UUID: 'carParkUuid',
  CREATED_AT: 'createdAt',
  IS_BEACON_ALIVE: 'isBeaconAlive',
  IS_ENTRANCE: 'isEntrance',
  IS_MOCK: 'isMock',
  IS_ONLINE: 'isOnline',
  IS_REBOOT_ALLOWED: 'isRebootAllowed',
  IS_SECRET_GENERATION_ALLOWED: 'isSecretGenerationAllowed',
  IS_SSH_TUNNEL_ENABLED: 'isSshTunnelEnabled',
  IS_UPDATE_ALLOWED: 'isUpdateAllowed',
  LAST_SUCCESSFUL_BEACON_CHECK_AT: 'lastSuccessfulBeaconCheckAt',
  LAST_UPDATE_CHECK_AT: 'lastUpdateCheckAt',
  MAC_ADDRESS: 'macAddress',
  NAME: 'name',
  OPERATOR_UUID: 'operatorUuid',
  SERVICE_UUID: 'serviceUuid',
  SOFTWARE_VERSION: 'softwareVersion',
  STATE: 'state',
  UPDATE_URL: 'updateUrl',
  UPDATED_AT: 'updatedAt',
  UUID: 'uuid',
}

export const BARRIER_STATE_VALUES = {
  notInstalled: 'NOT_INSTALLED',
  operating: 'OPERATING',
  underMaintenance: 'UNDER_MAINTENANCE',
}
