import React from 'react'
import { Delete as DeleteIcon } from '@mui/icons-material'

import IconChip from 'fragments/IconChips/IconChip'
import { useTranslation } from 'react-i18next'
/* -------------------------------------------------------------------------- */

const DeletedChip = () => {
  const { t } = useTranslation()

  return (
    <IconChip
      icon={<DeleteIcon style={{ color: 'white' }} />}
      label={t('DeletedChip.label')}
      chipStyle={{
        color: 'white',
        backgroundColor: 'red',
      }}
    />
  )
}

/* -------------------------------------------------------------------------- */
export default DeletedChip
