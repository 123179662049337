import React from 'react'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { isEmpty, reverse } from 'lodash'
import { List, ListItem, ListItemText } from '@mui/material'

import { BasicCardLoadable } from 'fragments/Loadables'
import TicketBarrierOpeningRow from 'components/Ticket/TicketBarrierOpeningRow'
import { BarrierOpening } from 'services/barrier-openings/barrier-openings-service'
const PREFIX = 'TicketBarrierOpeningsCard'

const classes = {
  scroll: `${PREFIX}-scroll`,
}

const StyledBasicCardLoadable = styled(BasicCardLoadable)({
  [`& .${classes.scroll}`]: {
    minHeight: 130,
    maxHeight: 600,
    overflow: 'auto',
  },
})

/* -------------------------------------------------------------------------- */

type TicketBarrierOpeningsCardProps = {
  barrierOpenings?: BarrierOpening[]
  isLoading: boolean
}

export default function TicketBarrierOpeningsCard(props: TicketBarrierOpeningsCardProps) {
  const { isLoading, barrierOpenings = [] } = props
  const { t } = useTranslation()

  return (
    <StyledBasicCardLoadable title={t('TicketBarrierOpeningsCard.barrierOpenings')} isLoading={isLoading}>
      <div className={classes.scroll}>
        <List>
          {reverse([...barrierOpenings]).map((opening) => (
            <TicketBarrierOpeningRow key={opening.uuid} opening={opening} />
          ))}
          {isEmpty(barrierOpenings) && (
            <ListItem alignItems='flex-start'>
              <ListItemText
                primary={t('TicketBarrierOpeningsCard.noActivity')}
                primaryTypographyProps={{ variant: 'body2' }}
              />
            </ListItem>
          )}
        </List>
      </div>
    </StyledBasicCardLoadable>
  )
}
