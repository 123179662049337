import React from 'react'
import { useSelector } from 'react-redux'
import * as PropTypes from 'prop-types'
import { Grid } from '@mui/material'

import { isTriveParkAdmin } from 'util/custom'
import InfoGeneralCard from 'components/CarPark/Info/InfoGeneralCard'
import InfoContactCard from 'components/CarPark/Info/InfoContactCard'
import InfoPricingCard from 'components/CarPark/Info/InfoPricingCard'
import InfoLocationCard from 'components/CarPark/Info/InfoLocationCard'
import InfoCapacityCard from 'components/CarPark/Info/InfoCapacityCard'
/* -------------------------------------------------------------------------- */

const InfoGrid = (props) => {
  const role = useSelector((state) => state.auth.user.role)
  const { selectedOperator } = useSelector((state) => state.globalFilters)

  const { isEdit, isCreate } = props

  const isTpAdmin = !selectedOperator && isTriveParkAdmin(role)

  return (
    <>
      <Grid item xs={12} sm={6} md={isTpAdmin ? 4 : 6}>
        <InfoGeneralCard isEdit={isEdit} isCreate={isCreate} />
      </Grid>
      <Grid item xs={12} sm={6} md={isTpAdmin ? 4 : 6}>
        <InfoContactCard isEdit={isEdit} isCreate={isCreate} />
      </Grid>
      <Grid item xs={12} sm={6} md={isTpAdmin ? 4 : 6}>
        <InfoPricingCard isEdit={isEdit} isCreate={isCreate} />
      </Grid>
      {isTpAdmin && (
        <Grid item xs={12} sm={6} md={6}>
          <InfoLocationCard isEdit={isEdit} isCreate={isCreate} />
        </Grid>
      )}
      <Grid item xs={12} sm={isTpAdmin ? 12 : 6} md={6}>
        <InfoCapacityCard isEdit={isEdit} isCreate={isCreate} />
      </Grid>
    </>
  )
}

InfoGrid.propTypes = {
  isEdit: PropTypes.bool,
  isCreate: PropTypes.bool,
}

InfoGrid.defaultProps = {
  isEdit: false,
  isCreate: false,
}

export default InfoGrid
