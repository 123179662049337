import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'

import { BasicPageContainerLoadable } from 'fragments/Loadables'
import PageGrid from 'fragments/Grid/PageGrid'
import ErrorAlertContainer from 'fragments/Alerts/ErrorAlertContainer'
import BarrierOpeningsFilterCard from 'components/BarrierOpenings/BarrierOpeningsFilterCard'
import BarrierOpeningsTableCard from 'components/BarrierOpenings/BarrierOpeningsTableCard'
import { INFREQUENT_UPDATE_TIME_IN_MS } from 'constants/generalConstants'
import {
  useGetBarrierOpeningsQuery,
  BarrierOpeningsType,
  usePrefetch,
} from 'services/barrier-openings/barrier-openings-service'
import LastUpdateInfo from 'fragments/Misc/LastUpdateInfo'
/* -------------------------------------------------------------------------- */

type RootState = {
  globalFilters: {
    selectedOperator: string
    selectedCarParks: string[]
  }
}

const initialType: BarrierOpeningsType[] = ['BLE', 'MANUAL', 'REMOTE']

export default function BarrierOpeningsContainer() {
  const { t } = useTranslation()
  const { selectedOperator, selectedCarParks } = useSelector((state: RootState) => state.globalFilters)
  const [page, setPage] = React.useState(0)
  const [rows, setRows] = React.useState(10)
  const [beforeDate, setBeforeDate] = React.useState<string>()
  const [type, setType] = React.useState(initialType)
  const prefetchBarrierOpenings = usePrefetch('getBarrierOpenings')
  const { data, fulfilledTimeStamp, isLoading, isFetching, error, refetch } = useGetBarrierOpeningsQuery(
    {
      before: beforeDate,
      carParkUuid: selectedCarParks,
      operatorUuid: selectedOperator,
      pageNumber: page,
      pageSize: rows,
      type: type,
    },
    { pollingInterval: INFREQUENT_UPDATE_TIME_IN_MS },
  )

  function resetFilters() {
    setBeforeDate(undefined)
    setType(initialType)
  }

  const prefetchNextPage = React.useCallback(
    () =>
      prefetchBarrierOpenings({
        before: beforeDate,
        carParkUuid: selectedCarParks,
        operatorUuid: selectedOperator,
        pageNumber: page + 1,
        pageSize: rows,
        type: type,
      }),
    [prefetchBarrierOpenings, beforeDate, selectedCarParks, selectedOperator, page, rows, type],
  )

  React.useEffect(() => {
    setPage(0)
  }, [selectedOperator, selectedCarParks, type, beforeDate, rows])

  React.useEffect(() => {
    if (!data?.isLastPage) {
      prefetchNextPage()
    }
  }, [data, prefetchNextPage])

  return (
    <BasicPageContainerLoadable
      helmetTitle={t('BarrierOpeningsContainer.barrierOpenings')}
      pageTitle={t('BarrierOpeningsContainer.barrierOpenings')}
    >
      <ErrorAlertContainer errors={[error]} />
      <PageGrid>
        <Grid item xs={12}>
          <BarrierOpeningsFilterCard
            resetFilters={resetFilters}
            isLoading={isLoading}
            beforeDate={beforeDate}
            setBeforeDate={setBeforeDate}
            statusGroup={type}
            setStatusGroup={setType}
          />
        </Grid>
        <Grid item xs={12}>
          <BarrierOpeningsTableCard
            barrierOpenings={data?.content ?? []}
            page={page}
            setPage={setPage}
            rows={rows}
            setRows={setRows}
            totalCount={data?.totalElements}
            isLoading={isLoading}
          />
        </Grid>
        <Grid item xs={12}>
          <LastUpdateInfo
            lastSuccessDate={fulfilledTimeStamp}
            isUpdating={isFetching}
            reloadIntervalInMs={INFREQUENT_UPDATE_TIME_IN_MS}
            refetch={refetch}
          />
        </Grid>
      </PageGrid>
    </BasicPageContainerLoadable>
  )
}
