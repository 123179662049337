import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'

const buttonStyling = {
  textTransform: 'none',
}

const EditButton = (props) => {
  const { t } = useTranslation()

  return (
    <Button type='button' color='primary' variant='outlined' sx={buttonStyling} {...props}>
      {t('EditButton.edit')}
    </Button>
  )
}

export default EditButton
