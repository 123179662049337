import React from 'react'
import { useSelector } from 'react-redux'
import Grid from '@mui/material/Grid'
import { useTranslation } from 'react-i18next'
import { Smartphone as SmartphoneIcon } from '@mui/icons-material'

import { BasicCardLoadable } from 'fragments/Loadables'
import InfoRows from 'fragments/Cards/InfoRows'
import LanguageFlagIconWithString from 'fragments/Icons/LanguageFlagIconWithString'
/* -------------------------------------------------------------------------- */
const DeviceCard = () => {
  const {
    isGettingAppUser,
    appUser: { osVersion, appVersion, device, languageCode },
  } = useSelector((state) => state.appUser)
  const { t } = useTranslation()

  const rows = [
    {
      label: t('DeviceCard.osVersion'),
      value: osVersion,
    },
    {
      label: t('DeviceCard.appVersion'),
      value: appVersion,
    },
    {
      label: t('DeviceCard.device'),
      value: device,
    },
    {
      label: t('DeviceCard.deviceLanguage'),
      value: <LanguageFlagIconWithString languageCode={languageCode} />,
    },
  ]

  return (
    <BasicCardLoadable isLoading={isGettingAppUser} title={t('DeviceCard.device')} action={<SmartphoneIcon />}>
      <Grid container spacing={1}>
        <InfoRows rows={rows} />
      </Grid>
    </BasicCardLoadable>
  )
}

export default DeviceCard
