import React from 'react'
import { useSelector } from 'react-redux'
import * as PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { Switch } from 'formik-mui'
import { Field } from 'formik'
import { Info as InfoIcon } from '@mui/icons-material'

import { isTriveParkAdmin, getOperatorNameAsString, getOperatorSelects } from 'util/custom'
import { BasicCardLoadable } from 'fragments/Loadables'
import InfoRows from 'fragments/Cards/InfoRows'
import VisibilityChip from 'fragments/IconChips/VisibilityChip'
import SelectTextField from 'fragments/Forms/Formik/SelectTextField'
import TextField from 'fragments/Forms/Formik/TextField'
/* -------------------------------------------------------------------------- */

const InfoGeneralCard = (props) => {
  const role = useSelector((state) => state.auth.user.role)
  const {
    isGettingCarPark,
    carPark: { name: carParkName, operatorUuid, isVisible },
  } = useSelector((state) => state.carPark)
  const { operators } = useSelector((state) => state.operators)
  const { selectedOperator } = useSelector((state) => state.globalFilters)
  const { t } = useTranslation()
  const { isEdit, isCreate } = props

  const isTpAdmin = !selectedOperator && isTriveParkAdmin(role)

  const operatorSelect = () => (
    <SelectTextField name='operatorUuid' variant='standard' options={getOperatorSelects(operators)} />
  )

  const visibilitySwitch = () => <Field component={Switch} type='checkbox' name='isVisible' color='primary' />

  const rows = [
    {
      label: t('InfoGeneralCard.operator') + (isCreate || isEdit ? ' *' : ''),
      value: (isEdit || isCreate) && isTpAdmin ? operatorSelect() : getOperatorNameAsString(t, operatorUuid, operators),
    },
    {
      label: t('InfoGeneralCard.carParkName') + (isCreate || isEdit ? ' *' : ''),
      value: isEdit || isCreate ? <TextField name='name' variant='standard' /> : carParkName,
    },
    {
      label: t('InfoGeneralCard.visibility'),
      value: isEdit || isCreate ? visibilitySwitch() : <VisibilityChip isVisible={isVisible} />,
    },
  ]

  return (
    <BasicCardLoadable title={t('InfoGeneralCard.title')} action={<InfoIcon />} isLoading={isGettingCarPark}>
      <Grid container spacing={1}>
        <InfoRows rows={rows} />
      </Grid>
    </BasicCardLoadable>
  )
}

InfoGeneralCard.propTypes = {
  isEdit: PropTypes.bool,
  isCreate: PropTypes.bool,
}

InfoGeneralCard.defaultProps = {
  isEdit: false,
  isCreate: false,
}

export default InfoGeneralCard
