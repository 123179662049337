// import { History } from 'history'
import { TFunction } from 'i18next'

import { muiDataTableOptions } from 'fragments/tables/TableConfig'
import { UserAccount } from './ResidentialParkingTableCard'
import { NavigateFunction } from 'react-router-dom'
/* -------------------------------------------------------------------------- */

type Options = {
  users: UserAccount[]
  onChangePage?: (n: number) => void
  onChangeRowsPerPage?: (n: number) => void
  onRowClick?: (row: number) => void
  history: NavigateFunction
  page?: number
  rows?: number
  t: TFunction
  totalCount?: number
  search?: string
  onSearchChange?: (s: string) => void
}

const options = ({
  users,
  history,
  onChangePage,
  onChangeRowsPerPage,
  onRowClick,
  page,
  rows,
  t,
  totalCount,
  onSearchChange,
  search,
}: Options) => ({
  count: totalCount,
  onChangePage,
  onChangeRowsPerPage,
  onRowClick,
  onSearchChange,
  page,
  history,
  users,
  rowsPerPage: rows,
  search: true,
  searchText: search,
  searchPlaceholder: t('ResidentialParkingTableOptions.searchPlaceholder'),
  serverSide: false,
})

export default (args: Options) => ({
  ...muiDataTableOptions(),
  ...options(args),
})
