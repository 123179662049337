import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'

const buttonStyling = {
  textTransform: 'none',
}

const CancelButton = (props) => {
  const { t } = useTranslation()

  return (
    <Button type='button' color='secondary' variant='outlined' sx={buttonStyling} {...props}>
      {t('CancelButton.cancel')}
    </Button>
  )
}

export default CancelButton
