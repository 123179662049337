import React from 'react'
import { Chip } from '@mui/material'

import { useTranslation } from 'react-i18next'
import { TicketUsage } from 'services/tickets/tickets-service'
/* -------------------------------------------------------------------------- */

type TicketUsageChipProps = {
  value?: TicketUsage
}

export default function TicketUsageChip(props: TicketUsageChipProps) {
  const { t } = useTranslation()
  const { value } = props

  function getTicketUsageAsString(type?: TicketUsage) {
    const validType = ['NeverUsed', 'InUse', 'NotInUseAnymore'].some((v) => v === type)

    return validType
      ? // i18next-extract-mark-context-next-line ["NeverUsed", "InUse", "NotInUseAnymore"]
        (t('ticket.usageStatus', {
          context: type,
        }) as string)
      : (t('ticket.usageStatus') as string)
  }

  function getTicketUsageStyle(type?: TicketUsage) {
    switch (type) {
      case 'NeverUsed': {
        return { color: 'white', background: '#677080' }
      }
      case 'InUse': {
        return { color: 'white', background: '#26a1f1' }
      }
      case 'NotInUseAnymore': {
        return { color: 'white', background: '#FFB70F' }
      }
      default: {
        break
      }
    }
  }

  return <Chip size='small' label={getTicketUsageAsString(value)} style={getTicketUsageStyle(value)} />
}
