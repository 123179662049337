import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Paper, Tabs, Tab } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { uniqueId } from 'lodash'

import { QUERY_PARAMS } from 'routes/routes'
import { getQueryString, getParsedQueryParam } from 'util/core'
import tabConfig from 'components/AppUser/tabConfig'
import { useDesktop } from 'hooks'
/* -------------------------------------------------------------------------- */

const AppUserTabBar = () => {
  const { t } = useTranslation()
  let navigate = useNavigate()
  const location = useLocation()
  const isDesktop = useDesktop()
  const uuid = getParsedQueryParam(location.search, QUERY_PARAMS.UUID)
  const tabNum = tabConfig.findIndex((tab) => tab.path === location.pathname)
  const [tab, setTab] = useState(tabNum ? tabNum : 0)

  useEffect(() => {
    setTab(tabNum)
  }, [location.pathname, tabNum])

  const a11yProps = (index) => ({
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  })

  const handleChange = (_, newTab) => {
    setTab(newTab)
    const newPath = tabConfig[newTab].path
    navigate(
      {
        pathname: newPath,
        search: getQueryString({
          [QUERY_PARAMS.UUID]: uuid,
        }),
      },
      { state: { from: location }, replace: true },
    )
  }

  return (
    <Paper>
      <Tabs
        value={tab}
        onChange={handleChange}
        aria-label='Tabs'
        indicatorColor='primary'
        variant='scrollable'
        textColor='primary'
      >
        {tabConfig.map((tab, index) => (
          // i18next-extract-mark-context-next-line ["info", "payments", "connections"]
          <Tab
            key={uniqueId()}
            label={t('AppUserTabBar.tab', { context: tab.label })}
            {...a11yProps(index)}
            style={{ minWidth: isDesktop ? '160px' : '90px' }}
          />
        ))}
      </Tabs>
    </Paper>
  )
}

export default AppUserTabBar
