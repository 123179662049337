import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import * as PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import { Assessment as AssessmentIcon } from '@mui/icons-material'

import { BasicCardLoadable } from 'fragments/Loadables'
import InfoRows from 'fragments/Cards/InfoRows'
import { calcOccupancyInPercent } from 'util/custom'
import NumberField from 'fragments/Forms/Formik/NumberField'
/* -------------------------------------------------------------------------- */

const InfoCapacityCard = (props) => {
  const {
    isGettingCarPark,
    carPark: { countParkingLots, countUserInside },
  } = useSelector((state) => state.carPark)
  const { t } = useTranslation()
  const { isEdit, isCreate } = props

  const occupancy = calcOccupancyInPercent(countUserInside, countParkingLots)

  const inputCountParkingLots = () => <NumberField name='countParkingLots' min={0} step={1} variant='standard' />

  const inputCountUserInside = () => <NumberField name='countUserInside' min={0} step={1} variant='standard' />

  const rows = [
    {
      label: t('InfoCapacityCard.countParkingLots'),
      value: isEdit || isCreate ? inputCountParkingLots() : countParkingLots,
    },
    {
      label: t('InfoCapacityCard.countUserInside'),
      value: isEdit || isCreate ? inputCountUserInside() : countUserInside,
    },
    {
      label: t('InfoCapacityCard.occupancy'),
      value: occupancy && occupancy + '%',
      hideOn: isEdit || isCreate,
    },
  ]

  return (
    <BasicCardLoadable title={t('InfoCapacityCard.title')} action={<AssessmentIcon />} isLoading={isGettingCarPark}>
      <Grid container spacing={1}>
        <InfoRows rows={rows} />
      </Grid>
    </BasicCardLoadable>
  )
}

InfoCapacityCard.propTypes = {
  isEdit: PropTypes.bool,
  isCreate: PropTypes.bool,
}

InfoCapacityCard.defaultProps = {
  isEdit: false,
  isCreate: false,
}

export default InfoCapacityCard
