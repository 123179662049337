import {
  ACTIVATE_ADMIN,
  MODIFY_PASSWORD,
  RESET_ACCOUNT_MGMT_FLAGS,
  RESET_PASSWORD,
  RESET_PASSWORD_REQUEST,
  DELETE_USER,
} from 'redux/Types'
/* -------------------------------------------------------------------------- */

// ----- REQUEST PASSWORD RESET -----

export const resetPasswordRequestAct = ({ email }) => ({
  type: RESET_PASSWORD_REQUEST.REQUEST,
  email,
})

export const resetPasswordRequestSuccessAct = () => ({
  type: RESET_PASSWORD_REQUEST.SUCCESS,
})

export const resetPasswordRequestErrorAct = (error) => ({
  type: RESET_PASSWORD_REQUEST.ERROR,
  error,
})

// ----- RESET PASSWORD -----

export const resetPasswordAct = ({ password, token }) => ({
  type: RESET_PASSWORD.REQUEST,
  newPassword: password,
  token,
})

export const resetPasswordSuccessAct = () => ({
  type: RESET_PASSWORD.SUCCESS,
})

export const resetPasswordErrorAct = (error) => ({
  type: RESET_PASSWORD.ERROR,
  error,
})

// ----- SET NEW PASSWORD -----

export const modifyPasswordAct = ({ oldPassword, newPassword }) => ({
  type: MODIFY_PASSWORD.REQUEST,
  oldPassword,
  newPassword,
})

export const modifyPasswordSuccessAct = () => ({
  type: MODIFY_PASSWORD.SUCCESS,
})

export const modifyPasswordErrorAct = (error) => ({
  type: MODIFY_PASSWORD.ERROR,
  error,
})

// ----- RESET FLAGS -----

export const resetAccountMgmtFlagsAct = () => ({
  type: RESET_ACCOUNT_MGMT_FLAGS,
})

// ----- ACTIVATION -----

export const activateAdminAct = ({ password, token }) => ({
  type: ACTIVATE_ADMIN.REQUEST,
  newPassword: password,
  token,
})

export const activateAdminSuccessAct = () => ({
  type: ACTIVATE_ADMIN.SUCCESS,
})

export const activateAdminErrorAct = (error) => ({
  type: ACTIVATE_ADMIN.ERROR,
  error,
})

// ----- DELETE USER -----

export const deleteUserAct = ({ accountUuid }) => ({
  type: DELETE_USER.REQUEST,
  accountUuid,
})
