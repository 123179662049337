import React from 'react'
import * as PropTypes from 'prop-types'
import { Android as AndroidIcon, Apple as AppleIcon } from '@mui/icons-material'

import { OS_TYPES } from 'constants/generalConstants'
import IconChip from 'fragments/IconChips/IconChip'
import { useTranslation } from 'react-i18next'
/* -------------------------------------------------------------------------- */
const OsChip = (props) => {
  const { osString } = props
  const { t } = useTranslation()
  const { ANDROID, IOS } = OS_TYPES

  const getOsType = (osString) => {
    let regex = new RegExp('^[A-Za-z]*')
    return osString && osString.match(regex)[0]
  }

  const getOsChipIcon = (osString) => {
    switch (getOsType(osString)) {
      case ANDROID:
        return <AndroidIcon style={{ color: 'white' }} />
      case IOS:
        return <AppleIcon style={{ color: 'white' }} />
      default:
        return undefined
    }
  }

  const getOsChipStyle = (osString) => {
    switch (getOsType(osString)) {
      case ANDROID:
        return {
          color: 'white',
          backgroundColor: 'green',
        }
      case IOS:
        return {
          color: 'white',
          background: '#42b3f5',
        }
      default:
        return undefined
    }
  }

  const getOsChipLabel = (osString) => (osString ? t('OsChip.osVersion', { osVersion: osString }) : t('OsChip.unknown'))

  return (
    <IconChip icon={getOsChipIcon(osString)} label={getOsChipLabel(osString)} chipStyle={getOsChipStyle(osString)} />
  )
}

/* -------------------------------------------------------------------------- */

OsChip.propTypes = {
  osString: PropTypes.string,
}

OsChip.defaultProps = {
  osString: null,
}

export default OsChip
