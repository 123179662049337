import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { BasicCardLoadable } from 'fragments/Loadables'
import MuiDataTableThemed from 'fragments/tables/MuiDataTableThemed'
import columns from './ResidentialParkingTableColumns'
import options from './ResidentialParkingTableOptions'
import ConfirmDialog from 'fragments/Dialogs/ConfirmDialog'
import { removeUserFromResidentialParking } from 'redux/Actions'
import { QUERY_PARAMS } from 'routes/routes'
import { getParsedQueryParam } from 'util/core'
/* -------------------------------------------------------------------------- */

type ResidentialParkingTableCardProps = {
  users: any[]
  assignments: any[]
  page?: number
  setPage?: (n: number) => void
  rows?: number
  setRows?: (n: number) => void
  totalCount?: number
  isLoading?: boolean
  search?: string
  onSearchChange?: (s: string) => void
}

export type UserAccount = {
  firstName: string
  lastName: string
  email: string
  licensePlate: string
  uuid: string
  assignmentUuid: string
}

export default function ResidentialParkingTableCard(props: ResidentialParkingTableCardProps) {
  const { users, assignments, page, setPage, rows, setRows, totalCount, isLoading, search, onSearchChange } = props
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const history = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const [showDeleteDialog, setDeleteDialog] = useState(false)
  const [rowClicked, setRowClicked] = useState([])
  const [tableUsers, setTableUsers] = useState(users)
  const carParkUuid = getParsedQueryParam(location.search, QUERY_PARAMS.UUID)

  const getTransformUserAccounts = () => {
    let userAccounts: UserAccount[] = []
    for (let i = 0; i < users.length; i++) {
      const user = users[i]
      let userAccount: UserAccount = {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        licensePlate: user.licensePlate,
        uuid: user.uuid,
        assignmentUuid: '',
      }
      for (let j = 0; j < assignments.length; j++) {
        const assignment = assignments[j]
        if (assignment.email === users[i].email) {
          userAccount.assignmentUuid = assignment.uuid
          break
        }
      }
      userAccounts.push(userAccount)
    }
    return userAccounts
  }

  useEffect(() => {
    setTableUsers(getTransformUserAccounts())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users, assignments])

  const handleSubmitDelete = () => {
    //find user
    let userUuid
    for (let i = 0; i < users.length; i++) {
      if (rowClicked[2] === users[i].email) {
        userUuid = users[i].uuid
        break
      }
    }
    //match user with assignment
    let assignmentUuid
    for (let j = 0; j < assignments.length; j++) {
      if (userUuid === assignments[j].userUuid) {
        assignmentUuid = assignments[j].uuid
        break
      }
    }
    setDeleteDialog(false)
    dispatch(removeUserFromResidentialParking(assignmentUuid, carParkUuid))
  }

  const handleRowClick = (row: any) => {
    setRowClicked(row)
    setDeleteDialog(true)
  }

  return (
    <BasicCardLoadable isLoading={isLoading}>
      <MuiDataTableThemed
        data={tableUsers}
        columns={columns(tableUsers, language as 'en' | 'de', t)}
        options={options({
          users: tableUsers,
          onChangePage: setPage,
          onChangeRowsPerPage: setRows,
          onRowClick: handleRowClick,
          history,
          page,
          rows,
          t,
          totalCount,
          search,
          onSearchChange,
        })}
      />
      <ConfirmDialog
        open={showDeleteDialog}
        dialogTitle={t('CarParksResidentialContainer.dialogueTitleDeleteAccount') as string}
        dialogContent={
          (t('CarParksResidentialContainer.dialogueDeleteContent') as string) + (rowClicked ? rowClicked[2] : '')
        }
        confirmLabel={t('CarParksResidentialContainer.confirm') as string}
        cancelLabel={t('CarParksResidentialContainer.cancel') as string}
        onConfirm={handleSubmitDelete}
        onClose={() => setDeleteDialog(false)}
      />
    </BasicCardLoadable>
  )
}
