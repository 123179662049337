import React, { useState } from 'react'
import * as PropTypes from 'prop-types'
import { Field } from 'formik'
import { TextField } from 'formik-mui'
import { InputAdornment, IconButton } from '@mui/material'
import { Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon } from '@mui/icons-material'
/* -------------------------------------------------------------------------- */

const PasswordField = (props) => {
  const { name, autoComplete, label, fullWidth } = props

  const [showPassword, toggleVisibility] = useState(false)

  const handleClickShowPassword = () => toggleVisibility((showPassword) => !showPassword)

  const handleMouseDownPassword = (event) => event.preventDefault()

  return (
    <Field
      component={TextField}
      variant='standard'
      type={showPassword ? 'string' : 'password'}
      name={name}
      autoComplete={autoComplete}
      label={label}
      fullWidth={fullWidth}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton
              aria-label='toggle password visibility'
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              size='large'
            >
              {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}

PasswordField.propTypes = {
  name: PropTypes.string.isRequired,
  autoComplete: PropTypes.string,
  label: PropTypes.string,
  fullWidth: PropTypes.bool,
}

PasswordField.defaultProps = {
  autoComplete: null,
  label: null,
  fullWidth: false,
}

export default PasswordField
