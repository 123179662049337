import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Grid, InputAdornment } from '@mui/material'
import * as PropTypes from 'prop-types'
import { Euro as EuroIcon } from '@mui/icons-material'

import { BasicCardLoadable } from 'fragments/Loadables'
import InfoRows from 'fragments/Cards/InfoRows'
import { getTimeAmountString, getTimeUnitSelects } from 'util/core'
import NumberField from 'fragments/Forms/Formik/NumberField'
import SelectTextField from 'fragments/Forms/Formik/SelectTextField'
import TextField from 'fragments/Forms/Formik/TextField'
/* -------------------------------------------------------------------------- */

const InfoPricingCard = (props) => {
  const {
    isGettingCarPark,
    carPark: { openingHours, continuityPeriodInMinutes, continuityPriceInCent, priceMaxPerDayInCent },
  } = useSelector((state) => state.carPark)
  const { t } = useTranslation()
  const { isEdit, isCreate } = props

  const priceMaxPerDayInEuroInput = () => (
    <NumberField
      name='priceMaxPerDayInEuro'
      min={0}
      step={0.01}
      endAdornment={<InputAdornment position='end'>&euro; / {getTimeAmountString(t, 24 * 60)}</InputAdornment>}
      variant='standard'
    />
  )

  const continuityRate = () => (
    <span style={{ display: 'flex' }}>
      <NumberField
        name='continuityPriceInEuro'
        min={0}
        step={0.01}
        endAdornment={<InputAdornment position='end'>&euro; / </InputAdornment>}
        variant='standard'
      />
      <NumberField d name='continuityPeriodDuration' min={0} step={1} variant='standard' />
      <SelectTextField name='continuityPeriodUnit' options={getTimeUnitSelects(t)} variant='standard' />
    </span>
  )

  const rows = [
    {
      label: t('InfoPricingCard.continuityRate'),
      value:
        isEdit || isCreate
          ? continuityRate()
          : t('InfoPricingCard.pricePerPeriod', {
              cents: continuityPriceInCent,
              timeAmount: getTimeAmountString(t, continuityPeriodInMinutes),
            }),
    },
    {
      label: t('InfoPricingCard.priceMax'),
      value:
        isEdit || isCreate
          ? priceMaxPerDayInEuroInput()
          : t('InfoPricingCard.pricePerPeriod', {
              cents: priceMaxPerDayInCent,
              timeAmount: getTimeAmountString(t, 24 * 60),
            }),
    },
    {
      label: t('InfoPricingCard.openingHours'),
      value: isEdit || isCreate ? <TextField name='openingHours' variant='standard' /> : openingHours,
    },
  ]

  return (
    <BasicCardLoadable title={t('InfoPricingCard.title')} action={<EuroIcon />} isLoading={isGettingCarPark}>
      <Grid container spacing={1}>
        <InfoRows rows={rows} />
      </Grid>
    </BasicCardLoadable>
  )
}

InfoPricingCard.propTypes = {
  isEdit: PropTypes.bool,
  isCreate: PropTypes.bool,
}

InfoPricingCard.defaultProps = {
  isEdit: false,
  isCreate: false,
}

export default InfoPricingCard
