import React from 'react'
import { useSelector } from 'react-redux'
import Grid from '@mui/material/Grid'
import { useTranslation } from 'react-i18next'
import { AccountBalance as AccountBalanceIcon } from '@mui/icons-material'

import { BasicCardLoadable } from 'fragments/Loadables'
import InfoRows from 'fragments/Cards/InfoRows'
/* -------------------------------------------------------------------------- */
const LegalCard = () => {
  const {
    isGettingAppUser,
    appUser: { GDPRTimestamp, termsTimestamp },
  } = useSelector((state) => state.appUser)
  const { t } = useTranslation()

  const rows = [
    {
      label: t('LegalCard.acceptedGDPR'),
      value: t('LegalCard.dateMediumTimeShort', { date: GDPRTimestamp }),
    },
    {
      label: t('LegalCard.acceptedTerms'),
      value: t('LegalCard.dateMediumTimeShort', { date: termsTimestamp }),
    },
  ]

  return (
    <BasicCardLoadable isLoading={isGettingAppUser} title={t('LegalCard.legal')} action={<AccountBalanceIcon />}>
      <Grid container spacing={1}>
        <InfoRows rows={rows} />
      </Grid>
    </BasicCardLoadable>
  )
}

export default LegalCard
