import React from 'react'
import * as PropTypes from 'prop-types'
import { Chip } from '@mui/material'
/* -------------------------------------------------------------------------- */

const DotChip = ({ color, size }) => (
  <Chip size='small' label='' style={{ background: color, padding: 0, width: size, height: size }} />
)

DotChip.propTypes = {
  background: PropTypes.string,
  size: PropTypes.number,
}

DotChip.defaultProps = {
  background: 'grey',
  size: 12,
}

export default DotChip
