import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { Link, useLocation } from 'react-router-dom'

import { BasicPageContainerLoadable } from 'fragments/Loadables'
import ErrorAlertContainer from 'fragments/Alerts/ErrorAlertContainer'
import LastUpdateInfo from 'fragments/Misc/LastUpdateInfo'
import PageGrid from 'fragments/Grid/PageGrid'
import { EditButton } from 'fragments/Buttons'
import OfferGrid from 'components/Offer/OfferGrid'
import OldOfferSnackbar from 'components/Offer/OldOfferSnackbar'
import { getCarParksAct, getOfferByUuidAct, resetOfferAct } from 'redux/Actions'
import { getQueryString, getParsedQueryParam } from 'util/core'
import { FREQUENT_UPDATE_TIME_IN_MS } from 'constants/generalConstants'
import { useInterval } from 'hooks'
import { APP, QUERY_PARAMS } from 'routes/routes'
/* -------------------------------------------------------------------------- */

const OfferContainer = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const {
    errGettingOfferByUuid,
    isGettingOfferByUuid,
    isReGettingOfferByUuid,
    lastSuccessDate,
    offer: { localizedNames },
  } = useSelector((state) => state.offer)

  const offerName = localizedNames?.[language.substring(0, 2)] ?? null

  const title = !!offerName ? offerName : t('OfferContainer.title')

  const uuid = getParsedQueryParam(location.search, QUERY_PARAMS.UUID)
  const versionUuid = getParsedQueryParam(location.search, QUERY_PARAMS.VERSION)

  useEffect(() => {
    dispatch(getOfferByUuidAct({ uuid, versionUuid }))
    dispatch(getCarParksAct())
    return () => {
      dispatch(resetOfferAct())
    }
  }, [dispatch, uuid, versionUuid])

  useInterval(() => {
    if (uuid) {
      dispatch(getOfferByUuidAct({ uuid, versionUuid, isReload: true }))
    }
  }, FREQUENT_UPDATE_TIME_IN_MS)

  return (
    <BasicPageContainerLoadable helmetTitle={title} pageTitle={title}>
      <OldOfferSnackbar />
      <ErrorAlertContainer errors={[errGettingOfferByUuid]} />
      <PageGrid>
        <OfferGrid />
        <Grid container item justifyContent='space-between' alignItems='flex-start'>
          <LastUpdateInfo
            lastSuccessDate={lastSuccessDate}
            isUpdating={isGettingOfferByUuid || isReGettingOfferByUuid}
            reloadIntervalInMs={FREQUENT_UPDATE_TIME_IN_MS}
          />
          {!versionUuid && (
            <EditButton
              component={Link}
              to={{
                pathname: APP.OFFER_EDIT,
                search: getQueryString({ [QUERY_PARAMS.UUID]: uuid }),
              }}
              state={{ from: location }}
            />
          )}
        </Grid>
      </PageGrid>
    </BasicPageContainerLoadable>
  )
}

export default OfferContainer
