import React from 'react'
import { styled } from '@mui/material/styles'
import * as PropTypes from 'prop-types'
import { Typography } from '@mui/material'
const PREFIX = 'PlaceHolderText'

const classes = {
  root: `${PREFIX}-root`,
}

const StyledTypography = styled(Typography)({ [`& .${classes.root}`]: { fontStyle: 'oblique' } })

const PlaceHolderText = (props) => {
  const { text } = props

  return (
    <StyledTypography variant='body2' color='secondary' classes={classes}>
      {text}
    </StyledTypography>
  )
}

PlaceHolderText.propTypes = {
  text: PropTypes.string.isRequired,
}

export default PlaceHolderText
