import { all } from 'redux-saga/effects'

import account from 'redux/Account/AccountSaga'
import admins from 'redux/Admins/AdminsSaga'
import admin from 'redux/Admin/AdminSaga'
import auth from 'redux/Auth/AuthSaga'
import appUser from 'redux/AppUser/AppUserSaga'
import appUsers from 'redux/AppUsers/AppUsersSaga'
import barriers from 'redux/Barriers/BarriersSaga'
import barrier from 'redux/Barrier/BarrierSaga'
import carParks from 'redux/CarParks/CarParksSaga'
import carPark from 'redux/CarPark/CarParkSaga'
import error from 'redux/Error/ErrorSaga'
import occupancy from 'redux/Occupancy/OccupancySaga'
import offer from 'redux/Offer/OfferSaga'
import offers from 'redux/Offers/OffersSaga'
import operator from 'redux/Operator/OperatorSaga'
import operators from 'redux/Operators/OperatorsSaga'
import residentialParkingAssignments from 'redux/ResidentialParkingAssignments/ResidentialParkingAssignmentsSaga'
import sales from 'redux/Sales/SalesSaga'
import statistics from 'redux/Statistics/StatisticsSaga'
/* -------------------------------------------------------------------------- */

export default function* rootSaga() {
  yield all([
    account(),
    admins(),
    admin(),
    admins(),
    appUser(),
    appUsers(),
    auth(),
    barriers(),
    barrier(),
    carPark(),
    carParks(),
    error(),
    occupancy(),
    offer(),
    offers(),
    operator(),
    operators(),
    residentialParkingAssignments(),
    sales(),
    statistics(),
  ])
}
