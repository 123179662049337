import React from 'react'
import { styled } from '@mui/material/styles'
import { TableFooter, TableRow, TableCell, TablePagination } from '@mui/material'
const PREFIX = 'CustomTableFooter'

const classes = {
  footerStyle: `${PREFIX}-footerStyle`,
}

const StyledTableFooter = styled(TableFooter)({
  [`& .${classes.footerStyle}`]: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '0px 24px 0px 24px',
  },
})

const CustomTableFooter = (props) => {
  const { count, textLabels, rowsPerPage, page } = props

  const handleRowChange = (event) => {
    props.changeRowsPerPage(event.target.value)
  }

  const handlePageChange = (_, page) => {
    props.changePage(page)
  }

  return (
    <StyledTableFooter>
      <TableRow>
        <TableCell className={classes.footerStyle}>
          <TablePagination
            component='div'
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            labelRowsPerPage={textLabels.rowsPerPage}
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${textLabels.displayRows} ${count}`}
            backIconButtonProps={{
              'aria-label': textLabels.previous,
            }}
            nextIconButtonProps={{
              'aria-label': textLabels.next,
            }}
            rowsPerPageOptions={[10, 25, 50]}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowChange}
          />
        </TableCell>
      </TableRow>
    </StyledTableFooter>
  )
}

export default CustomTableFooter
