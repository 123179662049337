import React from 'react'
import { styled } from '@mui/material/styles'
import { ListItem, ListItemIcon, ListItemText } from '@mui/material'
import { useTranslation } from 'react-i18next'

import DotChip from 'fragments/IconChips/DotChip'
import { TicketPayment, TicketPaymentStatus } from 'services/tickets/tickets-service'
const PREFIX = 'TicketPaymentRow'

const classes = {
  itemIcon: `${PREFIX}-itemIcon`,
}

const StyledListItem = styled(ListItem)(({ theme }) => ({
  [`& .${classes.itemIcon}`]: {
    minWidth: 12 + parseInt(theme.spacing(1)),
  },
}))

/*-------------------------------------------------------------------------- */

type TicketPaymentRowProps = {
  payment: TicketPayment
}

function getTicketPaymentColor(status: TicketPaymentStatus) {
  switch (status) {
    case 'requires_payment_method': {
      return '#ffb70f'
    }
    case 'requires_confirmation': {
      return '#ffb70f'
    }
    case 'requires_action': {
      return 'grey'
    }
    case 'requires_capture': {
      return 'grey'
    }
    case 'processing': {
      return '#ffb70f'
    }
    case 'canceled': {
      return '#ff3739'
    }
    case 'succeeded': {
      return '#00d014'
    }
    default: {
      return 'grey'
    }
  }
}

export default function TicketPaymentRow(props: TicketPaymentRowProps) {
  const { payment } = props

  const { t } = useTranslation()

  const color = getTicketPaymentColor(payment?.status)
  // i18next-extract-mark-context-next-line ["", "requires_payment_method", "requires_confirmation", "requires_action", "requires_capture", "processing", "canceled", "succeeded" ]
  const statusString = t('TicketPaymentRow.status', { context: payment?.status })

  return (
    <StyledListItem>
      <ListItemIcon classes={{ root: classes.itemIcon }}>
        <DotChip color={color} />
      </ListItemIcon>
      <ListItemText
        primary={t('TicketPaymentRow.paymentRow', {
          date: payment?.updatedAt,
          amount: payment?.amountInCent,
          status: statusString,
        })}
        primaryTypographyProps={{ variant: 'body2' }}
      />
    </StyledListItem>
  )
}
