import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import * as PropTypes from 'prop-types'
import { AccessTime as AccessTimeIcon } from '@mui/icons-material'

import { BasicCardLoadable } from 'fragments/Loadables'
import InfoRows from 'fragments/Cards/InfoRows'
import SelectTextField from 'fragments/Forms/Formik/SelectTextField'
import NumberField from 'fragments/Forms/Formik/NumberField'
import CustomDateTimePicker from 'fragments/Forms/Formik/CustomDateTimePicker'
import CustomTimePicker from 'fragments/Forms/Formik/CustomTimePicker'
import { OFFER_TYPE_VALUES } from 'api/offerMgmt/offerMgmtModels'
import { getTimeUnitSelects, getTimeAmountString, timeByMinFromMidnight } from 'util/core'
/* -------------------------------------------------------------------------- */

const OfferTimingCard = (props) => {
  const { t } = useTranslation()
  const offer = useSelector((state) => state?.offer)

  const { isCreate, isEdit, formikProps } = props
  const type = formikProps?.values?.type ?? null
  const isGettingOfferByUuid = offer.isGettingOfferByUuid
  const accessEarliestInMinFromMidnight = offer.offer?.accessEarliestInMinFromMidnight ?? null
  const accessLatestInMinFromMidnight = offer.offer?.accessLatestInMinFromMidnight ?? null
  const availableFrom = offer.offer?.availableFrom ?? null
  const availableUntil = offer.offer?.availableUntil ?? null
  const validityInMin = offer.offer?.validityInMin ?? null

  const validityInput = () => (
    <NumberField
      name='validityDuration'
      min={0}
      step={1}
      variant='standard'
      endAdornment={<SelectTextField name='validityUnit' options={getTimeUnitSelects(t)} variant='standard' />}
    />
  )

  const availableInput = (name) => (
    <CustomDateTimePicker name={name} disableFuture={false} disablePast error={formikProps?.errors[name]} />
  )

  const accessTimeInput = (name) => <CustomTimePicker showTodayButton={false} name={name} />

  const rows = [
    {
      label: t('OfferTimingCard.validity') + (isCreate || isEdit ? ' *' : ''),
      value: isCreate || isEdit ? validityInput() : getTimeAmountString(t, validityInMin),
      hideOn: type === OFFER_TYPE_VALUES.LONG_TERM,
    },
    {
      label: t('OfferTimingCard.availableFrom'),
      value:
        isCreate || isEdit
          ? availableInput('availableFrom')
          : t('OfferTimingCard.dateMediumTimeShort', { date: availableFrom }),
    },
    {
      label: t('OfferTimingCard.availableUntil'),
      value:
        isCreate || isEdit
          ? availableInput('availableUntil')
          : t('OfferTimingCard.dateMediumTimeShort', { date: availableUntil }),
    },
    {
      label: t('OfferTimingCard.accessEarliest'),
      value:
        isCreate || isEdit
          ? accessTimeInput('accessEarliestInMinFromMidnight')
          : timeByMinFromMidnight(accessEarliestInMinFromMidnight, t),
    },
    {
      label: t('OfferTimingCard.accessLates'),
      value:
        isCreate || isEdit
          ? accessTimeInput('accessLatestInMinFromMidnight')
          : timeByMinFromMidnight(accessLatestInMinFromMidnight, t),
    },
  ]

  return (
    <BasicCardLoadable title={t('OfferTimingCard.title')} action={<AccessTimeIcon />} isLoading={isGettingOfferByUuid}>
      <Grid container spacing={1}>
        <InfoRows rows={rows} />
      </Grid>
    </BasicCardLoadable>
  )
}
/* -------------------------------------------------------------------------- */
OfferTimingCard.propTypes = {
  isCreate: PropTypes.bool,
  isEdit: PropTypes.bool,
  formikProps: PropTypes.shape(),
}

OfferTimingCard.defaultProps = {
  isCreate: false,
  isEdit: false,
  formikProps: null,
}

export default OfferTimingCard
