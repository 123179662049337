import React from 'react'
import { styled } from '@mui/material/styles'
import { useSelector } from 'react-redux'
import { Badge } from '@mui/material'
import { FilterList as FilterListIcon } from '@mui/icons-material'

import { isTriveParkAdmin } from 'util/custom'
const PREFIX = 'FilterIconWithBadges'

const classes = {
  icon: `${PREFIX}-icon`,
  badge: `${PREFIX}-badge`,
}

const StyledBadge = styled(Badge)(() => ({
  [`& .${classes.icon}`]: {
    color: '#575756',
  },

  [`& .${classes.badge}`]: {
    color: 'white',
  },
}))

const FilterIconWithBadges = () => {
  const { selectedCarParks, selectedOperator } = useSelector((state) => state.globalFilters)
  const { role } = useSelector((state) => state.auth.user)

  return (
    <StyledBadge
      // show dot if operator select is active
      variant='dot'
      color='primary'
      overlap='circular'
      invisible={!isTriveParkAdmin(role) || !selectedOperator}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <Badge
        // show count for active car park selects
        badgeContent={selectedCarParks.length}
        color='primary'
        overlap='rectangular'
        classes={{ colorPrimary: classes.badge }}
      >
        <FilterListIcon className={classes.icon} />
      </Badge>
    </StyledBadge>
  )
}

export default FilterIconWithBadges
