import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Button, TextField, Grid, Switch, FormControlLabel } from '@mui/material'

import { QUERY_PARAMS } from 'routes/routes'
import { getParsedQueryParam } from 'util/core'
import { BasicCardLoadable } from 'fragments/Loadables'
import PageGrid from 'fragments/Grid/PageGrid'
import { editCarParkAddUserToResidentialParking, getCarParkByUuidAct } from 'redux/Actions'
import { useAddTicketAdminMutation } from 'services/tickets/tickets-service'

export default function ResidentialParkingAddCard() {
  const { t } = useTranslation()
  const location = useLocation()
  const dispatch = useDispatch()
  const uuid = getParsedQueryParam(location.search, QUERY_PARAMS.UUID)
  const [inputEmail, setInputEmail] = useState('')
  const [giveAdminTicket, setGiveAdminTicket] = useState(false)
  const [addTicketAdmin] = useAddTicketAdminMutation()

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setInputEmail(event.target.value)
  }

  const handleSubmit = () => {
    dispatch(editCarParkAddUserToResidentialParking(inputEmail, uuid))
    if (giveAdminTicket) {
      let currentDate = new Date()
      let futureDate = new Date(currentDate)
      futureDate.setFullYear(currentDate.getFullYear() + 1)

      const input = {
        carParkUuid: uuid as string,
        email: inputEmail,
        validFrom: currentDate.toISOString(),
        validUntil: futureDate.toISOString(),
      }

      try {
        addTicketAdmin(input)
      } catch (err) {
        // Handle error
        console.error('Error adding ticket:', err)
      }
    }
    dispatch(getCarParkByUuidAct({ uuid: uuid }))
  }

  return (
    <BasicCardLoadable>
      <PageGrid alignItems={'center'} alignContent={'center'}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            value={inputEmail}
            onChange={(event) => handleInputChange(event)}
            type={'email'}
            placeholder={t('CarParksResidentialContainer.addUserTextfield')}
          ></TextField>
        </Grid>
        <Grid item xs={3}>
          <Button
            type='button'
            onClick={handleSubmit}
            color='primary'
            variant='outlined'
            style={{ textTransform: 'none' }}
          >
            {t('CarParksResidentialContainer.addUser')}
          </Button>
        </Grid>
        <Grid item xs={3}>
          <FormControlLabel
            label={'Adminticket'}
            control={<Switch checked={giveAdminTicket} onChange={() => setGiveAdminTicket(!giveAdminTicket)} />}
          ></FormControlLabel>
        </Grid>
      </PageGrid>
    </BasicCardLoadable>
  )
}
