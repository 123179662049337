import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { BasicPageContainerLoadable } from 'fragments/Loadables'
import ErrorAlertContainer from 'fragments/Alerts/ErrorAlertContainer'
import PageGrid from 'fragments/Grid/PageGrid'
import CarParkTabBar from 'components/CarPark/CarParkTabBar'
import { getCarParkByUuidAct, resetCarParkAct } from 'redux/Actions'
import { QUERY_PARAMS } from 'routes/routes'
import { getParsedQueryParam } from 'util/core'
/* -------------------------------------------------------------------------- */

const CarParkContainer = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const location = useLocation()
  const {
    errGettingCarPark,
    carPark: { name: carParkName },
  } = useSelector((state) => state.carPark)
  const { children, title: tabTitle, errors } = props
  const title = carParkName ? `${carParkName} | ${tabTitle}` : t('CarParkContainer.carPark')

  useEffect(() => {
    const carParkUuid = getParsedQueryParam(location.search, QUERY_PARAMS.UUID)
    dispatch(getCarParkByUuidAct({ uuid: carParkUuid }))

    return () => {
      dispatch(resetCarParkAct())
    }
  }, [location.search, dispatch])

  return (
    <BasicPageContainerLoadable helmetTitle={title} pageTitle={title}>
      <PageGrid>
        <Grid item xs={12}>
          <CarParkTabBar />
        </Grid>
        <ErrorAlertContainer errors={[errGettingCarPark, ...errors]} />
        <Grid item xs={12}>
          {children}
        </Grid>
      </PageGrid>
    </BasicPageContainerLoadable>
  )
}

CarParkContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  errors: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  title: PropTypes.string.isRequired,
}

export default CarParkContainer
