import { call, put, takeLatest, all, fork, select } from 'redux-saga/effects'

import { DELETE_APP_USER, GET_APP_USER, GET_APP_USER_BY_EMAIL } from 'redux/Types'
import {
  getAppUserByUuidSuccessAct,
  getAppUserByUuidErrorAct,
  catchSagaErrorsAct,
  getAppUserByEmailSuccessAct,
  getAppUserByEmailErrorAct,
  deleteAppUserByUuidAct,
} from 'redux/Actions'
import { getAppUserReq, getAppUserByEmailReq, deleteAppUserByUuidReq } from 'api/requests'
/* -------------------------------------------------------------------------- */

function* workerGetAppUserByUuid(action) {
  try {
    const {
      appUsers: {
        pagination: { page, rows },
      },
    } = yield select()
    const { uuid } = action
    const data = yield call(getAppUserReq, uuid, page, rows)
    yield put(getAppUserByUuidSuccessAct(data))
  } catch (error) {
    yield put(catchSagaErrorsAct(error, getAppUserByUuidErrorAct))
  }
}

function* watcherGetUserAccountDetailById() {
  yield takeLatest(GET_APP_USER.REQUEST, workerGetAppUserByUuid)
}

function* workerGetAppUserByEmail(action) {
  try {
    const {
      appUsers: {
        pagination: { page, rows },
      },
    } = yield select()
    const { email } = action
    const data = yield call(getAppUserByEmailReq, email, page, rows)
    yield put(getAppUserByEmailSuccessAct(data))
  } catch (error) {
    yield put(catchSagaErrorsAct(error, getAppUserByEmailErrorAct))
  }
}

function* watcherGetUserAccountDetailByEmail() {
  yield takeLatest(GET_APP_USER_BY_EMAIL.REQUEST, workerGetAppUserByEmail)
}

/* --------------------------------DELETE-------------------------------------- */
function* workerDeleteAppUserByUuid(action) {
  try {
    const { uuid } = action
    const data = yield call(deleteAppUserByUuidReq, uuid)
    yield put(deleteAppUserByUuidAct(data))
  } catch (error) {
    yield put(catchSagaErrorsAct(error, getAppUserByUuidErrorAct))
  }
}

function* watcherDeleteUserAccountByUuid() {
  yield takeLatest(DELETE_APP_USER.REQUEST, workerDeleteAppUserByUuid)
}

// ----- EXPORT -----
export default function* rootSaga() {
  yield all([fork(watcherGetUserAccountDetailById)])
  yield all([fork(watcherGetUserAccountDetailByEmail)])
  yield all([fork(watcherDeleteUserAccountByUuid)])
}
