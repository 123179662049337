import React from 'react'
import * as PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Wifi as WifiIcon, WifiOff as WifiOffIcon } from '@mui/icons-material'

import BinaryIconChip from 'fragments/IconChips/BinaryIconChip'
import { getDeviceOfflineTimeAsString } from 'util/custom'
/* -------------------------------------------------------------------------- */
const BarrierOnlineStateChip = (props) => {
  const { isOnline, lastSeenAt } = props
  const { t } = useTranslation()

  return (
    <BinaryIconChip
      condition={lastSeenAt ? isOnline : null}
      falseIcon={WifiOffIcon}
      falseLabel={getDeviceOfflineTimeAsString(t, lastSeenAt)}
      trueIcon={WifiIcon}
      trueLabel={t('BarrierOnlineStateChip.online')}
    />
  )
}

/* -------------------------------------------------------------------------- */
BarrierOnlineStateChip.propTypes = {
  isOnline: PropTypes.bool,
  lastSeenAt: PropTypes.string,
}

BarrierOnlineStateChip.defaultProps = {
  isOnline: false,
  lastSeenAt: null,
}

export default BarrierOnlineStateChip
