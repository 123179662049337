import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

import { hasRole } from 'util/custom'
import { ROLES } from 'constants/generalConstants'
import ROUTES from 'routes/routes'
/* -------------------------------------------------------------------------- */

const NoAuthorizationComp = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()

  useEffect(() => {
    if (!isAuthenticated) {
      enqueueSnackbar(t('AuthorizationHOC.pleaseAuthenticate'), { variant: 'warning' })
      navigate(
        {
          pathname: ROUTES.AUTH.SIGN_IN,
        },
        { state: { from: location } },
      )
    } else {
      enqueueSnackbar(t('AuthorizationHOC.cannotAccessThisRoute'), { variant: 'warning' })
      navigate(
        {
          pathname: ROUTES.APP.HOME,
        },
        { state: { from: location } },
      )
    }
  }, [enqueueSnackbar, navigate, isAuthenticated, t, location])

  return null
}

/* -------------------------------------------------------------------------- */

const uncurriedAuthorizationHOC = (allowedRoles, NotAuthorizedComponent, AuthorizedComponent) => {
  const AuthorizingComponent = (props) => {
    const userRole = useSelector((state) => state.auth.user?.role)

    if (hasRole(allowedRoles, userRole)) {
      return <AuthorizedComponent {...props} />
    }
    return <NotAuthorizedComponent />
  }

  return AuthorizingComponent
}

const authorizationHOC = _.curry(uncurriedAuthorizationHOC)

/* -------------------------------------------------------------------------- */

export default authorizationHOC

// Taking advantage of the curried function we can ignore/delay giving the
// first argument to the function and define that later
// https://lodash.com/docs/4.17.15#curry
export const redirectAuthorizationHOC = authorizationHOC(_, NoAuthorizationComp)
export const nullAuthorizationHOC = authorizationHOC(_, () => null)

/* -------------------------------------------------------------------------- */

export const IsTpAdmin = redirectAuthorizationHOC([ROLES.TP_ADMIN])

export const IsOperatorAdmin = redirectAuthorizationHOC([ROLES.TP_ADMIN, ROLES.OP_ADMIN])

export const IsOperatorManager = redirectAuthorizationHOC([ROLES.TP_ADMIN, ROLES.OP_ADMIN, ROLES.OP_MANAGER])

export const IsHavingRole = (NoRoleFragment, RoleContainer) =>
  authorizationHOC([ROLES.TP_ADMIN, ROLES.OP_ADMIN, ROLES.OP_MANAGER], NoRoleFragment, RoleContainer)
