import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material'
import { Person as PersonIcon, ExitToApp as ExitToAppIcon } from '@mui/icons-material'

import CircularProgressOverlay from 'fragments/CircularProgressOverlay'
import { logOutUserAct } from 'redux/Actions'
import { isPending } from 'redux/Status'
import { APP } from 'routes/routes'
const PREFIX = 'UserMenu'

const classes = {
  listItemIcon: `${PREFIX}-listItemIcon`,
  menuEntry: `${PREFIX}-menuEntry`,
  iconPerson: `${PREFIX}-iconPerson`,
  iconExit: `${PREFIX}-iconExit`,
  paper: `${PREFIX}-paper`,
}

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.listItemIcon}`]: {
    minWidth: 22.5 + parseInt(theme.spacing(2)),
  },

  [`& .${classes.menuEntry}`]: {
    fontSize: theme.typography.pxToRem(18),
  },

  [`& .${classes.iconPerson}`]: {
    color: '#575756',
  },

  [`& .${classes.iconExit}`]: {
    color: '#FF7285',
  },

  [`& .${classes.paper}`]: {
    borderRadius: 15,
  },
}))

const UserMenu = () => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const { statusLoggingOut } = useSelector((state) => state.auth)
  const location = useLocation()

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    dispatch(logOutUserAct())
    handleClose()
  }

  return (
    <Root>
      {isPending(statusLoggingOut) && <CircularProgressOverlay />}
      <Tooltip title={t('UserMenu.userMenu')} placement='bottom'>
        <IconButton
          aria-label={t('UserMenu.userMenu')}
          aria-controls='user-menu-appbar'
          aria-haspopup='true'
          onClick={handleMenu}
          color='inherit'
          size='large'
        >
          <PersonIcon className={classes.iconPerson} />
        </IconButton>
      </Tooltip>
      <Menu
        id='user-menu-appbar'
        open={open}
        onClose={handleClose}
        classes={{ paper: classes.paper }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem component={Link} to={APP.USER_PROFILE} state={{ from: location }} onClick={handleClose} divider>
          <ListItemIcon className={classes.listItemIcon}>
            <PersonIcon className={classes.iconPerson} />
          </ListItemIcon>
          <ListItemText classes={{ primary: classes.menuEntry }}>{t('UserMenu.profile')}</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon className={classes.listItemIcon}>
            <ExitToAppIcon className={classes.iconExit} />
          </ListItemIcon>
          <ListItemText classes={{ primary: classes.menuEntry }}>{t('UserMenu.logOut')}</ListItemText>
        </MenuItem>
      </Menu>
    </Root>
  )
}

export default UserMenu
