import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { BasicCardLoadable } from 'fragments/Loadables'
import MuiDataTableThemed from 'fragments/tables/MuiDataTableThemed'
import options from 'components/Admins/adminsTableOptions'
import columns from 'components/Admins/AdminsTableColumns'
import { isTriveParkAdmin } from 'util/custom'
/* -------------------------------------------------------------------------- */
const AdminsTableCard = () => {
  const {
    user: { role },
  } = useSelector((state) => state.auth)
  const { admins, isGettingAdmins } = useSelector((state) => state.admins)
  const { operators } = useSelector((state) => state.operators)
  const { selectedOperator } = useSelector((state) => state.globalFilters)
  const { t } = useTranslation()
  let navigate = useNavigate()

  // FIXME: there is no possibility backend side to get only TpAdmins
  // Parkmotive -> Team would show all operators
  const data = selectedOperator === null ? admins.filter((a) => isTriveParkAdmin(a.role)) : admins

  return (
    <BasicCardLoadable isLoading={isGettingAdmins}>
      <MuiDataTableThemed
        data={data}
        columns={columns({
          admins: data,
          operators,
          role,
          t,
        })}
        options={options({
          admins: data,
          history: navigate,
        })}
      />
    </BasicCardLoadable>
  )
}

export default AdminsTableCard
