import React, { useState } from 'react'
import * as PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { FormHelperText } from '@mui/material'

import ConfirmDialog from 'fragments/Dialogs/ConfirmDialog'
import BasicSwitch from 'fragments/Forms/BasicSwitch'
import { editBarrierAct } from 'redux/Actions'
/* -------------------------------------------------------------------------- */
const ToggleBarrierSsh = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { uuid, isSshTunnelEnabled } = useSelector((state) => state.barrier.barrier)
  const [open, setOpen] = useState(false)

  const { isEdit } = props

  const handleConfirm = () => {
    dispatch(editBarrierAct(uuid, { isSshTunnelEnabled: !isSshTunnelEnabled }))
    setOpen(false)
  }

  return (
    <>
      <ConfirmDialog
        open={open}
        dialogTitle={t('ToggleBarrierSsh.title')}
        dialogContent={isSshTunnelEnabled ? t('ToggleBarrierSsh.contentTrigger') : t('ToggleBarrierSsh.contentCancel')}
        onConfirm={handleConfirm}
        onClose={() => setOpen(false)}
      />
      <BasicSwitch disabled={isEdit} checked={isSshTunnelEnabled} onChange={() => setOpen(true)} />
      <FormHelperText>{isEdit ? t('ToggleBarrierSsh.helperText.editMode') : null}</FormHelperText>
    </>
  )
}

ToggleBarrierSsh.propTypes = {
  isEdit: PropTypes.bool,
}

ToggleBarrierSsh.defaultProps = {
  isEdit: false,
}

export default ToggleBarrierSsh
