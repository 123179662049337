import React from 'react'
import { useSelector } from 'react-redux'
import Grid from '@mui/material/Grid'
import { useTranslation } from 'react-i18next'
import { Euro as EuroIcon } from '@mui/icons-material'

import { BasicCardLoadable } from 'fragments/Loadables'
import InfoRows from 'fragments/Cards/InfoRows'
/* -------------------------------------------------------------------------- */
const CreditCard = () => {
  const {
    isGettingAppUser,
    appUser: {
      creditCardNumber,
      creditCardExpiryDate,
      creditCardCvc,
      creditCardOwnerFirstName,
      creditCardOwnerLastName,
    },
  } = useSelector((state) => state.appUser)

  const { t } = useTranslation()

  const rows = [
    {
      label: t('CreditCard.number'),
      value: creditCardNumber,
    },
    {
      label: t('CreditCard.cvc'),
      value: creditCardCvc,
    },
    {
      label: t('CreditCard.expiryDate'),
      value: t('CreditCard.dateMediumTimeShort', { date: creditCardExpiryDate }),
    },
    {
      label: t('CreditCard.owner'),
      value:
        creditCardOwnerFirstName && creditCardOwnerLastName
          ? creditCardOwnerFirstName + ' ' + creditCardOwnerLastName
          : null,
    },
  ]

  return (
    <BasicCardLoadable isLoading={isGettingAppUser} title={t('CreditCard.title')} action={<EuroIcon />}>
      <Grid container spacing={1}>
        <InfoRows rows={rows} />
      </Grid>
    </BasicCardLoadable>
  )
}

export default CreditCard
