import React from 'react'
import { connect } from 'react-redux'
import * as PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'

import { BasicPageContainerLoadable } from 'fragments/Loadables'
import PageGrid from 'fragments/Grid/PageGrid'
import LinksToOperatorCard from 'components/Settings/LinksToOperatorCard'
import LinksToParkMotiveCard from 'components/Settings/LinksToParkMotiveCard'
import LinksToConnectedAccountsCard from 'components/Settings/LinksToConnectedAccountsCard'
import SoftwareVersionCard from 'components/Settings/SoftwareVersionCard'
import { ROLES } from 'constants/generalConstants'
import { hasRole } from 'util/custom'
/* -------------------------------------------------------------------------- */
const SettingsContainer = (props) => {
  const { role, selectedOperator } = props
  const { t } = useTranslation()

  return (
    <BasicPageContainerLoadable
      helmetTitle={t('SettingsContainer.settings')}
      pageTitle={t('SettingsContainer.settings')}
    >
      <PageGrid>
        {!!selectedOperator && hasRole([ROLES.TP_ADMIN, ROLES.OP_ADMIN], role) && (
          <Grid item xs={12} sm={6}>
            <LinksToOperatorCard />
          </Grid>
        )}
        {!selectedOperator && hasRole([ROLES.TP_ADMIN]) && (
          <Grid item xs={12} sm={6}>
            <LinksToParkMotiveCard />
          </Grid>
        )}
        {!selectedOperator && hasRole([ROLES.TP_ADMIN]) && (
          <Grid item xs={12} sm={6}>
            <LinksToConnectedAccountsCard />
          </Grid>
        )}
        {!selectedOperator && hasRole([ROLES.TP_ADMIN]) && (
          <Grid item xs={12} sm={6}>
            <SoftwareVersionCard />
          </Grid>
        )}
      </PageGrid>
    </BasicPageContainerLoadable>
  )
}

/* -------------------------------------------------------------------------- */
SettingsContainer.propTypes = {
  role: PropTypes.string,
  selectedOperator: PropTypes.string,
}

SettingsContainer.defaultProps = {
  role: '',
  selectedOperator: null,
}

const mapStateToProps = (state) => {
  const {
    auth: {
      user: { role },
    },
    globalFilters: { selectedOperator },
  } = state
  return { selectedOperator, role }
}

export default connect(mapStateToProps)(SettingsContainer)
