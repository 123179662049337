import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Grid, Button } from '@mui/material'
import { Link, useLocation } from 'react-router-dom'

import { BasicPageContainerLoadable } from 'fragments/Loadables'
import ErrorAlertContainer from 'fragments/Alerts/ErrorAlertContainer'
import PageGrid from 'fragments/Grid/PageGrid'
import { getAdminsAct } from 'redux/Actions'
import AdminsTableCard from './AdminsTableCard'
import { APP } from 'routes/routes'
import LastUpdateInfo from 'fragments/Misc/LastUpdateInfo'
/* -------------------------------------------------------------------------- */

const AdminsContainer = () => {
  const { errGettingAdmins, lastSuccessDate } = useSelector((state) => state.admins)
  const { selectedOperator } = useSelector((state) => state.globalFilters)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const location = useLocation()

  useEffect(() => {
    dispatch(getAdminsAct())
  }, [dispatch, selectedOperator])

  const createAdmin = () => (
    <Button
      type='button'
      component={Link}
      to={APP.ADMIN_CREATE}
      state={{ from: location }}
      color='primary'
      variant='outlined'
      style={{ textTransform: 'none' }}
    >
      {t('AdminsContainer.createAdmin')}
    </Button>
  )

  return (
    <BasicPageContainerLoadable
      helmetTitle={t('AdminsContainer.admins')}
      pageTitle={t('AdminsContainer.admins')}
      titleBarEndAdornment={createAdmin}
    >
      <ErrorAlertContainer errors={[errGettingAdmins]} />
      <PageGrid>
        <Grid item xs={12}>
          <AdminsTableCard />
        </Grid>
        <Grid item xs={12}>
          <LastUpdateInfo lastSuccessDate={lastSuccessDate} />
        </Grid>
      </PageGrid>
    </BasicPageContainerLoadable>
  )
}

export default AdminsContainer
