import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { Grid, Typography } from '@mui/material'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { Formik, Form } from 'formik'

import PageGrid from 'fragments/Grid/PageGrid'
import AdminGrid from 'components/Admin/AdminGrid'
import { SaveButton, CancelButton } from 'fragments/Buttons'
import { createAdminAct, resetCreateAdminAct } from 'redux/Actions'
import { APP, QUERY_PARAMS } from 'routes/routes'
import { getQueryString, getRequiredInfo } from 'util/core'
import { initialValues, validationSchema } from 'components/Admin/Create/adminCreate'
import { isResolved, isPending } from 'redux/Status'
/* -------------------------------------------------------------------------- */

const AdminCreateForm = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const { enqueueSnackbar } = useSnackbar()
  const {
    statusCreatingAdmin,
    admin: { uuid },
  } = useSelector((state) => state.admin)
  const { selectedOperator } = useSelector((state) => state.globalFilters)

  const onKeyDown = (keyEvent) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault()
    }
  }

  useEffect(() => {
    if (isResolved(statusCreatingAdmin)) {
      enqueueSnackbar(t('AdminCreateForm.success'), { variant: 'success' })
      navigate(
        {
          pathname: APP.ADMIN,
          search: getQueryString({ [QUERY_PARAMS.UUID]: uuid }),
        },
        { state: { from: location } },
      )
    }
    return () => {
      if (isResolved(statusCreatingAdmin)) {
        dispatch(resetCreateAdminAct())
      }
    }
  }, [dispatch, enqueueSnackbar, navigate, statusCreatingAdmin, t, uuid, location])

  const handleSubmit = (values) => dispatch(createAdminAct(values))

  return (
    <Formik
      initialValues={initialValues(selectedOperator)}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {(formikProps) => {
        const { isValidating, isSubmitting } = formikProps
        return (
          <Form onKeyDown={onKeyDown}>
            <PageGrid justify='space-between'>
              <AdminGrid isCreate formikProps={formikProps} />
              <Grid container justifyContent='space-between' item>
                <Grid item>
                  <Typography variant='body2'>{getRequiredInfo(t)}</Typography>
                </Grid>
                <Grid item xs>
                  <Grid container spacing={2} justifyContent='flex-end'>
                    <Grid item>
                      <CancelButton
                        component={Link}
                        to={APP.ADMINS}
                        state={{ from: location }}
                        disabled={isPending(statusCreatingAdmin)}
                      />
                    </Grid>
                    <Grid item>
                      <SaveButton disabled={isValidating || isSubmitting || isPending(statusCreatingAdmin)} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </PageGrid>
          </Form>
        )
      }}
    </Formik>
  )
}

export default AdminCreateForm
