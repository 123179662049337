import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import * as PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { Info as InfoIcon } from '@mui/icons-material'
import { useLocation } from 'react-router-dom'

import { BasicCardLoadable } from 'fragments/Loadables'
import InfoRows from 'fragments/Cards/InfoRows'
import IconChip from 'fragments/IconChips/IconChip'
import { getChargepointForCarParkUuidReq } from 'api/requests'
import { QUERY_PARAMS } from 'routes/routes'
import { getParsedQueryParam } from 'util/core'
/* -------------------------------------------------------------------------- */

const ChargingCard = (props) => {
  const { isGettingCarPark } = useSelector((state) => state.carPark)
  const { t } = useTranslation()
  const location = useLocation()
  const [chargePoint, setChargePoint] = useState({})

  const uuid = getParsedQueryParam(location?.search, QUERY_PARAMS.UUID)

  //Useeffect to get charging info for car park with its uuid
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    async function GetChargepoint() {
      let res = await getChargepointForCarParkUuidReq(uuid)
      if (res.data && res.data[0]) {
        setChargePoint(res.data[0])
      } else {
        console.error('No chargepoint available')
      }
    }
    GetChargepoint()
  }, [uuid])

  const getStatusChipColor = (status) => {
    switch (status) {
      case 'AVAILABLE':
        return 'green'
      case 'RESERVED':
        return 'orange'
      default:
        return 'red'
    }
  }

  const rows = [
    {
      label: t('ChargepointCard.evseId'),
      value: chargePoint.evseId ?? '',
    },
    {
      label: t('ChargepointCard.plugs'),
      value: chargePoint.plugs ?? '',
    },
    {
      label: t('ChargepointCard.power'),
      value: chargePoint.power ?? '',
    },
    {
      label: t('ChargepointCard.powerType'),
      value: chargePoint.powerType ?? '',
    },
    {
      label: t('ChargepointCard.status'),
      value: (
        <IconChip
          icon={null}
          label={chargePoint.status ?? ''}
          chipStyle={{
            color: 'white',
            background: getStatusChipColor(chargePoint.status),
          }}
        />
      ),
    },
  ]

  return (
    <BasicCardLoadable title={t('ChargepointCard.title')} action={<InfoIcon />} isLoading={isGettingCarPark}>
      <Grid container spacing={1}>
        <InfoRows rows={rows} />
      </Grid>
    </BasicCardLoadable>
  )
}

ChargingCard.propTypes = {
  isEdit: PropTypes.bool,
  isCreate: PropTypes.bool,
}

ChargingCard.defaultProps = {
  isEdit: false,
  isCreate: false,
}

export default ChargingCard
