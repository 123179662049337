import React from 'react'
import { Grid } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import CarParkContainer from 'components/CarPark/CarParkContainer'
import { QUERY_PARAMS } from 'routes/routes'
import { getParsedQueryParam } from 'util/core'
import PageGrid from 'fragments/Grid/PageGrid'
import { getCarParkByUuidAct } from 'redux/Actions'
import { INFREQUENT_UPDATE_TIME_IN_MS } from 'constants/generalConstants'
import { useInterval } from 'hooks'
import ChargingCard from './ChargingCard'
/* -------------------------------------------------------------------------- */

const ChargingContainer = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const dispatch = useDispatch()
  const { isReGettingCarPark, lastSuccessDate, errGettingCarPark } = useSelector((state) => state.carPark)
  const uuid = getParsedQueryParam(location.search, QUERY_PARAMS.UUID)

  useInterval(() => {
    dispatch(getCarParkByUuidAct({ uuid, isReload: true }))
  }, INFREQUENT_UPDATE_TIME_IN_MS)

  return (
    <CarParkContainer
      title={t('ChargepointCard.title')}
      errors={[errGettingCarPark]}
      lastSuccessDate={lastSuccessDate}
      isUpdating={isReGettingCarPark}
      reloadIntervalInMs={INFREQUENT_UPDATE_TIME_IN_MS}
    >
      <PageGrid justify='flex-end'>
        <Grid item>
          <ChargingCard />
        </Grid>
      </PageGrid>
    </CarParkContainer>
  )
}

export default ChargingContainer
