/* eslint-disable no-unused-vars */
import React, { Fragment } from 'react'
import TextField from '@mui/material/TextField'
import { MobileDatePicker } from '@mui/x-date-pickers'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import {
  ChevronRight as ChevronRightIcon,
  ChevronLeft as ChevronLeftIcon,
  Event as EventIcon,
} from '@mui/icons-material'
import { IconButton, InputAdornment, Tooltip, Grid } from '@mui/material'
import {
  compareAsc,
  startOfDay,
  startOfWeek,
  startOfMonth,
  startOfYear,
  addDays,
  addWeeks,
  addYears,
  addMonths,
} from 'date-fns'
import * as PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { localeMap } from 'util/i18n'
import { getTimeIntervalValue, getIntervalLabel, datePattern } from 'util/core'
/* -------------------------------------------------------------------------- */
const CustomDatePicker = (props) => {
  const { date, onChange, disabled, timeInterval, showArrows } = props
  const [open, setOpen] = React.useState(false)
  const { t, i18n } = useTranslation()
  const label = getIntervalLabel(t, timeInterval)
  let nextDate, prevDate, disableNextBtn, fieldFormat, views, openTo

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  switch (timeInterval) {
    case getTimeIntervalValue('WEEK'): {
      nextDate = addWeeks(date, 1)
      prevDate = addWeeks(date, -1)
      disableNextBtn = compareAsc(date, startOfWeek(new Date(), { weekStartsOn: 1 })) > -1
      fieldFormat = datePattern.weekLong
      views = ['day']
      openTo = 'day'
      break
    }
    case getTimeIntervalValue('MONTH'): {
      nextDate = addMonths(date, 1)
      prevDate = addMonths(date, -1)
      disableNextBtn = compareAsc(startOfMonth(date), startOfMonth(new Date())) > -1
      fieldFormat = datePattern.monthShort + ' ' + datePattern.yearFull
      views = ['year', 'month']
      openTo = 'month'
      break
    }
    case getTimeIntervalValue('YEAR'): {
      nextDate = addYears(date, 1)
      prevDate = addYears(date, -1)
      disableNextBtn = compareAsc(startOfYear(date), startOfYear(new Date())) > -1
      fieldFormat = datePattern.yearFull
      views = ['year']
      openTo = 'year'
      break
    }
    default: {
      // 'DAY'
      nextDate = addDays(date, 1)
      prevDate = addDays(date, -1)
      disableNextBtn = compareAsc(startOfDay(date), startOfDay(new Date())) > -1
      fieldFormat = datePattern.dateShort
      views = ['day']
      openTo = 'day'
      break
    }
  }

  const onChangeDate = (date) => onChange(date)

  const onBackClick = () => onChange(prevDate)

  const onNextClick = () => onChange(nextDate)

  const renderDatPicker = () => {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={localeMap[i18n.language]}>
        <MobileDatePicker
          open={open}
          openTo={openTo}
          views={views}
          value={date}
          label={label}
          disabled={disabled}
          onChange={onChangeDate}
          inputFormat={fieldFormat}
          slotProps={{ textField: { variant: 'outlined' } }}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton size='large' edge='end' onClick={handleOpen}>
                  <EventIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          onClose={handleClose}
          onOpen={handleOpen}
          inputVariant='outlined'
          okText={t('CustomDatePicker.ok')}
          cancelText={t('CustomDatePicker.cancel')}
          maxDate={new Date()}
          animateYearScrolling={false}
          leftArrowIcon={<ChevronLeftIcon />}
          rightArrowIcon={<ChevronRightIcon />}
        />
      </LocalizationProvider>
    )
  }

  return (
    <Grid container justifyContent='space-between' alignItems='center'>
      {showArrows && (
        <Grid item xs={2}>
          <Tooltip title={t('CustomDatePicker.back')}>
            <IconButton onClick={onBackClick} size='large'>
              <ChevronLeftIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      )}
      <Grid item xs={showArrows ? 8 : 12}>
        {renderDatPicker()}
      </Grid>
      {showArrows && (
        <Grid item xs={2}>
          <Tooltip title={t('CustomDatePicker.next')}>
            <Fragment>
              <IconButton onClick={onNextClick} disabled={disableNextBtn} size='large'>
                <ChevronRightIcon />
              </IconButton>
            </Fragment>
          </Tooltip>
        </Grid>
      )}
    </Grid>
  )
}

/* -------------------------------------------------------------------------- */
CustomDatePicker.propTypes = {
  date: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
  timeInterval: PropTypes.string,
  disabled: PropTypes.bool,
  showArrows: PropTypes.bool,
}

CustomDatePicker.defaultProps = {
  timeInterval: 'day',
  disabled: false,
  showArrows: false,
}

export default CustomDatePicker
