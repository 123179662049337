import React from 'react'
import * as PropTypes from 'prop-types'
import { VerifiedUser as VerifiedUserIcon, ErrorOutline as ErrorOutlineIcon } from '@mui/icons-material'

import BinaryIconChip from 'fragments/IconChips/BinaryIconChip'
import { useTranslation } from 'react-i18next'
/* -------------------------------------------------------------------------- */
const EmailVerifiedChip = (props) => {
  const { isEmailVerified } = props
  const { t } = useTranslation()

  return (
    <BinaryIconChip
      condition={isEmailVerified}
      falseIcon={ErrorOutlineIcon}
      falseLabel={t('EmailVerifiedChip.emailNotVerified')}
      trueIcon={VerifiedUserIcon}
      trueLabel={t('EmailVerifiedChip.emailVerified')}
    />
  )
}

/* -------------------------------------------------------------------------- */
EmailVerifiedChip.propTypes = {
  isEmailVerified: PropTypes.bool,
}

EmailVerifiedChip.defaultProps = {
  isEmailVerified: null,
}

export default EmailVerifiedChip
