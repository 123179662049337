import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import * as PropTypes from 'prop-types'
import { Field } from 'formik'
import { FlashOn as FlashOnIcon } from '@mui/icons-material'

import { BasicCardLoadable } from 'fragments/Loadables'
import InfoRows from 'fragments/Cards/InfoRows'
import VisibilityChip from 'fragments/IconChips/VisibilityChip'
import DeletedChip from 'fragments/IconChips/DeletedChip'
import { Switch } from 'formik-mui'
import NumberField from 'fragments/Forms/Formik/NumberField'
import InputAdornment from '@mui/material/InputAdornment'
/* -------------------------------------------------------------------------- */

const OfferStatusCard = (props) => {
  const { t } = useTranslation()
  const {
    isGettingOfferByUuid,
    offer: { createdAt, isDeleted, isVisible, contingent },
  } = useSelector((state) => state?.offer)
  const { isCreate, isEdit } = props

  const getVisibilityValue = () => (isDeleted ? <DeletedChip /> : <VisibilityChip isVisible={isVisible} />)

  const getContingentValue = () =>
    Number.isInteger(contingent)
      ? t('OfferStatusCard.contingent.value', { value: contingent })
      : t('OfferStatusCard.contingent.inf')

  const visibilityInput = () => <Field component={Switch} type='checkbox' name='isVisible' color='primary' />

  const contingentInput = () => (
    <NumberField
      name='contingent'
      min={0}
      step={1}
      endAdornment={<InputAdornment position='end'>{t('OfferStatusCard.contingent.tickets')}</InputAdornment>}
      variant='standard'
    />
  )

  const rows = [
    {
      label: t('OfferStatusCard.visibility') + (isCreate || isEdit ? ' *' : ''),
      value: isCreate || isEdit ? visibilityInput() : getVisibilityValue(),
    },
    {
      label: t('OfferStatusCard.contingent.label'),
      value: isCreate || isEdit ? contingentInput() : getContingentValue(),
    },
    {
      label: t('OfferStatusCard.createdAt'),
      value: t('OfferStatusCard.dateMediumTimeShort', { date: createdAt }),
      hideOn: isCreate,
    },
  ]

  return (
    <BasicCardLoadable title={t('OfferStatusCard.title')} action={<FlashOnIcon />} isLoading={isGettingOfferByUuid}>
      <Grid container spacing={1}>
        <InfoRows rows={rows} />
      </Grid>
    </BasicCardLoadable>
  )
}
/* -------------------------------------------------------------------------- */
OfferStatusCard.propTypes = {
  isCreate: PropTypes.bool,
  isEdit: PropTypes.bool,
}

OfferStatusCard.defaultProps = {
  isCreate: false,
  isEdit: false,
}

export default OfferStatusCard
