import React from 'react'
import { Grid } from '@mui/material'
import { Build as BuildIcon } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

import { BasicCardLoadable } from 'fragments/Loadables'
import InfoRows from 'fragments/Cards/InfoRows'
import Emoji from 'fragments/Misc/Emoji'
/* -------------------------------------------------------------------------- */

const SoftwareVersionCard = () => {
  const { t } = useTranslation()

  const rows = [
    {
      label: t('SoftwareVersionCard.version'),
      value: (
        <>
          <Emoji label='package' symbol='📦' />
          {' v' + process.env.REACT_APP_PACKAGE_VERSION}
        </>
      ),
    },
    {
      label: t('SoftwareVersionCard.buildVariant'),
      value: (
        <>
          <Emoji label='hammer' symbol='🔨' />
          {' ' + process.env.REACT_APP_VERSION ?? ' run npm start'}
        </>
      ),
    },
  ]

  return (
    <BasicCardLoadable title={t('SoftwareVersionCard.title')} action={<BuildIcon />}>
      <Grid container spacing={1}>
        <InfoRows rows={rows} />
      </Grid>
    </BasicCardLoadable>
  )
}

export default SoftwareVersionCard
