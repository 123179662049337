import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Grid, Typography } from '@mui/material'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { Formik, Form } from 'formik'

import PageGrid from 'fragments/Grid/PageGrid'
import InfoGrid from 'components/CarPark/Info/InfoGrid'
import { APP, QUERY_PARAMS } from 'routes/routes'
import { createCarParkAct, resetCreateCarParkAct } from 'redux/Actions'
import { getQueryString, getRequiredInfo } from 'util/core'
import { initialValues, validationSchema } from 'components/CarPark/Info/Create/carParkCreate'
import { SaveButton, CancelButton } from 'fragments/Buttons'
/* -------------------------------------------------------------------------- */

const InfoCreateForm = () => {
  const {
    createCarParkSuccess,
    carPark: { uuid },
  } = useSelector((state) => state.carPark)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()

  const onKeyDown = (keyEvent) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault()
    }
  }

  const to = React.useMemo(
    () => ({
      pathname: APP.CAR_PARK_INFO,
      search: getQueryString({
        [QUERY_PARAMS.UUID]: uuid,
      }),
    }),
    [uuid],
  )

  useEffect(() => {
    if (createCarParkSuccess) {
      navigate(
        {
          ...to,
        },
        { state: { from: location } },
      )
    }
    return () => {
      if (createCarParkSuccess) {
        dispatch(resetCreateCarParkAct())
      }
    }
  }, [dispatch, createCarParkSuccess, navigate, to, uuid, location])

  const handleSubmit = (values) => dispatch(createCarParkAct(values))

  return (
    <Formik
      initialValues={initialValues()}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {(formikProps) => {
        const { isValidating, isSubmitting } = formikProps
        return (
          <Form onKeyDown={onKeyDown}>
            <PageGrid justify='space-between'>
              <InfoGrid isCreate />
              <Grid container justifyContent='space-between' item>
                <Grid item>
                  <Typography variant='body2'>{getRequiredInfo(t)}</Typography>
                </Grid>
                <Grid item xs>
                  <Grid container spacing={2} justifyContent='flex-end'>
                    <Grid item>
                      <CancelButton component={Link} to={APP.CAR_PARKS} state={{ from: location }} />
                    </Grid>
                    <Grid item>
                      <SaveButton disabled={isValidating || isSubmitting} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </PageGrid>
          </Form>
        )
      }}
    </Formik>
  )
}

export default InfoCreateForm
