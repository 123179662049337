import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { Grid } from '@mui/material'

import { BasicPageContainerLoadable } from 'fragments/Loadables'
import PageGrid from 'fragments/Grid/PageGrid'
import ErrorAlertContainer from 'fragments/Alerts/ErrorAlertContainer'
import LastUpdateInfo from 'fragments/Misc/LastUpdateInfo'
import ManualOpeningCard from 'components/Ticket/ManualOpeningCard'
import TicketStatusCard from 'components/Ticket/TicketStatusCard'
import TicketInfoCard from 'components/Ticket/TicketInfoCard'
import AppUserShortInfoCard from 'components/AppUser/AppUserShortInfoCard'
import TicketBarrierOpeningsCard from 'components/Ticket/TicketBarrierOpeningsCard'
import TicketPaymentsCard from 'components/Ticket/TicketPaymentsCard'
import { FREQUENT_UPDATE_TIME_IN_MS } from 'constants/generalConstants'
import { getParsedQueryParam } from 'util/core'
import {
  useAddManualBarrierOpeningMutation,
  useGetTicketQuery,
  TicketUsage,
  TicketUsability,
} from 'services/tickets/tickets-service'
import { RtkQueryError, transformRtkQueryError } from 'services/services'
/* -------------------------------------------------------------------------- */

function showManualOpeningCard(usability?: TicketUsability, usage?: TicketUsage) {
  return usability === 'Usable' || usage === 'InUse'
}

export default function TicketContainer() {
  const { t } = useTranslation()
  const location = useLocation()

  const publicId = React.useMemo(() => getParsedQueryParam(location?.search, 'id') as string, [location?.search])
  const title = t('TicketContainer.title', { id: publicId })
  const {
    data: ticket,
    isLoading: isLoadingTicket,
    fulfilledTimeStamp,
    isFetching,
    error: errorGettingTicket,
    refetch,
  } = useGetTicketQuery(publicId, {
    pollingInterval: FREQUENT_UPDATE_TIME_IN_MS,
  })
  const [addManualOpening, { isLoading: isAddingManualBarrierOpening, error: errAddingManualBarrierOpening }] =
    useAddManualBarrierOpeningMutation()

  return (
    <BasicPageContainerLoadable helmetTitle={title} pageTitle={title}>
      <ErrorAlertContainer
        errors={[
          transformRtkQueryError(errorGettingTicket as RtkQueryError),
          transformRtkQueryError(errAddingManualBarrierOpening as RtkQueryError),
        ]}
      />
      <PageGrid>
        <Grid item xs={12} sm={6} md={4}>
          <TicketStatusCard isLoading={isLoadingTicket} ticket={ticket} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TicketInfoCard isLoading={isLoadingTicket} ticket={ticket} />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <AppUserShortInfoCard isLoading={isLoadingTicket} user={ticket?.user} />
        </Grid>
        <Grid item xs={12} md={6}>
          <TicketBarrierOpeningsCard isLoading={isLoadingTicket} barrierOpenings={ticket?.barrierOpenings} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2} style={{ height: '100%' }}>
            {showManualOpeningCard(ticket?.usability, ticket?.usage) ? (
              <Grid item xs={12}>
                <ManualOpeningCard
                  isLoading={isLoadingTicket || isAddingManualBarrierOpening}
                  ticket={ticket}
                  addManualOpening={addManualOpening}
                  refetch={refetch}
                />
              </Grid>
            ) : null}
            <Grid item xs={12}>
              <TicketPaymentsCard isLoading={isLoadingTicket} ticket={ticket} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <LastUpdateInfo
            lastSuccessDate={fulfilledTimeStamp}
            isUpdating={isFetching}
            reloadIntervalInMs={FREQUENT_UPDATE_TIME_IN_MS}
            refetch={refetch}
          />
        </Grid>
      </PageGrid>
    </BasicPageContainerLoadable>
  )
}
