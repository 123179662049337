import React, { useEffect } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'

import AppLayout from 'layouts/AppLayout/AppLayout'
import appRoutesConfig from 'routes/appRoutesConfig'
import { APP } from 'routes/routes'
/* -------------------------------------------------------------------------- */

const AppRoutes = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const isValidAppPath = Object.values(APP).includes(location.pathname)

  useEffect(() => {
    if (!isValidAppPath) {
      navigate(
        {
          pathname: APP.HOME,
        },
        { state: { from: location } },
      )
    }
  }, [navigate, isValidAppPath, location])

  return (
    <AppLayout>
      <Routes>
        {appRoutesConfig.map(({ path, element: Element }) => (
          <Route key={path} path={path} element={<Element />} />
        ))}
      </Routes>
    </AppLayout>
  )
}

export default AppRoutes
