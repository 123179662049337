import { APP } from 'routes/routes'
/* -------------------------------------------------------------------------- */

export default [
  {
    label: 'info',
    path: APP.CAR_PARK_INFO,
  },
  {
    label: 'barriers',
    path: APP.CAR_PARK_BARRIERS,
  },
  {
    label: 'offers',
    path: APP.CAR_PARK_OFFERS,
  },
  {
    label: 'tickets',
    path: APP.CAR_PARK_TICKETS,
  },
  {
    label: 'openings',
    path: APP.CAR_PARK_OPENINGS,
  },
  {
    label: 'residential',
    path: APP.CAR_PARK_RESIDENTIAL,
  },
  {
    label: 'charging',
    path: APP.CAR_PARK_CHARGING,
  },
]
