import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { Grid, Button } from '@mui/material'

import PageGrid from 'fragments/Grid/PageGrid'
import { BasicPageContainerLoadable } from 'fragments/Loadables'
import ConfirmDialog from 'fragments/Dialogs/ConfirmDialog'
import { getAppUserByUuidAct, resetAppUserAct, deleteAppUserByUuidAct, exportAppUserByUuidAct } from 'redux/Actions'
import { getParsedQueryParam } from 'util/core'
import ErrorAlertContainer from 'fragments/Alerts/ErrorAlertContainer'
import ErrorAlert from 'fragments/Alerts/ErrorAlert'
import AppUserTabBar from 'components/AppUser/AppUserTabBar'
import AppUserLongInfoCard from 'components/AppUser/AppUserLongInfoCard'
import AddressCard from 'components/AppUser/AddressCard'
import DeviceCard from 'components/AppUser/DeviceCard'
import LegalCard from 'components/AppUser/LegalCard'
import CreditCard from 'components/AppUser/Payments/CreditCard'
import { QUERY_PARAMS } from 'routes/routes'
/* -------------------------------------------------------------------------- */
const AppUserContainer = () => {
  const {
    isGettingAppUser,
    errGettingAppUser,
    isDeletingAppUser,
    isExportingAppUser,
    appUser: { isDeleted },
  } = useSelector((state) => state.appUser)
  const dispatch = useDispatch()
  const location = useLocation()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()

  const uuid = getParsedQueryParam(location.search, QUERY_PARAMS.UUID)

  const [showExportDialog, setExportDialog] = useState(false)
  const [showDeleteDialog, setDeleteDialog] = useState(false)

  useEffect(() => {
    dispatch(getAppUserByUuidAct(uuid))
    return () => dispatch(resetAppUserAct())
  }, [dispatch, uuid])

  useEffect(() => {
    // FIXME: this logic should take an error into account and should depend on success flag!
    if (isDeletingAppUser) {
      enqueueSnackbar(t('AppUserContainer.deleteAccountSuccess'), { variant: 'success' })
    }
  }, [enqueueSnackbar, isDeletingAppUser, t])

  useEffect(() => {
    if (isExportingAppUser) {
      // FIXME: this logic should take an error into account and should depend on success flag!
      enqueueSnackbar(t('AppUserContainer.exportAccountSuccess'), { variant: 'success' })
    }
  }, [enqueueSnackbar, isExportingAppUser, t])

  const handleSubmitExport = () => {
    setExportDialog(false)
    dispatch(exportAppUserByUuidAct())
  }

  const handleSubmitDelete = () => {
    setDeleteDialog(false)
    dispatch(deleteAppUserByUuidAct())
  }

  return (
    <BasicPageContainerLoadable
      helmetTitle={t('AppUserContainer.title')}
      pageTitle={t('AppUserContainer.title')}
      isLoading={false}
    >
      <ErrorAlertContainer errors={[errGettingAppUser]} />
      <PageGrid>
        <Grid item xs={12}>
          <AppUserTabBar />
        </Grid>
        {isDeleted && (
          <Grid item xs={12}>
            <ErrorAlert message={t('AppUserContainer.accountDeleted')} />
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <AppUserLongInfoCard />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={2} style={{ height: '100%' }}>
            <Grid item xs={12}>
              <AddressCard />
            </Grid>
            <Grid item xs={12}>
              <LegalCard />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <DeviceCard />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CreditCard />
        </Grid>
        {!isGettingAppUser && (
          <Grid item container spacing={1} justifyContent='flex-end'>
            <Grid item>
              <Button
                onClick={() => setExportDialog(true)}
                color='primary'
                type='button'
                variant='outlined'
                disabled={false}
                style={{ textTransform: 'none' }}
              >
                {t('AppUserContainer.exportAccount')}
              </Button>
            </Grid>
            {!isDeleted && (
              <Grid item>
                <Button
                  onClick={() => setDeleteDialog(true)}
                  color='secondary'
                  type='button'
                  variant='outlined'
                  disabled={false}
                  style={{ textTransform: 'none' }}
                >
                  {t('AppUserContainer.deleteAccount')}
                </Button>
              </Grid>
            )}
          </Grid>
        )}
      </PageGrid>
      <ConfirmDialog
        open={showExportDialog}
        dialogTitle={t('AppUserContainer.dialogueTitleExportAccount')}
        dialogContent={t('AppUserContainer.dialogueExportContent')}
        confirmLabel={t('AppUserContainer.confirm')}
        cancelLabel={t('AppUserContainer.cancel')}
        onConfirm={handleSubmitExport}
        onClose={() => setExportDialog(false)}
      />
      <ConfirmDialog
        open={showDeleteDialog}
        dialogTitle={t('AppUserContainer.dialogueTitleDeleteAccount')}
        dialogContent={t('AppUserContainer.dialogueDeleteContent')}
        confirmLabel={t('AppUserContainer.confirm')}
        cancelLabel={t('AppUserContainer.cancel')}
        onConfirm={handleSubmitDelete}
        onClose={() => setDeleteDialog(false)}
      />
    </BasicPageContainerLoadable>
  )
}

export default AppUserContainer
