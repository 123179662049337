import React from 'react'
import { styled } from '@mui/material/styles'
import * as PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { uniqueId } from 'lodash'
import { Grid } from '@mui/material'

import ErrorAlert from 'fragments/Alerts/ErrorAlert'
import { getErrorMessage, hasError } from 'util/core'
const PREFIX = 'ErrorAlertContainer'

const classes = {
  gridContainer: `${PREFIX}-gridContainer`,
}

const StyledGridWrapper = styled('div')(({ theme }) => ({
  [`& .${classes.gridContainer}`]: {
    marginBottom: theme.spacing(2),
  },
}))

const ErrorAlertContainer = (props) => {
  const { t } = useTranslation()

  const { errors } = props

  const renderErrorAlertItem = (e) => {
    const id = uniqueId()
    return hasError(e) ? (
      <Grid key={id} item xs={12}>
        <ErrorAlert key={id} message={getErrorMessage(e, t)} />
      </Grid>
    ) : null
  }

  return errors.length === 0 ? null : (
    <StyledGridWrapper>
      <Grid container spacing={2} className={classes.gridContainer}>
        {errors.map((e) => renderErrorAlertItem(e))}
      </Grid>
    </StyledGridWrapper>
  )
}

/* -------------------------------------------------------------------------- */
ErrorAlertContainer.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.shape()).isRequired,
}

export default ErrorAlertContainer
