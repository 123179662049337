const ROOT = '/'
const CATCH_ALL = '*'

export const QUERY_PARAMS = {
  PUBLIC_ID: 'id',
  SEARCH: 'search',
  UUID: 'uuid',
  VERSION: 'version',
  TOKEN: 'token',
}

export const APP = {
  ADMIN_CREATE: '/admin/create',
  ADMIN_EDIT: '/admin/edit',
  ADMIN: '/admin',
  ADMINS: '/admins',
  APP_USER: '/appUser/info',
  APP_USER_CREATE: '/appUser/create',
  APP_USER_CONNECTIONS: '/appUser/connections',
  APP_USER_PAYMENTS: '/appUser/payments',
  APP_USER_RESIDENTIAL: '/appUser/residential',
  CLIENTS: '/clients',
  BARRIER_EDIT: '/barrier/edit',
  BARRIER_OPENINGS: '/openings',
  BARRIER: '/barrier',
  BARRIERS: '/barriers',
  CAR_PARK_BARRIERS: '/carPark/barriers',
  CAR_PARK_CHARGING: '/carPark/charging',
  CAR_PARK_INFO_CREATE: '/carPark/info/create',
  CAR_PARK_INFO_EDIT: '/carPark/info/edit',
  CAR_PARK_INFO: '/carPark/info',
  CAR_PARK_OFFERS: '/carPark/offers',
  CAR_PARK_OPENINGS: '/carPark/openings',
  CAR_PARK_RESIDENTIAL: '/carPark/residential',
  CAR_PARK_RESIDENTIAL_EDIT: '/carPark/residential/edit',
  CAR_PARK_RESIDENTIAL_REMOVE: '/carPark/residential/remove',
  CAR_PARK_TICKETS: '/carPark/tickets',
  CAR_PARKS: '/carParks',
  HOME: '/home',
  OCCUPANCY: '/occupancy',
  OFFER_CREATE: '/offer/create',
  OFFER_EDIT: '/offer/edit',
  OFFER: '/offer',
  OFFERS: '/offers',
  OPERATOR_CREATE: '/operator/create',
  OPERATOR_EDIT: '/operator/edit',
  OPERATOR: '/operator',
  OPERATORS: '/operators',
  PARKMOTIVE_ADMINS: '/parkmotive/admins',
  SALES_MONTH: '/sales/month',
  SALES_YEAR: '/sales/year',
  SETTINGS: '/settings',
  TICKET: '/ticket',
  TICKETS: '/tickets',
  USER_PROFILE: '/profile',
}

export const AUTH = {
  RESET_PW_REQUEST: '/resetPasswordRequest',
  RESET_PW: '/resetPassword',
  ACTIVATE_ADMIN: '/activate',
  SIGN_IN: '/signin',
}

export default { ROOT, CATCH_ALL, AUTH, APP }
