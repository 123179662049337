import React, { Fragment } from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { Grid, Typography, Link as MuiLink } from '@mui/material'
import { uniqueId } from 'lodash'
import * as PropTypes from 'prop-types'

import { BasicCardLoadable } from 'fragments/Loadables'
/* -------------------------------------------------------------------------- */
const LinksCard = (props) => {
  const { title, action, links } = props
  const location = useLocation()

  return (
    <BasicCardLoadable title={title} action={action}>
      <Grid container spacing={1}>
        {links.map((l) => (
          <Fragment key={uniqueId()}>
            <Grid item xs={12}>
              <MuiLink
                variant='body2'
                component={RouterLink}
                to={l.linkTo}
                state={{ from: location }}
                underline='hover'
              >
                {l.linkLabel}
              </MuiLink>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body2' color='secondary'>
                {l.helperText}
              </Typography>
            </Grid>
          </Fragment>
        ))}
      </Grid>
    </BasicCardLoadable>
  )
}
/* -------------------------------------------------------------------------- */
LinksCard.propTypes = {
  title: PropTypes.string.isRequired,
  action: PropTypes.node,
  links: PropTypes.arrayOf(PropTypes.shape()),
}

LinksCard.defaultProps = {
  links: [],
  action: null,
}

export default LinksCard
