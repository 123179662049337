import React from 'react'
import * as PropTypes from 'prop-types'

import de_image from 'assets/img/flags/de.png'
import en_image from 'assets/img/flags/en.png'
import unknown_image from 'assets/img/flags/unknown.png'
/* -------------------------------------------------------------------------- */
const LanguageFlagIcon = (props) => {
  const { className, height, languageCode, width } = props

  const getFlag = () => {
    switch (languageCode) {
      case 'de':
        return de_image
      case 'en':
        return en_image
      default:
        return unknown_image
    }
  }

  return <img className={className} src={getFlag()} style={{ width, height }} alt='lang-icon' />
}

/* -------------------------------------------------------------------------- */
LanguageFlagIcon.propTypes = {
  className: PropTypes.string,
  height: PropTypes.number,
  languageCode: PropTypes.string,
  width: PropTypes.number,
}

LanguageFlagIcon.defaultProps = {
  className: '',
  height: 16,
  width: 25,
  languageCode: null,
}

export default LanguageFlagIcon
