import React from 'react'
import { useSelector } from 'react-redux'
import Grid from '@mui/material/Grid'
import { useTranslation } from 'react-i18next'
import { Home as HomeIcon } from '@mui/icons-material'

import { BasicCardLoadable } from 'fragments/Loadables'
import InfoRows from 'fragments/Cards/InfoRows'
/* -------------------------------------------------------------------------- */
const AddressCard = () => {
  const {
    isGettingAppUser,
    appUser: { street, houseNumber, postalCode, city },
  } = useSelector((state) => state.appUser)
  const { t } = useTranslation()

  const rows = [
    {
      label: t('AddressCard.street'),
      value: street,
    },
    {
      label: t('AddressCard.houseNumber'),
      value: houseNumber,
    },
    {
      label: t('AddressCard.city'),
      value: city,
    },
    {
      label: t('AddressCard.postalCode'),
      value: postalCode,
    },
  ]

  return (
    <BasicCardLoadable isLoading={isGettingAppUser} title={t('AddressCard.userAddress')} action={<HomeIcon />}>
      <Grid container spacing={1}>
        <InfoRows rows={rows} />
      </Grid>
    </BasicCardLoadable>
  )
}

export default AddressCard
