import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'

import { BasicPageContainerLoadable } from 'fragments/Loadables'
import PageGrid from 'fragments/Grid/PageGrid'
import ErrorAlertContainer from 'fragments/Alerts/ErrorAlertContainer'
import BarriersTableCard from 'components/Barriers/BarriersTableCard'
import BarriersFilterCard from 'components/Barriers/BarriersFilterCard'
import { getBarriersAct, resetBarriersFiltersAct, resetBarriersAct } from 'redux/Actions'
import { FREQUENT_UPDATE_TIME_IN_MS } from 'constants/generalConstants'
import { APP } from 'routes/routes'
import { useInterval } from 'hooks'
import LastUpdateInfo from 'fragments/Misc/LastUpdateInfo'
import { useLocation } from 'react-router-dom'
/* -------------------------------------------------------------------------- */

const BarriersContainer = () => {
  const { errGettingBarriers, lastSuccessDate } = useSelector((state) => state.barriers)
  const { selectedOperator, selectedCarParks } = useSelector((state) => state.globalFilters)
  const dispatch = useDispatch()

  const lastLocation = useLocation().state?.from
  const lastPathname = lastLocation?.pathname ?? ''
  const { t } = useTranslation()

  useEffect(() => {
    if (!lastPathname.includes(APP.BARRIER)) {
      dispatch(resetBarriersFiltersAct())
    }
  }, [dispatch, lastPathname])

  useEffect(() => {
    dispatch(getBarriersAct())
    return () => dispatch(resetBarriersAct())
  }, [dispatch, selectedOperator, selectedCarParks])

  useInterval(() => {
    dispatch(getBarriersAct({ isReload: true }))
  }, FREQUENT_UPDATE_TIME_IN_MS)

  return (
    <BasicPageContainerLoadable
      helmetTitle={t('BarriersContainer.barriers')}
      pageTitle={t('BarriersContainer.barriers')}
    >
      <ErrorAlertContainer errors={[errGettingBarriers]} />
      <PageGrid>
        <Grid item xs={12}>
          <BarriersFilterCard />
        </Grid>
        <Grid item xs={12}>
          <BarriersTableCard />
        </Grid>
        <Grid item xs={12}>
          <LastUpdateInfo lastSuccessDate={lastSuccessDate} />
        </Grid>
      </PageGrid>
    </BasicPageContainerLoadable>
  )
}

export default BarriersContainer
