import React from 'react'
import { Grid } from '@mui/material'
import { useLocation, Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { EditButton } from 'fragments/Buttons'
import InfoGrid from 'components/CarPark/Info/InfoGrid'
import CarParkContainer from 'components/CarPark/CarParkContainer'
import { APP, QUERY_PARAMS } from 'routes/routes'
import { getQueryString, getParsedQueryParam } from 'util/core'
import PageGrid from 'fragments/Grid/PageGrid'
import { getCarParkByUuidAct } from 'redux/Actions'
import { INFREQUENT_UPDATE_TIME_IN_MS } from 'constants/generalConstants'
import { useInterval } from 'hooks'
/* -------------------------------------------------------------------------- */

const InfoContainer = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const dispatch = useDispatch()
  const { isReGettingCarPark, lastSuccessDate, errGettingCarPark } = useSelector((state) => state.carPark)
  const uuid = getParsedQueryParam(location.search, QUERY_PARAMS.UUID)

  useInterval(() => {
    dispatch(getCarParkByUuidAct({ uuid, isReload: true }))
  }, INFREQUENT_UPDATE_TIME_IN_MS)

  return (
    <CarParkContainer
      title={t('InfoContainer.info')}
      errors={[errGettingCarPark]}
      lastSuccessDate={lastSuccessDate}
      isUpdating={isReGettingCarPark}
      reloadIntervalInMs={INFREQUENT_UPDATE_TIME_IN_MS}
    >
      <PageGrid justifyContent='flex-end'>
        <InfoGrid />
        <Grid item>
          <EditButton
            component={Link}
            to={{
              pathname: APP.CAR_PARK_INFO_EDIT,
              search: getQueryString({ [QUERY_PARAMS.UUID]: uuid }),
            }}
            state={{ from: location }}
          />
        </Grid>
      </PageGrid>
    </CarParkContainer>
  )
}

export default InfoContainer
