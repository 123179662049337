import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'

import { getParsedQueryParam, getQueryString } from 'util/core'
import PageGrid from 'fragments/Grid/PageGrid'
import ResidentialParkingTableCard from 'components/ResidentialParking/ResidentialParkingTableCard'
import CarParkContainer from 'components/CarPark/CarParkContainer'
import { getCarParkByUuidAct, getResidentialParkingAssignmentsByCarParkUuidAct, resetCarParkAct } from 'redux/Actions'
import { resetEditCarParkAct } from 'redux/Actions'
import { APP, QUERY_PARAMS } from 'routes/routes'
import ResidentialParkingAddCard from 'components/ResidentialParking/ResidentialParkingAddCard'
/* -------------------------------------------------------------------------- */

const CarParkResidentialContainer = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {
    errGettingCarPark,
    editCarParkSuccess,
    carPark: { assignedUsers },
  } = useSelector((state) => state.carPark)
  const { residentialParkingAssignments } = useSelector((state) => state.residentialParkingAssignments)
  const carParkUuid = getParsedQueryParam(location.search, QUERY_PARAMS.UUID)

  useEffect(() => {
    dispatch(getResidentialParkingAssignmentsByCarParkUuidAct({ carParkUuid: carParkUuid }))
  }, [carParkUuid, dispatch])

  useEffect(() => {
    const carParkUuid = getParsedQueryParam(location.search, QUERY_PARAMS.UUID)
    dispatch(getCarParkByUuidAct({ uuid: carParkUuid }))

    return () => {
      dispatch(resetCarParkAct())
    }
  }, [location.search, dispatch])

  const to = React.useMemo(
    () => ({
      pathname: APP.CAR_PARK_RESIDENTIAL,
      search: getQueryString({
        [QUERY_PARAMS.UUID]: carParkUuid,
      }),
    }),
    [carParkUuid],
  )

  useEffect(() => {
    if (editCarParkSuccess) {
      navigate(
        {
          ...to,
        },
        { state: { from: location } },
      )
    }
    return () => {
      if (editCarParkSuccess) {
        dispatch(resetEditCarParkAct())
      }
    }
  }, [dispatch, editCarParkSuccess, navigate, to, carParkUuid, location])

  return (
    <CarParkContainer title={t('CarParkBarriersContainer.residential')} errors={[errGettingCarPark]}>
      <PageGrid>
        <Grid item xs={12}>
          <ResidentialParkingAddCard />
        </Grid>
        <Grid item xs={12}>
          <ResidentialParkingTableCard users={assignedUsers ?? []} assignments={residentialParkingAssignments ?? []} />
        </Grid>
      </PageGrid>
    </CarParkContainer>
  )
}

export default CarParkResidentialContainer
