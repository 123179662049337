import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Grid, Button } from '@mui/material'
import { Delete as DeleteIcon } from '@mui/icons-material'
import { useSnackbar } from 'notistack'

import { BasicCardLoadable } from 'fragments/Loadables'
import { deleteAppUserByUuidAct, getAppUserByEmailAct, logOutUserAct, getAdminByEmailAct } from 'redux/Actions'
import ConfirmDialog from 'fragments/Dialogs/ConfirmDialog'
import ErrorAlertContainer from 'fragments/Alerts/ErrorAlertContainer'
import ErrorAlert from 'fragments/Alerts/ErrorAlert'

const buttonStyle = {
  borderRadius: '30px',
  textTransform: 'none',
}

const DeleteUserCard = () => {
  const { email } = useSelector((state) => state.auth.user)
  const {
    errGettingAppUser,
    isDeletingAppUser,
    appUser: { isDeleted, uuid },
  } = useSelector((state) => state.appUser)
  const { admin, errGettingAdmin } = useSelector((state) => state.admin)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [showDeleteDialog, setDeleteDialog] = useState(false)

  useEffect(() => {
    if (email !== undefined) {
      dispatch(getAdminByEmailAct({ email }))
      dispatch(getAppUserByEmailAct(email))
    }
  }, [dispatch, email])

  useEffect(() => {
    if (isDeletingAppUser) {
      enqueueSnackbar(t('AppUserContainer.deleteAccountSuccess'), { variant: 'success' })
    }
  }, [enqueueSnackbar, isDeletingAppUser, t])

  useEffect(() => {
    if (isDeleted) {
      dispatch(logOutUserAct())
    }
  }, [isDeleted])

  const handleSubmitDelete = () => {
    setDeleteDialog(false)
    if (uuid || admin.uuid) {
      dispatch(deleteAppUserByUuidAct(uuid ? uuid : admin.uuid))
    }
  }

  return (
    <BasicCardLoadable title={t('DeleteUserCard.title')} action={<DeleteIcon />}>
      <Grid container direction='row' spacing={3} justifyContent={'center'} alignItems={'center'}>
        <Grid container item spacing={1} alignItems='flex-end'>
          <Grid item>
            <p>{t('DeleteUserCard.advice')}</p>
            <p>{t('DeleteUserCard.adviceText')}</p>
            {!uuid && !admin.uuid && <ErrorAlertContainer errors={[errGettingAdmin, errGettingAppUser]} />}
          </Grid>
        </Grid>
        <Grid item>
          <Button
            type='button'
            onClick={() => setDeleteDialog(true)}
            disabled={!uuid && !admin.uuid}
            sx={{
              ...buttonStyle,
              '&.MuiButton-outlined': {
                borderColor: 'red',
                color: 'red',
              },
            }}
            variant='outlined'
            fullWidth
          >
            {t('DeleteUserCard.title')}
          </Button>
        </Grid>
      </Grid>
      <ConfirmDialog
        open={showDeleteDialog}
        dialogTitle={t('DeleteUserCard.dialogueTitleDeleteAccount')}
        dialogContent={t('DeleteUserCard.dialogueDeleteContent')}
        confirmLabel={t('DeleteUserCard.confirm')}
        cancelLabel={t('DeleteUserCard.cancel')}
        onConfirm={handleSubmitDelete}
        onClose={() => setDeleteDialog(false)}
      />
      {isDeleted && (
        <Grid item xs={12}>
          <ErrorAlert message={t('DeleteUserCard.accountDeleted')} />
        </Grid>
      )}
    </BasicCardLoadable>
  )
}

export default DeleteUserCard
