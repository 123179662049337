import React, { Fragment } from 'react'
import { styled } from '@mui/material/styles'
import * as PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { NavLink as BaseNavLink, useLocation } from 'react-router-dom'
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip, Box } from '@mui/material'

import sidebarConfig from 'layouts/AppLayout/Sidebar/sidebarConfig'
import HasRole from 'components/Session/HasRole'
const PREFIX = 'SidebarNav'
const BORDER_WIDTH = 5

const classes = {
  navList: `${PREFIX}-navList`,
  item: `${PREFIX}-item`,
  icon: `${PREFIX}-icon`,
  text: `${PREFIX}-text`,
  active: `${PREFIX}-active`,
  popper: `${PREFIX}-popper`,
}

const StyledList = styled(List)(({ theme }) => ({
  [`&.${classes.navList}`]: {
    width: theme.sidebar.width,
  },

  [`& .${classes.item}`]: {
    height: 53,
  },

  [`& .${classes.icon}`]: {
    color: theme.sidebar.color,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(3),
    minWidth: 40,
  },

  [`& .${classes.text}`]: {
    color: theme.sidebar.color,
  },

  [`& .${classes.active}`]: {
    background: theme.sidebar.active,
    borderLeft: `${BORDER_WIDTH}px solid ${theme.sidebar.color}`,
    paddingLeft: parseInt(theme.spacing(3)) - BORDER_WIDTH,
    height: 53,
    '& div': {
      marginLeft: theme.spacing(0),
    },
  },

  [`& .${classes.popper}`]: {
    marginLeft: -195,
  },
}))

/* -------------------------------------------------------------------------- */

const SidebarNav = (props) => {
  const { t } = useTranslation()

  const { isCollapsed, onSelect } = props
  const location = useLocation()

  const NavLink = React.forwardRef(({ activeClassName, activeStyle, ...props }, ref) => {
    return (
      <BaseNavLink
        ref={ref}
        {...props}
        className={({ isActive }) => [props.className, isActive ? activeClassName : null].filter(Boolean).join(' ')}
        style={({ isActive }) => ({
          ...props.style,
          ...(isActive ? activeStyle : null),
        })}
      />
    )
  })

  return (
    <StyledList className={classes.navList} disablePadding>
      {sidebarConfig(t).map((group) => (
        <Fragment key={group[0].title}>
          <Box m={3} />
          {group.map((entry) => (
            <HasRole key={entry.title} allowedRoles={entry.allowedRoles}>
              <Tooltip
                title={entry.title}
                placement='right'
                disableHoverListener={!isCollapsed}
                classes={{ popper: classes.popper }}
                arrow
              >
                <ListItem disableGutters disablePadding className={classes.item}>
                  <ListItemButton
                    onClick={onSelect}
                    component={NavLink}
                    state={{ from: location }}
                    activeClassName={classes.active}
                    to={entry.path}
                    disableGutters
                  >
                    <ListItemIcon className={classes.icon}>{entry.icon}</ListItemIcon>
                    <ListItemText className={classes.text} primaryTypographyProps={{ variant: 'subtitle1' }}>
                      {entry.title}
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              </Tooltip>
            </HasRole>
          ))}
        </Fragment>
      ))}
    </StyledList>
  )
}

SidebarNav.propTypes = {
  isCollapsed: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
}

SidebarNav.defaultProps = {
  onSelect: () => {},
}

export default SidebarNav
