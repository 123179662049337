import { call, put, takeLatest, all, fork } from 'redux-saga/effects'

import { GET_ADMIN_BY_UUID, GET_ADMIN_BY_EMAIL, CREATE_ADMIN, EDIT_ADMIN } from 'redux/Types'
import {
  getAdminByUuidSuccessAct,
  getAdminByUuidErrorAct,
  createAdminSuccessAct,
  createAdminErrorAct,
  editAdminSuccessAct,
  editAdminErrorAct,
  catchSagaErrorsAct,
} from 'redux/Actions'
import { getAdminByUuidReq, getAdminByEmailReq, createAdminReq, editAdminReq } from 'api/requests'
/* -------------------------------------------------------------------------- */

function* workerGetAdminByUuid({ uuid }) {
  try {
    const response = yield call(getAdminByUuidReq, uuid)
    yield put(getAdminByUuidSuccessAct(response))
  } catch (error) {
    yield put(catchSagaErrorsAct(error, getAdminByUuidErrorAct))
  }
}

function* watcherGetAdminByUuid() {
  yield takeLatest(GET_ADMIN_BY_UUID.REQUEST, workerGetAdminByUuid)
}

function* workerGetAdminByEmail({ email }) {
  try {
    const response = yield call(getAdminByEmailReq, email)
    yield put(getAdminByUuidSuccessAct(response.content[0]))
  } catch (error) {
    yield put(catchSagaErrorsAct(error, getAdminByUuidErrorAct))
  }
}

function* watcherGetAdminByEmail() {
  yield takeLatest(GET_ADMIN_BY_EMAIL.REQUEST, workerGetAdminByEmail)
}

// ----- CREATE ADMIN ----
function* workerCreateAdmin(action) {
  try {
    const { adminCreatable } = action
    const admin = yield call(createAdminReq, adminCreatable)
    yield put(createAdminSuccessAct(admin))
  } catch (error) {
    yield put(catchSagaErrorsAct(error, createAdminErrorAct))
  }
}

function* watcherCreateAdmin() {
  yield takeLatest(CREATE_ADMIN.REQUEST, workerCreateAdmin)
}

// ----- EDIT ADMIN ----
function* workerEditAdmin(action) {
  try {
    const { adminUpdatable, uuid } = action
    const admin = yield call(editAdminReq, uuid, adminUpdatable)
    yield put(editAdminSuccessAct(admin))
  } catch (error) {
    yield put(catchSagaErrorsAct(error, editAdminErrorAct))
  }
}

function* watcherEditAdmin() {
  yield takeLatest(EDIT_ADMIN.REQUEST, workerEditAdmin)
}

// ----- EXPORT -----
export default function* rootSaga() {
  yield all([
    fork(watcherGetAdminByUuid),
    fork(watcherCreateAdmin),
    fork(watcherEditAdmin),
    fork(watcherGetAdminByEmail),
  ])
}

/* ----- EOF ---------------------------------------------------------------- */
