import React from 'react'
import * as PropTypes from 'prop-types'
import { Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon } from '@mui/icons-material'

import BinaryIconChip from 'fragments/IconChips/BinaryIconChip'
import { useTranslation } from 'react-i18next'
/* -------------------------------------------------------------------------- */
const VisibilityChip = (props) => {
  const { isVisible } = props
  const { t } = useTranslation()

  return (
    <BinaryIconChip
      condition={isVisible}
      falseIcon={VisibilityOffIcon}
      falseLabel={t('VisibilityChip.invisible')}
      trueIcon={VisibilityIcon}
      trueLabel={t('VisibilityChip.visible')}
    />
  )
}

/* -------------------------------------------------------------------------- */
VisibilityChip.propTypes = {
  isVisible: PropTypes.bool,
}

VisibilityChip.defaultProps = {
  isVisible: null,
}

export default VisibilityChip
