import React from 'react'
import { CssBaseline } from '@mui/material'

import BaseRoutes from 'routes/BaseRoutes'
import AppProviders from 'context/AppProviders'
/* -------------------------------------------------------------------------- */

export default function App() {
  return (
    <AppProviders>
      <CssBaseline />
      <BaseRoutes />
    </AppProviders>
  )
}
