import React from 'react'
import { TimePicker } from 'formik-mui-lab'
import * as PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Field } from 'formik'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { localeMap } from 'util/i18n'
import { TextField } from '@mui/material'
/* -------------------------------------------------------------------------- */

const CustomTimePicker = (props) => {
  const { t, i18n } = useTranslation()
  const { label, inputVariant, name, showTodayButton } = props

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={localeMap[i18n.language]}>
      <Field
        component={TimePicker}
        name={name}
        label={label}
        inputVariant={inputVariant}
        inputFormat='HH:mm'
        // KeyboardButtonProps={{
        //   'aria-label': t('CustomTimePicker.changeTime'),
        // }}
        ampm={false}
        showTodayButton={showTodayButton}
        disableFuture
        okText={t('CustomTimePicker.ok')}
        cancelText={t('CustomTimePicker.cancel')}
        todayText={t('CustomTimePicker.today')}
        clearable
        clearText={t('CustomTimePicker.delete')}
        renderInput={(props) => (
          <TextField
            {...props}
            helperText={props.error ? t('CustomTimePicker.failure') : ''}
            fullWidth
            variant={inputVariant}
            placeholder='HH:MM'
          />
        )}
      />
    </LocalizationProvider>
  )
}
/* -------------------------------------------------------------------------- */
CustomTimePicker.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  inputVariant: PropTypes.string,
  showTodayButton: PropTypes.bool,
}

CustomTimePicker.defaultProps = {
  label: null,
  inputVariant: 'standard',
  showTodayButton: false,
}
export default CustomTimePicker
