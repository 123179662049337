import React from 'react'
import * as PropTypes from 'prop-types'
import MenuItem from '@mui/material/MenuItem'
import { TextField } from 'formik-mui'
import { Field } from 'formik'

import LanguageFlagIconWithString from 'fragments/Icons/LanguageFlagIconWithString'
import { localeCodesArr } from 'util/i18n'
/* -------------------------------------------------------------------------- */

const LanguageSelect = (props) => {
  const { name } = props

  return (
    <Field name={name} component={TextField} select fullWidth>
      {localeCodesArr.map((code) => (
        <MenuItem key={code} value={code}>
          <LanguageFlagIconWithString languageCode={code} />
        </MenuItem>
      ))}
    </Field>
  )
}

/* -------------------------------------------------------------------------- */
LanguageSelect.propTypes = {
  name: PropTypes.string.isRequired,
}

export default LanguageSelect
