import { BODY_PAGINATION } from 'api/backendConstants'
import { unpackAssignments } from 'api/carParkMgmt/carParkMgmtUnpackers'
import { GET_CAR_PARK_RESIDENTIAL_PARKING } from 'redux/Types'

/* -------------------------------------------------------------------------- */
const INIT_STATE = {
  residentialParkingAssignments: [],
  errGettingAssignments: {
    errorCode: null,
    httpStatusCode: null,
    message: null,
  },
  isGettingAssignments: false,
  isReGettingAssignments: false,
  lastSuccessDate: undefined,
  pagination: {
    page: 0,
    rows: 10,
    totalCount: 0,
  },
}

export default (state = INIT_STATE, action) => {
  const { type } = action

  switch (type) {
    case GET_CAR_PARK_RESIDENTIAL_PARKING.REQUEST: {
      const { isReload, page, rows } = action
      return {
        ...state,
        isGettingAssignments: !isReload,
        isReGettingAssignments: !!isReload,
        errGettingAssignments: INIT_STATE.errGettingAssignments,
        pagination: {
          ...state.pagination,
          page: page ?? state.pagination.page,
          rows: rows ?? state.pagination.rows,
        },
      }
    }
    case GET_CAR_PARK_RESIDENTIAL_PARKING.SUCCESS: {
      const { data } = action
      const { CONTENT_ARR: PAGE_NUMBER, PAGE_SIZE, TOTAL_ELEMENTS } = BODY_PAGINATION
      return {
        ...state,
        residentialParkingAssignments: unpackAssignments(data),
        isGettingAssignments: false,
        isReGettingAssignments: false,
        lastSuccessDate: new Date(),
        pagination: {
          totalCount: data?.[TOTAL_ELEMENTS] ?? state.pagination.totalCount,
          page: data?.[PAGE_NUMBER] ?? state.pagination.page,
          rows: (data?.[PAGE_SIZE] ?? state.pagination.rows) || 10,
        },
      }
    }
    case GET_CAR_PARK_RESIDENTIAL_PARKING.ERROR: {
      const { error } = action
      return {
        ...state,
        isGettingAssignments: false,
        isReGettingAssignments: false,
        errGettingAssignments: error,
        pagination: INIT_STATE.pagination,
      }
    }
    default:
      return { ...state }
  }
}
