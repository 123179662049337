import { GET_APP_USER, DELETE_APP_USER, EXPORT_USER_ACCOUNT, RESET_APP_USER, GET_APP_USER_BY_EMAIL } from 'redux/Types'
import { unpackAppUser } from 'api/appUserMgmt/appUserMgmtUnpackers'
/* -------------------------------------------------------------------------- */

const INIT_STATE = {
  appUser: unpackAppUser({}),
  isGettingAppUser: false,
  errGettingAppUser: {
    httpStatusCode: null,
    errorCode: null,
    message: null,
  },
  isDeletingAppUser: false,
  isExportingAppUser: false,
  errCheckingForUnpaidBills: {
    httpStatusCode: null,
    errorCode: null,
    message: null,
  },
}

export default (state = INIT_STATE, action) => {
  const { type } = action
  switch (type) {
    case GET_APP_USER.REQUEST: {
      return {
        ...state,
        isGettingAppUser: true,
        errGettingAppUser: INIT_STATE.errGettingAppUser,
      }
    }
    case GET_APP_USER.SUCCESS: {
      const { data } = action
      return {
        ...state,
        isGettingAppUser: false,
        appUser: unpackAppUser(data),
      }
    }
    case GET_APP_USER.ERROR: {
      const { error } = action
      return {
        ...state,
        isGettingAppUser: false,
        errGettingAppUser: error,
      }
    }
    case GET_APP_USER_BY_EMAIL.REQUEST: {
      return {
        ...state,
        isGettingAppUser: true,
        errGettingAppUser: INIT_STATE.errGettingAppUser,
      }
    }
    case GET_APP_USER_BY_EMAIL.SUCCESS: {
      const { data } = action
      return {
        ...state,
        isGettingAppUser: false,
        appUser: unpackAppUser(data),
      }
    }
    case GET_APP_USER_BY_EMAIL.ERROR: {
      const { error } = action
      return {
        ...state,
        isGettingAppUser: false,
        errGettingAppUser: error,
      }
    }
    case RESET_APP_USER: {
      return {
        ...state,
        appUser: INIT_STATE.appUser,
      }
    }
    case DELETE_APP_USER.REQUEST: {
      return {
        ...state,
        isDeletingAppUser: true,
        appUser: {
          ...state.appUser,
          isDeleted: true,
        },
      }
    }
    case EXPORT_USER_ACCOUNT.REQUEST: {
      return {
        ...state,
        isExportingAppUser: true,
      }
    }
    default:
      return { ...state }
  }
}
