import { call, put, takeLatest, all, fork } from 'redux-saga/effects'

import { catchSagaErrorsAct } from 'redux/Actions'
import { GET_CAR_PARK_RESIDENTIAL_PARKING } from 'redux/Types'
import {
  getResidentialParkingAssignmentsByCarParkUuidErrorAct,
  getResidentialParkingAssignmentsByCarParkUuidSuccessAct,
} from './ResidentialParkingAssignmentsActions'
import { getCarParkResidentialParkingAssignmentsReq } from 'api/requests'

function* workerGetCarParkResidentialParkingAssignments({ carParkUuid }) {
  try {
    const data = yield call(getCarParkResidentialParkingAssignmentsReq, {
      carParkUuid: carParkUuid,
    })

    yield put(getResidentialParkingAssignmentsByCarParkUuidSuccessAct(data.data.content))
  } catch (error) {
    console.error(error)
    yield put(catchSagaErrorsAct(error, getResidentialParkingAssignmentsByCarParkUuidErrorAct))
  }
}

function* watcherGetCarParkResidentialParkingAssignments() {
  yield takeLatest(GET_CAR_PARK_RESIDENTIAL_PARKING.REQUEST, workerGetCarParkResidentialParkingAssignments)
}

// ----- EXPORT -----
export default function* rootSaga() {
  yield all([fork(watcherGetCarParkResidentialParkingAssignments)])
}
