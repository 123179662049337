import React from 'react'
import { Info as InfoIcon } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

import LinksCard from 'fragments/Cards/LinksCard'
import { APP } from 'routes/routes'
import company from 'constants/company'
/* -------------------------------------------------------------------------- */
const LinksToParkMotiveCard = () => {
  const { t } = useTranslation()
  const links = [
    // {
    //   linkLabel: t('LinksToParkMotiveCard.infoLink'),
    //   helperText: t('LinksToParkMotiveCard.infoText'),
    //   linkTo: {
    //     pathname: APP.PARKMOTIVE_ADMINS,
    //   },
    // },
    {
      linkLabel: t('LinksToParkMotiveCard.teamLink'),
      helperText: t('LinksToParkMotiveCard.teamText'),
      linkTo: {
        pathname: APP.ADMINS,
      },
    },
  ]
  return <LinksCard title={company.name} action={<InfoIcon />} links={links} />
}
/* -------------------------------------------------------------------------- */
export default LinksToParkMotiveCard
