import React from 'react'
import { useTranslation } from 'react-i18next'
import { BarChart as BarChartIcon } from '@mui/icons-material'
import { Grid } from '@mui/material'
/* -------------------------------------------------------------------------- */

const EmptyChartComponent = () => {
  const { t } = useTranslation()
  return (
    <Grid container alignItems='center' spacing={1}>
      <Grid item>
        <BarChartIcon fontSize='large' color='primary' />
      </Grid>
      <Grid item>{t('EmptyChartComponent.noData')}</Grid>
    </Grid>
  )
}

export default EmptyChartComponent
