import React from 'react'
import * as PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { PermScanWifi as PermScanWifiIcon } from '@mui/icons-material'

import { BasicCardLoadable } from 'fragments/Loadables'
import SelectTextField from 'fragments/Forms/Formik/SelectTextField'
import BarrierOnlineStateChip from 'fragments/IconChips/BarrierOnlineStateChip'
import InfoRows from 'fragments/Cards/InfoRows'
import { getBarrierOperatingStateAsString, getBarrierOperatingStates } from 'util/custom'
import BeaconStatusChip from 'fragments/IconChips/BeaconStateChip'
/* -------------------------------------------------------------------------- */

const BarrierStateCard = (props) => {
  const { t } = useTranslation()
  const {
    barrier: { state, isOnline, lastUpdateCheckAt, isBeaconOnline, lastBeaconTimestamp },
    isEditingBarrier,
  } = useSelector((state) => state.barrier)
  const { isEdit } = props

  const operatingStateInput = () => (
    <SelectTextField name='state' options={getBarrierOperatingStates(t)} variant='standard' />
  )

  const rows = [
    {
      label: t('BarrierStateCard.operatingState') + (isEdit ? ' *' : ''),
      value: isEdit ? operatingStateInput() : getBarrierOperatingStateAsString(t, state),
    },
    {
      label: t('BarrierStateCard.onlineState'),
      value: <BarrierOnlineStateChip isOnline={isOnline} lastSeenAt={lastUpdateCheckAt} />,
    },
    {
      label: t('BarrierStateCard.beaconState'),
      value: <BeaconStatusChip isOnline={isBeaconOnline} lastSeenAt={lastBeaconTimestamp} />,
    },
  ]

  return (
    <BasicCardLoadable title={t('BarrierStateCard.title')} action={<PermScanWifiIcon />} isLoading={isEditingBarrier}>
      <Grid container spacing={1}>
        <InfoRows rows={rows} />
      </Grid>
    </BasicCardLoadable>
  )
}

BarrierStateCard.propTypes = {
  isEdit: PropTypes.bool,
}

BarrierStateCard.defaultProps = {
  isEdit: false,
}

export default BarrierStateCard
