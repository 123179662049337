import React from 'react'
import { DateTimePicker } from 'formik-mui-lab'
import * as PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Field } from 'formik'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { localeMap } from 'util/i18n'
import { TextField } from '@mui/material'
/* -------------------------------------------------------------------------- */

const CustomDateTimePicker = (props) => {
  const { t, i18n } = useTranslation()
  const { disableFuture, disablePast, name, label, inputVariant, error } = props

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={localeMap[i18n.language]}>
      <Field
        component={DateTimePicker}
        name={name}
        label={label}
        inputFormat='dd.MM.yyyy HH:mm'
        mask='__.__.____ __:__'
        // KeyboardButtonProps={{
        //   'aria-label': t('CustomDateTimePicker.changeDate'),
        // }}
        ampm={false}
        showTodayButton
        disableFuture={disableFuture}
        disablePast={disablePast}
        okText={t('CustomDateTimePicker.ok')}
        cancelText={t('CustomDateTimePicker.cancel')}
        todayText={t('CustomDateTimePicker.today')}
        clearable
        clearText={t('CustomDateTimePicker.delete')}
        showToolbar={true}
        minDateTime={new Date()}
        renderInput={(props) => (
          <TextField
            {...props}
            error={error ? true : props.error ? true : false}
            helperText={
              error
                ? error.includes('Invalid Date')
                  ? t('CustomDateTimePicker.failure')
                  : error
                : props.error
                ? t('CustomDateTimePicker.invalidPast')
                : ''
            }
            fullWidth
            variant={inputVariant}
            placeholder='DD.MM.YYYY HH:MM'
          />
        )}
      />
    </LocalizationProvider>
  )
}
/* -------------------------------------------------------------------------- */
CustomDateTimePicker.propTypes = {
  name: PropTypes.string.isRequired,
  disableFuture: PropTypes.bool,
  disablePast: PropTypes.bool,
  label: PropTypes.string,
  inputVariant: PropTypes.string,
  error: PropTypes.string,
}

CustomDateTimePicker.defaultProps = {
  disableFuture: true,
  disablePast: false,
  label: null,
  inputVariant: 'standard',
  error: '',
}
export default CustomDateTimePicker
