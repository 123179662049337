import React from 'react'
import { useTranslation } from 'react-i18next'

import { BasicCardLoadable } from 'fragments/Loadables'
import MuiDataTableThemed from 'fragments/tables/MuiDataTableThemed'
import columns from './TicketsTableColumns'
import options from './ticketsTableOptions'
import { Ticket } from 'services/tickets/tickets-service'
import { useNavigate } from 'react-router-dom'
/* -------------------------------------------------------------------------- */

type TicketsTableCardProps = {
  tickets: Ticket[]
  page?: number
  setPage?: (n: number) => void
  rows?: number
  setRows?: (n: number) => void
  totalCount?: number
  isLoading: boolean
  search?: string
  onSearchChange?: (s: string) => void
}

export default function TicketsTableCard(props: TicketsTableCardProps) {
  const { tickets, page, setPage, rows, setRows, totalCount, isLoading, search, onSearchChange } = props
  let navigate = useNavigate()

  const {
    t,
    i18n: { language },
  } = useTranslation()

  return (
    <BasicCardLoadable isLoading={isLoading}>
      <MuiDataTableThemed
        data={tickets}
        columns={columns(tickets, language as 'en' | 'de', t)}
        options={options({
          tickets,
          onChangePage: setPage,
          onChangeRowsPerPage: setRows,
          navigate,
          page,
          rows,
          t,
          totalCount,
          search,
          onSearchChange,
        })}
      />
    </BasicCardLoadable>
  )
}
