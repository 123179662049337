import { BODY_ASSIGNMENTS, BODY_BARRIERS, BODY_CAR_PARKS } from 'api/carParkMgmt/carParkMgmtModels'
import { appendZeroUTC, getTimeInMinByValueAndDuration } from 'util/core'
/* -------------------------------------------------------------------------- */

// ----- BARRIERS -----

export const unpackBarrier = (b) => {
  const {
    BEACON_MAJOR,
    BEACON_MINOR,
    CAR_PARK_NAME,
    CAR_PARK_UUID,
    CREATED_AT,
    IS_BEACON_ALIVE,
    IS_ENTRANCE,
    IS_MOCK,
    IS_ONLINE,
    IS_REBOOT_ALLOWED,
    IS_SECRET_GENERATION_ALLOWED,
    IS_SSH_TUNNEL_ENABLED,
    IS_UPDATE_ALLOWED,
    LAST_SUCCESSFUL_BEACON_CHECK_AT,
    LAST_UPDATE_CHECK_AT,
    MAC_ADDRESS,
    NAME,
    OPERATOR_UUID,
    SERVICE_UUID,
    SOFTWARE_VERSION,
    STATE,
    UPDATE_URL,
    UPDATED_AT,
    UUID,
  } = BODY_BARRIERS

  return {
    beaconMajor: b?.[BEACON_MAJOR],
    beaconMinor: b?.[BEACON_MINOR],
    carParkName: b?.[CAR_PARK_NAME],
    carParkUuid: b?.[CAR_PARK_UUID],
    createdAt: b?.[CREATED_AT],
    isBeaconOnline: b?.[IS_BEACON_ALIVE],
    isEntrance: b?.[IS_ENTRANCE],
    isMock: b?.[IS_MOCK],
    isOnline: b?.[IS_ONLINE],
    isRebootAllowed: b?.[IS_REBOOT_ALLOWED],
    isSecretGenerationAllowed: b?.[IS_SECRET_GENERATION_ALLOWED],
    isSshTunnelEnabled: b?.[IS_SSH_TUNNEL_ENABLED],
    isUpdateAllowed: b?.[IS_UPDATE_ALLOWED],
    lastBeaconTimestamp: appendZeroUTC(b?.[LAST_SUCCESSFUL_BEACON_CHECK_AT]),
    lastUpdateCheckAt: appendZeroUTC(b?.[LAST_UPDATE_CHECK_AT]),
    macAddress: b?.[MAC_ADDRESS],
    name: b?.[NAME],
    operatorUuid: b?.[OPERATOR_UUID],
    serviceUuid: b?.[SERVICE_UUID],
    softwareVersion: b?.[SOFTWARE_VERSION],
    state: b?.[STATE],
    updateUrl: b?.[UPDATE_URL],
    UPDATED_AT: b?.[UPDATED_AT],
    uuid: b?.[UUID],
  }
}

export const unpackBarriers = (barriers) => barriers.map(unpackBarrier)

export const packBarrier = (barrier) => {
  const {
    CAR_PARK_UUID,
    IS_ENTRANCE,
    BEACON_MINOR,
    IS_MOCK,
    STATE,
    UPDATE_URL,
    IS_UPDATE_ALLOWED,
    IS_REBOOT_ALLOWED,
    IS_SSH_TUNNEL_ENABLED,
    IS_SECRET_GENERATION_ALLOWED,
    NAME,
  } = BODY_BARRIERS

  return {
    [CAR_PARK_UUID]: barrier.carParkUuid ?? null,
    [IS_ENTRANCE]: barrier.isEntrance ?? null,
    [BEACON_MINOR]: barrier.beaconMinor ?? null,
    [IS_MOCK]: barrier.isMock ?? null,
    [STATE]: barrier.state ?? null,
    [UPDATE_URL]: barrier.updateUrl ?? null,
    [IS_UPDATE_ALLOWED]: barrier.isUpdateAllowed ?? null,
    [IS_REBOOT_ALLOWED]: barrier.isRebootAllowed ?? null,
    [IS_SSH_TUNNEL_ENABLED]: barrier.isSshTunnelEnabled ?? null,
    [IS_SECRET_GENERATION_ALLOWED]: barrier.isSecretGenerationAllowed ?? null,
    [NAME]: barrier.name ?? null,
  }
}

// ----- CAR PARKS -----

export const unpackCarPark = (cp) => {
  const {
    ASSIGNED_USERS,
    BARRIERS,
    BEACON_MAJOR,
    CITY,
    CONTINUITY_PERIOD_IN_MIN,
    CONTINUITY_PRICE_IN_CENT,
    CREATED_AT,
    HOUSE_NUMBER,
    IS_VISIBLE,
    LATITUDE,
    LONGITUDE,
    NAME,
    OPENING_HOURS,
    OPERATOR_UUID,
    PARKING_LOTS,
    POSTAL_CODE,
    PRICE_MAX_PER_DAY_IN_CENT,
    STREET,
    TELEPHONE_NUMBER,
    TIMEZONE,
    UPDATED_AT,
    USER_INSIDE_COUNT,
    UUID,
  } = BODY_CAR_PARKS

  return {
    assignedUsers: cp[ASSIGNED_USERS] ?? [],
    barriers: unpackBarriers(cp?.[BARRIERS] ?? []),
    beaconMajor: cp?.[BEACON_MAJOR] ?? null,
    city: cp?.[CITY] ?? null,
    continuityPeriodInMinutes: cp?.[CONTINUITY_PERIOD_IN_MIN] ?? null,
    continuityPriceInCent: cp?.[CONTINUITY_PRICE_IN_CENT] ?? null,
    countParkingLots: cp?.[PARKING_LOTS] ?? null,
    countUserInside: cp?.[USER_INSIDE_COUNT] ?? null,
    createdAt: cp?.[CREATED_AT] ?? null,
    houseNumber: cp?.[HOUSE_NUMBER] ?? null,
    isVisible: cp?.[IS_VISIBLE] ?? null,
    uuid: cp?.[UUID] ?? null,
    imageUrl: null,
    latitude: cp?.[LATITUDE] ?? null,
    longitude: cp?.[LONGITUDE] ?? null,
    name: cp?.[NAME] ?? null,
    openingHours: cp?.[OPENING_HOURS] ?? null,
    operatorUuid: cp?.[OPERATOR_UUID] ?? null,
    postalCode: cp?.[POSTAL_CODE] ?? null,
    priceMaxPerDayInCent: cp?.[PRICE_MAX_PER_DAY_IN_CENT] ?? null,
    street: cp?.[STREET] ?? null,
    telephoneNumber: cp?.[TELEPHONE_NUMBER] ?? null,
    timezone: cp?.[TIMEZONE] ?? null,
    updatedAt: cp?.[UPDATED_AT] ?? null,
  }
}

export const unpackCarParkMinified = (cp) => {
  const { NAME, UUID } = BODY_CAR_PARKS
  return {
    uuid: cp?.[UUID] ?? null,
    name: cp?.[NAME] ?? null,
  }
}

export const unpackCarParks = (carParks) => carParks.map(unpackCarPark)
export const unpackCarParksMinified = (carParks) => carParks.map(unpackCarParkMinified)

export const pack = (carPark) => {
  const {
    OPERATOR_UUID,
    NAME,
    STREET,
    HOUSE_NUMBER,
    POSTAL_CODE,
    CITY,
    LATITUDE,
    LONGITUDE,
    TELEPHONE_NUMBER,
    BEACON_MAJOR,
    PARKING_LOTS,
    CONTINUITY_PRICE_IN_CENT,
    CONTINUITY_PERIOD_IN_MIN,
    PRICE_MAX_PER_DAY_IN_CENT,
    USER_INSIDE_COUNT,
    OPENING_HOURS,
    IS_VISIBLE,
  } = BODY_CAR_PARKS

  const continuityPeriodInMin = getTimeInMinByValueAndDuration(
    carPark.continuityPeriodDuration,
    carPark.continuityPeriodUnit,
  )

  return {
    [OPERATOR_UUID]: carPark.operatorUuid,
    [NAME]: carPark.name,
    [STREET]: carPark.street,
    [HOUSE_NUMBER]: carPark.houseNumber,
    [POSTAL_CODE]: carPark.postalCode,
    [CITY]: carPark.city,
    [LATITUDE]: carPark.latitude,
    [LONGITUDE]: carPark.longitude,
    [TELEPHONE_NUMBER]: carPark.telephoneNumber,
    [BEACON_MAJOR]: carPark.beaconMajor,
    [PARKING_LOTS]: carPark.countParkingLots === '' ? null : carPark.countParkingLots,
    [CONTINUITY_PRICE_IN_CENT]: carPark.continuityPriceInEuro ? carPark.continuityPriceInEuro * 100 : null,
    [CONTINUITY_PERIOD_IN_MIN]: continuityPeriodInMin,
    [PRICE_MAX_PER_DAY_IN_CENT]: carPark.priceMaxPerDayInEuro ? carPark.priceMaxPerDayInEuro * 100 : null,
    [USER_INSIDE_COUNT]: carPark.countUserInside === '' ? null : carPark.countUserInside,
    [OPENING_HOURS]: carPark.openingHours,
    [IS_VISIBLE]: carPark.isVisible,
  }
}

// ----- RESIDENTIAL PARKING -----

export const unpackAssignment = (b) => {
  const { CAR_PARK_UUID, USER_UUID, UUID } = BODY_ASSIGNMENTS

  return {
    carParkUuid: b?.[CAR_PARK_UUID],
    userUuid: b?.[USER_UUID],
    uuid: b?.[UUID],
  }
}

export const unpackAssignments = (assignments) => assignments.map(unpackAssignment)
