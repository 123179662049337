import React from 'react'
import { useTranslation } from 'react-i18next'
import { IconButton, Grid, Tooltip, CircularProgress, Typography, useTheme } from '@mui/material'
import { Autorenew as AutorenewIcon } from '@mui/icons-material'
/* -------------------------------------------------------------------------- */

type LastUpdateInfoProps = {
  lastSuccessDate?: number
  isUpdating?: boolean
  reloadIntervalInMs?: number
  refetch?: () => void
}

export default function LastUpdateInfo(props: LastUpdateInfoProps) {
  const { lastSuccessDate, isUpdating = false, reloadIntervalInMs, refetch } = props
  const theme = useTheme()
  const { t } = useTranslation()

  function getIconOrSpinner() {
    return (
      <Tooltip
        title={
          t('LastUpdateInfo.pageUpdateInterval', {
            interval: (reloadIntervalInMs ?? 0) / 1000,
          }) as string
        }
      >
        {isUpdating ? (
          <CircularProgress size={20} style={{ paddingRight: 5 }} />
        ) : refetch ? (
          <IconButton onClick={refetch} aria-label='refetch' style={{ paddingRight: 5 }} size='small'>
            <AutorenewIcon fontSize='inherit' color='primary' />
          </IconButton>
        ) : (
          <AutorenewIcon fontSize='small' color='primary' style={{ paddingRight: 5 }} />
        )}
      </Tooltip>
    )
  }

  return (
    <Grid container item xs alignItems='center'>
      {getIconOrSpinner()}
      <Typography variant='caption' style={{ color: theme.palette.grey[600] }}>
        {t('LastUpdateInfo.lastUpdateAt', {
          lastSuccessDate: lastSuccessDate ? new Date(lastSuccessDate).toISOString() : null,
        })}
      </Typography>
    </Grid>
  )
}
