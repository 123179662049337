import React from 'react'
import { Chip } from '@mui/material'
/* -------------------------------------------------------------------------- */

type OpeningsProcessTimeCellProps = {
  timeInSec: number
}

function getProcessTimeBadge(timeInSec: number) {
  if (timeInSec <= 15) return { color: 'white', background: '#26a1f1' }
  if (timeInSec <= 30) return { color: 'white', background: '#ffb70f' }
  return { color: 'white', background: '#FF3739' }
}

export default function OpeningsProcessTimeCell(props: OpeningsProcessTimeCellProps) {
  const { timeInSec } = props

  return <Chip size='small' label={timeInSec} style={getProcessTimeBadge(timeInSec)} />
}
