import React from 'react'
import { InputAdornment } from '@mui/material'
import * as PropTypes from 'prop-types'
import { get } from 'lodash'
import { TextField } from 'formik-mui'
import { Field } from 'formik'

import LanguageFlagIcon from 'fragments/Icons/LanguageFlagIcon'
import { localeCodes } from 'util/i18n'
/* -------------------------------------------------------------------------- */

const LangAdornmentTextField = (props) => {
  const { variant, language, name } = props

  return (
    <Field
      component={TextField}
      name={name}
      variant={variant}
      fullWidth
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <LanguageFlagIcon languageCode={language} />
          </InputAdornment>
        ),
      }}
    />
  )
}
/* -------------------------------------------------------------------------- */
LangAdornmentTextField.propTypes = {
  name: PropTypes.string.isRequired,
  language: PropTypes.string,
  variant: PropTypes.string,
}

LangAdornmentTextField.defaultProps = {
  language: get(localeCodes, 'de'),
  variant: 'outlined',
}

export default LangAdornmentTextField
