import React, { useState } from 'react'
import * as PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { FormHelperText } from '@mui/material'

import ConfirmDialog from 'fragments/Dialogs/ConfirmDialog'
import BasicSwitch from 'fragments/Forms/BasicSwitch'
import { editBarrierAct } from 'redux/Actions'
/* -------------------------------------------------------------------------- */

const ToggleBarrierReboot = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { uuid, isUpdateAllowed, isRebootAllowed } = useSelector((state) => state.barrier.barrier)
  const [open, setOpen] = useState(false)

  const { isEdit } = props

  const handleConfirm = () => {
    dispatch(editBarrierAct(uuid, { isRebootAllowed: !isRebootAllowed }))
    setOpen(false)
  }

  return (
    <>
      <ConfirmDialog
        open={open}
        dialogTitle={t('ToggleBarrierReboot.title')}
        dialogContent={
          isRebootAllowed ? t('ToggleBarrierReboot.contentTrigger') : t('ToggleBarrierReboot.contentCancel')
        }
        onConfirm={handleConfirm}
        onClose={() => setOpen(false)}
      />
      <BasicSwitch disabled={isUpdateAllowed || isEdit} checked={isRebootAllowed} onChange={() => setOpen(true)} />
      <FormHelperText>
        {isEdit
          ? t('ToggleBarrierReboot.helperText.editMode')
          : isUpdateAllowed
          ? t('ToggleBarrierReboot.helperText.reboot')
          : null}
      </FormHelperText>
    </>
  )
}

ToggleBarrierReboot.propTypes = {
  isEdit: PropTypes.bool,
}

ToggleBarrierReboot.defaultProps = {
  isEdit: false,
}
export default ToggleBarrierReboot
