import React from 'react'
import * as PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
  Cancel as CancelIcon,
  HourglassFullRounded as HourglassFullRoundedIcon,
  CheckCircle as CheckCircleIcon,
} from '@mui/icons-material'

import IconChip from 'fragments/IconChips/IconChip'
import { PAYMENT_STATUS_VALUES } from 'api/ticketMgmt/ticketMgmtModels'
import { getPaymentStatusString } from 'util/custom'
/* -------------------------------------------------------------------------- */

const PaymentStatusChip = (props) => {
  const { status } = props
  const { t } = useTranslation()
  const { PROCESSING, CANCELED, SUCCEEDED } = PAYMENT_STATUS_VALUES

  const styleLight = { color: '#F4F6F7' }
  const styleDark = { color: '#575756' }

  const obj = {
    [SUCCEEDED]: {
      icon: <CheckCircleIcon style={styleLight} />,
      style: { ...styleLight, background: '#26A1F1' },
    },
    [PROCESSING]: {
      icon: <HourglassFullRoundedIcon style={styleDark} />,
      style: { ...styleDark, background: '#FFCA82' },
    },
    [CANCELED]: {
      icon: <CancelIcon style={styleLight} />,
      style: { ...styleLight, background: '#FF7285' },
    },
  }

  return (
    <IconChip
      icon={obj?.[status]?.icon ?? null}
      label={getPaymentStatusString(t, status)}
      chipStyle={obj?.[status]?.style ?? null}
    />
  )
}

/* -------------------------------------------------------------------------- */
PaymentStatusChip.propTypes = {
  status: PropTypes.string,
}

PaymentStatusChip.defaultProps = {
  status: null,
}

export default PaymentStatusChip
