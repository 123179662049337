import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Grid, Typography } from '@mui/material'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { Formik, Form } from 'formik'

import PageGrid from 'fragments/Grid/PageGrid'
import OperatorGrid from 'components/Operator/OperatorGrid'
import { createOperatorAct, resetCreateOperatorAct } from 'redux/Actions'
import { APP, QUERY_PARAMS } from 'routes/routes'
import { getQueryString, getRequiredInfo } from 'util/core'
import { validationSchema, initialValues } from 'components/Operator/Create/operatorCreate'
import { SaveButton, CancelButton } from 'fragments/Buttons'
/* -------------------------------------------------------------------------- */

const OperatorCreateForm = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const {
    operator: { uuid },
    createOperatorSuccess,
  } = useSelector((state) => state.operator)

  const onKeyDown = (keyEvent) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault()
    }
  }

  useEffect(() => {
    if (createOperatorSuccess) {
      navigate(
        {
          pathname: APP.OPERATOR,
          search: getQueryString({ [QUERY_PARAMS.UUID]: uuid }),
        },
        { state: { from: location } },
      )
    }
    return () => {
      if (createOperatorSuccess) {
        dispatch(resetCreateOperatorAct())
      }
    }
  }, [createOperatorSuccess, dispatch, navigate, uuid, location])

  const handleSubmit = (values) => dispatch(createOperatorAct(values))

  return (
    <Formik
      initialValues={initialValues()}
      validationSchema={validationSchema(t)}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {(formikProps) => {
        const { isValidating, isSubmitting } = formikProps
        return (
          <Form onKeyDown={onKeyDown}>
            <PageGrid justify='space-between'>
              <OperatorGrid isCreate />
              <Grid container justifyContent='space-between' item>
                <Grid item>
                  <Typography variant='body2'>{getRequiredInfo(t)}</Typography>
                </Grid>
                <Grid item xs>
                  <Grid container spacing={2} justifyContent='flex-end'>
                    <Grid item>
                      <CancelButton component={Link} to={APP.OPERATORS} state={{ from: location }} />
                    </Grid>
                    <Grid item>
                      <SaveButton disabled={isValidating || isSubmitting} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </PageGrid>
          </Form>
        )
      }}
    </Formik>
  )
}

export default OperatorCreateForm
