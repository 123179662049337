import { get } from 'lodash'

import { BODY_OPERATORS } from 'api/operatorMgmt/operatorMgmtModels'
import { appendZeroUTC } from 'util/core'
/* -------------------------------------------------------------------------- */

export const unpackOperator = (operator) => {
  const {
    CITY,
    COUNTRY,
    CREATED_AT,
    EMAIL,
    HOUSE_NUMBER,
    NAME,
    PHONE_NUMBER,
    POSTAL_CODE,
    STREET,
    STRIPE_ACCOUNT_ID,
    UPDATED_AT,
    UUID,
  } = BODY_OPERATORS
  return {
    city: get(operator, CITY, null),
    country: get(operator, COUNTRY, null),
    createdAt: appendZeroUTC(get(operator, CREATED_AT, null)),
    email: get(operator, EMAIL, null),
    houseNumber: get(operator, HOUSE_NUMBER, null),
    name: get(operator, NAME, null),
    phoneNumber: get(operator, PHONE_NUMBER, null),
    postalCode: get(operator, POSTAL_CODE, null),
    street: get(operator, STREET, null),
    stripeAccountId: get(operator, STRIPE_ACCOUNT_ID, null),
    updatedAt: appendZeroUTC(get(operator, UPDATED_AT, null)),
    uuid: get(operator, UUID, null),
  }
}

export const unpackOperators = (operators) => operators.map(unpackOperator)

export const pack = (operator) => {
  const { EMAIL, NAME, STREET, HOUSE_NUMBER, POSTAL_CODE, CITY, COUNTRY, PHONE_NUMBER, STRIPE_ACCOUNT_ID } =
    BODY_OPERATORS

  return {
    [EMAIL]: operator.email,
    [NAME]: operator.name,
    [STREET]: operator.street,
    [HOUSE_NUMBER]: operator.houseNumber,
    [POSTAL_CODE]: operator.postalCode,
    [CITY]: operator.city,
    [COUNTRY]: operator.country || 'Deutschland',
    [PHONE_NUMBER]: operator.phoneNumber || null,
    [STRIPE_ACCOUNT_ID]: operator.stripeAccountId,
  }
}
