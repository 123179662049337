import React from 'react'
import { styled } from '@mui/material/styles'
import { MenuItem, Grid, Typography } from '@mui/material'
import { LocalActivity as LocalActivityIcon, Announcement as AnnouncementIcon } from '@mui/icons-material'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { APP, QUERY_PARAMS } from 'routes/routes'
import { getQueryString } from 'util/core'
const PREFIX = 'SearchItem'

const classes = {
  icon: `${PREFIX}-icon`,
}

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  [`& .${classes.icon}`]: {
    paddingRight: theme.spacing(1),
  },
}))

const SearchItem = React.forwardRef((props, ref) => {
  const { t } = useTranslation()

  const { items, isLoading, onSelect } = props
  const location = useLocation()

  function renderFeedback(label) {
    return (
      <StyledMenuItem ref={ref}>
        <Grid container alignItems='center' justifyContent='flex-start'>
          <AnnouncementIcon className={classes.icon} color='secondary' />
          <Typography variant='body1'>{label}</Typography>
        </Grid>
      </StyledMenuItem>
    )
  }

  return isLoading
    ? renderFeedback(t('SearchItems.loading'))
    : items.length === 0
    ? renderFeedback(t('SearchItems.noMatch'))
    : items.map((ticket) => (
        <MenuItem
          key={ticket.publicId}
          ref={ref}
          onClick={onSelect}
          component={Link}
          to={{
            pathname: APP.TICKET,
            search: getQueryString({ [QUERY_PARAMS.PUBLIC_ID]: ticket.publicId }),
          }}
          state={{ from: location }}
        >
          <Grid container alignItems='center' justifyContent='flex-start'>
            <LocalActivityIcon className={classes.icon} color='secondary' />
            <Typography variant='body1'>
              {`${t('SearchItems.ticketId', { id: ticket.publicId })} ${ticket.carParkName}`}
            </Typography>
          </Grid>
        </MenuItem>
      ))
})

export default SearchItem
