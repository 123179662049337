import { createApi } from '@rtk-incubator/rtk-query'

import { getBaseQuery } from 'services/baseQuery'
import { Page } from 'services/services'
import { getQueryString } from 'util/core'
import { BarrierOpening } from 'services/barrier-openings/barrier-openings-service'
/* -------------------------------------------------------------------------- */

export type User = {
  acceptedGdprAt: string
  acceptedTermsAt: string
  appVersion: string
  city: string
  createdAt: string
  deletedAt: string
  device: string
  email: string
  firstName: string
  houseNumber: string
  isDeleted: boolean
  isEmailVerified: boolean
  languageCode: string
  lastName: string
  licensePlate: string
  osVersion: string
  postalCode: string
  setLicensePlateAt: string
  street: string
  updatedAt: string
  uuid: string
}

type OfferVersion = {
  accessEarliestInMinFromMidnight: number
  accessLatestInMinFromMidnight: number
  authorUuid: string
  availableFrom: string
  availableUntil: string
  carParkName: string
  carParkUuid: string
  contingent: number
  createdAt: string
  isDeleted: boolean
  isVisible: boolean
  localizedGeneratedName: {
    de: string
    en: string
  }
  localizedInformation: {
    de: string
    en: string
  }
  localizedName: {
    de: string
    en: string
  }
  offerUuid: string
  priceInCent: number
  ticketUsageInMin: number
  ticketValidityInMin: number
  type: string
  updatedAt: string
  uuid: string
}

export type TicketPaymentStatus =
  | 'requires_payment_method'
  | 'requires_confirmation'
  | 'requires_action'
  | 'requires_capture'
  | 'processing'
  | 'canceled'
  | 'succeeded'

export type TicketPayment = {
  amountInCent: number
  clientSecret: string
  createdAt: string
  status: TicketPaymentStatus
  updatedAt: string
  uuid: string
}

export type TicketUsability = 'NotYetUsable' | 'Usable' | 'NotUsableAnymore'
export type TicketUsage = 'NeverUsed' | 'InUse' | 'NotInUseAnymore'
export type TicketType = 'ADMIN' | 'LONG_TERM' | 'SHORT_TERM'

type TicketsQueryArgs = {
  carParkUuid?: string[]
  deleted?: 'Deleted' | 'NotDeleted' | 'Any'
  operatorUuid?: string
  order?: 'Ascending' | 'Descending'
  pageNumber?: number
  pageSize?: number
  search?: string
  sortBy?: 'CreatedAt' | 'UpdatedAt' | 'ValidUntil'
  type?: TicketType
  usability?: TicketUsability
  usage?: TicketUsage
}

export type ManualOpeningStatus =
  | 'NO_APP_USER'
  | 'BLE_SUCCESS'
  | 'PERMISSION_DENIED'
  | 'UPDATE_TICKET_FAILED'
  | 'REMOTE_SUCCESS'
  | 'REMOTE_FAILURE'
  | 'MANUAL_UNSPECIFIED'
  | 'MANUAL_BARRIER_ERROR'
  | 'MANUAL_APP_ERROR'
  | 'MANUAL_NO_PERMISSION'

export type ManualOpeningQueryArgs = {
  uuid: string
  date: string
  status: ManualOpeningStatus
}

export type Ticket = {
  barrierOpenings: BarrierOpening[]
  bookedUsageFrom: string
  bookedUsageUntil: string
  carParkName: string
  createdAt: string
  offerVersion: OfferVersion
  paidPriceInCent: number
  payments: TicketPayment[]
  publicId: string
  totalPriceAt: string
  totalPriceInCent: number
  updatedAt: string
  usability: TicketUsability
  usage: TicketUsage
  user: User
  uuid: string
  validFrom: string
  validUntil: string
}

export type TicketAdminQueryArgs = {
  carParkUuid: string
  email: string
  validFrom: string
  validUntil: string
}

export const ticketsApi = createApi({
  reducerPath: 'ticketsApi',
  keepUnusedDataFor: 1000 * 60 * 5, // 5 Minutes
  baseQuery: getBaseQuery('tickets'),
  endpoints: (builder) => ({
    getTickets: builder.query<Page<Ticket[]>, TicketsQueryArgs>({
      query: (queryArgs: TicketsQueryArgs) => {
        const {
          carParkUuid,
          deleted,
          operatorUuid,
          order,
          pageNumber = 0,
          pageSize = 10,
          search,
          sortBy,
          type,
          usability,
          usage,
        } = queryArgs

        const params = getQueryString({
          carParkUuid,
          deleted,
          operatorUuid,
          order,
          pageNumber,
          pageSize,
          search,
          sortBy,
          type,
          usability,
          usage,
        })

        return {
          url: params,
        }
      },
    }),
    getTicket: builder.query<Ticket, string>({
      query: (publicId: string) => `public-id/${publicId}`,
    }),
    addManualBarrierOpening: builder.mutation<Ticket, ManualOpeningQueryArgs>({
      query: (queryArgs) => {
        const { uuid, ...body } = queryArgs

        return {
          url: `${uuid}/manual-opening`,
          method: 'POST',
          body,
        }
      },
    }),
    addTicketAdmin: builder.mutation<Ticket, TicketAdminQueryArgs>({
      query: (queryArgs) => {
        const { carParkUuid, email, validFrom, validUntil } = queryArgs
        const body = {
          carParkUuid,
          email,
          validFrom,
          validUntil,
        }

        return {
          url: '/admin',
          method: 'POST',
          body,
        }
      },
    }),
  }),
})

export const {
  useGetTicketsQuery,
  useGetTicketQuery,
  useAddManualBarrierOpeningMutation,
  useAddTicketAdminMutation,
  usePrefetch,
} = ticketsApi
