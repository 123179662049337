import React from 'react'
import { styled } from '@mui/material/styles'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { FormControl, FormGroup, FormControlLabel, Checkbox } from '@mui/material'
import { includes } from 'lodash'

import { setSelectedCarParks } from 'redux/Actions'
const PREFIX = 'CarParksSelector'

const classes = {
  controlLabel: `${PREFIX}-controlLabel`,
}

const StyledFormControl = styled(FormControl)(() => ({
  [`& .${classes.controlLabel}`]: {
    margin: 0,
    padding: '0px 16px 0px 4px',
    height: 35,
  },
}))

const CarParksSelector = () => {
  const { carParks, selectedCarParks } = useSelector((state) => state.globalFilters)

  const dispatch = useDispatch()
  const { t } = useTranslation()

  const defaultSelection = 'ALL'

  const handleChange = ({ target }) => {
    if (target.name === defaultSelection) {
      // deselect all others
      if (target.checked) dispatch(setSelectedCarParks([]))
      // select all others
      else dispatch(setSelectedCarParks(carParks.map((o) => o.uuid)))
    } else {
      if (target.checked) {
        // add option
        dispatch(setSelectedCarParks(selectedCarParks.concat(target.id)))
      } else {
        // remove option
        dispatch(setSelectedCarParks(selectedCarParks.filter((option) => option !== target.id)))
      }
    }
  }

  return (
    <StyledFormControl component='fieldset'>
      <FormGroup aria-label='Car parks select' name='carParksSelect'>
        <FormControlLabel
          control={
            <Checkbox
              checked={selectedCarParks.length === 0}
              onChange={handleChange}
              name={defaultSelection}
              color='primary'
            />
          }
          label={t('CarParksSelector.all')}
          classes={{ root: classes.controlLabel }}
        />
        {carParks.map((carPark) => (
          <FormControlLabel
            key={carPark.uuid}
            control={
              <Checkbox
                checked={includes(selectedCarParks, carPark.uuid)}
                onChange={handleChange}
                name={carPark.name}
                id={carPark.uuid}
                color='primary'
              />
            }
            label={carPark.name}
            classes={{ root: classes.controlLabel }}
          />
        ))}
      </FormGroup>
    </StyledFormControl>
  )
}

export default CarParksSelector
