import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  BluetoothDisabled as BluetoothDisabledIcon,
  BluetoothSearching as BluetoothSearchingIcon,
} from '@mui/icons-material'
import * as PropTypes from 'prop-types'

import BinaryIconChip from 'fragments/IconChips/BinaryIconChip'
import { getDeviceOfflineTimeAsString } from 'util/custom'
/* -------------------------------------------------------------------------- */
const BeaconStatusChip = (props) => {
  const { isOnline, lastSeenAt } = props
  const { t } = useTranslation()

  return (
    <BinaryIconChip
      condition={lastSeenAt ? isOnline : null}
      falseIcon={BluetoothDisabledIcon}
      falseLabel={getDeviceOfflineTimeAsString(t, lastSeenAt)}
      trueIcon={BluetoothSearchingIcon}
      trueLabel={t('BeaconStatusChip.broadcasting')}
    />
  )
}

/* -------------------------------------------------------------------------- */
BeaconStatusChip.propTypes = {
  isOnline: PropTypes.bool,
  lastSeenAt: PropTypes.string,
}

BeaconStatusChip.defaultProps = {
  isOnline: false,
  lastSeenAt: null,
}

export default BeaconStatusChip
