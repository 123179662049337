import React from 'react'
import { styled } from '@mui/material/styles'
import * as PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { AppBar, Grid, Toolbar, IconButton, Tooltip, Paper } from '@mui/material'
import { FirstPage as FirstPageIcon, LastPage as LastPageIcon, Menu as MenuIcon } from '@mui/icons-material'

import UserMenu from 'layouts/AppLayout/Topbar/UserMenu'
import Search from 'layouts/AppLayout/Topbar/Search'
import GlobalFilter from 'layouts/AppLayout/Topbar/GlobalFilter'
import { useDesktop } from 'hooks'
const PREFIX = 'Topbar'

const classes = {
  topbar: `${PREFIX}-topbar`,
  iconButton: `${PREFIX}-iconButton`,
  icon: `${PREFIX}-icon`,
}

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  [`&.${classes.topbar}`]: {
    background: '#fff',
    color: '#7b7b7b',
    boxShadow: 'none',
  },

  [`& .${classes.iconButton}`]: {
    paddingRight: theme.spacing(2),
    marginRight: theme.spacing(1),
  },

  [`& .${classes.icon}`]: {
    color: '#575756',
  },
}))

const Topbar = (props) => {
  const { t } = useTranslation()

  const isDesktop = useDesktop()
  const { toggleSidebar, isCollapsed } = props

  return (
    <StyledAppBar position='absolute' className={classes.topbar}>
      <Paper square>
        <Toolbar component={Grid} container>
          <Grid item xs={8} container alignItems='center'>
            <Tooltip title={isCollapsed ? t('Topbar.show') : t('Topbar.hide')} placement='bottom'>
              <IconButton
                edge='start'
                onClick={toggleSidebar}
                color='inherit'
                aria-label='menu'
                className={classes.iconButton}
                size='large'
              >
                {!isDesktop ? (
                  <MenuIcon className={classes.icon} />
                ) : isCollapsed ? (
                  <LastPageIcon className={classes.icon} />
                ) : (
                  <FirstPageIcon className={classes.icon} />
                )}
              </IconButton>
            </Tooltip>
            <Grid item xs={9}>
              <Search />
            </Grid>
          </Grid>
          <Grid item xs={4} container justifyContent='flex-end' alignItems='center'>
            <GlobalFilter />
            <UserMenu />
          </Grid>
        </Toolbar>
      </Paper>
    </StyledAppBar>
  )
}

Topbar.propTypes = {
  isCollapsed: PropTypes.bool.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
}

export default Topbar
