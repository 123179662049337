import { call, put, takeLatest, all, fork } from 'redux-saga/effects'

import {
  GET_CAR_PARK_BY_UUID,
  EDIT_CAR_PARK,
  CREATE_CAR_PARK,
  EDIT_CAR_PARK_RESIDENTIAL_PARKING,
  REMOVE_FROM_CAR_PARK_RESIDENTIAL_PARKING,
} from 'redux/Types'
import {
  getCarParkByUuidSuccessAct,
  getCarParkByUuidErrorAct,
  catchSagaErrorsAct,
  editCarParkSuccessAct,
  editCarParkErrorAct,
  createCarParkSuccessAct,
  createCarParkErrorAct,
} from 'redux/Actions'
import {
  getCarParkByUuidReq,
  editCarParkByUuidReq,
  createCarParkReq,
  addUseremailToCarParkResidentialParkingReq,
  removeUserFromCarParkAssignment,
} from 'api/requests'
import { unpackCarPark } from 'api/carParkMgmt/carParkMgmtUnpackers'
/* -------------------------------------------------------------------------- */

// ----- LOAD -----

function* workerGetCarParkByUuid({ uuid }) {
  try {
    const response = yield call(getCarParkByUuidReq, uuid)
    const carPark = unpackCarPark(response)
    yield put(getCarParkByUuidSuccessAct(carPark))
  } catch (error) {
    yield put(catchSagaErrorsAct(error, getCarParkByUuidErrorAct))
  }
}

function* watcherGetCarParkByUuid() {
  yield takeLatest(GET_CAR_PARK_BY_UUID.REQUEST, workerGetCarParkByUuid)
}

// ----- EDIT  -----

function* workerEditCarPark(action) {
  try {
    const { carParkUpdatable, uuid } = action
    const carPark = yield call(editCarParkByUuidReq, uuid, carParkUpdatable)
    yield put(editCarParkSuccessAct(carPark))
  } catch (error) {
    yield put(catchSagaErrorsAct(error, editCarParkErrorAct))
  }
}

function* watcherEditCarPark() {
  yield takeLatest(EDIT_CAR_PARK.REQUEST, workerEditCarPark)
}

// ----- RESIDENTIAL PARKING  -----

function* workerAddUserToCarParkResidentialParking(action) {
  try {
    const { userEmail, carParkUuid } = action
    yield call(addUseremailToCarParkResidentialParkingReq, userEmail, carParkUuid)
    const response = yield call(getCarParkByUuidReq, carParkUuid)
    yield put(editCarParkSuccessAct(response))
  } catch (error) {
    yield put(catchSagaErrorsAct(error, editCarParkErrorAct))
  }
}

function* watcherAddUserToCarParkResidentialParking() {
  yield takeLatest(EDIT_CAR_PARK_RESIDENTIAL_PARKING, workerAddUserToCarParkResidentialParking)
}

function* workerRemoveUserFromCarParkResidentialParking(action) {
  try {
    const { assignmentUuid, carParkUuid } = action
    yield call(removeUserFromCarParkAssignment, assignmentUuid)
    const response = yield call(getCarParkByUuidReq, carParkUuid)
    yield put(editCarParkSuccessAct(response))
  } catch (error) {
    yield put(catchSagaErrorsAct(error, editCarParkErrorAct))
  }
}

function* watcherRemoveUserFromCarParkResidentialParking() {
  yield takeLatest(REMOVE_FROM_CAR_PARK_RESIDENTIAL_PARKING, workerRemoveUserFromCarParkResidentialParking)
}
// ----- CREATE  -----

function* workerCreateCarPark(action) {
  try {
    const { carParkCreatable } = action
    const carPark = yield call(createCarParkReq, carParkCreatable)
    yield put(createCarParkSuccessAct(carPark))
  } catch (error) {
    yield put(catchSagaErrorsAct(error, createCarParkErrorAct))
  }
}

function* watcherCreateCarPark() {
  yield takeLatest(CREATE_CAR_PARK.REQUEST, workerCreateCarPark)
}

// ----- EXPORT -----
export default function* rootSaga() {
  yield all([
    fork(watcherGetCarParkByUuid),
    fork(watcherEditCarPark),
    fork(watcherCreateCarPark),
    fork(watcherAddUserToCarParkResidentialParking),
    fork(watcherRemoveUserFromCarParkResidentialParking),
  ])
}
