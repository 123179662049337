import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { BasicCardLoadable } from 'fragments/Loadables'
import MuiDataTableThemed from 'fragments/tables/MuiDataTableThemed'
import columns from 'components/Clients/AppUsers/AppUsersTableColumns'
import options from 'components/Clients/AppUsers/appUsersTableOptions'
/* -------------------------------------------------------------------------- */

const AppUsersTableCard = () => {
  const {
    isGettingAppUsers,
    appUsers,
    pagination: { page, rows, totalCount },
  } = useSelector((state) => state.appUsers)
  const {
    user: { role },
  } = useSelector((state) => state.auth)
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const dispatch = useDispatch()
  let navigate = useNavigate()

  return (
    <BasicCardLoadable isLoading={isGettingAppUsers} title={t('AppUsersTableCard.title')}>
      <MuiDataTableThemed
        data={appUsers}
        columns={columns({ language, appUsers, role, t })}
        options={options({ dispatch, appUsers, history: navigate, page, rows, totalCount })}
      />
    </BasicCardLoadable>
  )
}

export default AppUsersTableCard
