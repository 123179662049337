import { get } from 'lodash'

import { muiDataTableOptions } from 'fragments/tables/TableConfig'
import { getCarParksAct } from 'redux/Actions'
import { APP, QUERY_PARAMS } from 'routes/routes'
import { getQueryString } from 'util/core'
/* -------------------------------------------------------------------------- */

const options = ({ carParks, dispatch, history: navigate, page, rows, totalCount }) => ({
  count: totalCount,
  onChangePage: (page) =>
    dispatch(
      getCarParksAct({
        page,
      }),
    ),
  onChangeRowsPerPage: (rows) =>
    dispatch(
      getCarParksAct({
        rows,
      }),
    ),
  onRowClick: (_, { dataIndex }) => {
    const uuid = get(carParks, `[${dataIndex}].uuid`, null)
    if (uuid) {
      navigate({
        pathname: APP.CAR_PARK_INFO,
        search: getQueryString({
          [QUERY_PARAMS.UUID]: uuid,
        }),
      })
    }
  },
  page,
  rowsPerPage: rows,
  serverSide: true,
})

export default (args) => ({
  ...muiDataTableOptions(),
  ...options(args),
})
