import { GET_APP_USERS } from 'redux/Types'
/* -------------------------------------------------------------------------- */

export const getAppUsersAct = (params) => ({
  type: GET_APP_USERS.REQUEST,
  isReload: params?.isReload,
  page: params?.page,
  rows: params?.rows,
})

export const getAppUsersSuccessAct = (data) => ({
  type: GET_APP_USERS.SUCCESS,
  data,
})

export const getAppUsersErrorAct = (error) => ({
  type: GET_APP_USERS.ERROR,
  error,
})
