import React from 'react'
import { styled } from '@mui/material/styles'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material'

import { setSelectedOperatorAct } from 'redux/Actions'
const PREFIX = 'OperatorSelector'

const classes = {
  label: `${PREFIX}-label`,
}

const StyledFormControl = styled(FormControl)(() => ({
  [`& .${classes.label}`]: {
    margin: 0,
    padding: '0px 16px 0px 4px',
    height: 35,
  },
}))

const OperatorSelector = () => {
  const { operators } = useSelector((state) => state.operators)
  const { selectedOperator } = useSelector((state) => state.globalFilters)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const defaultSelection = 'ALL'

  const handleChange = ({ target }) => {
    const selected = target.value
    dispatch(setSelectedOperatorAct(selected === defaultSelection ? null : selected))
  }

  return (
    <StyledFormControl component='fieldset'>
      <RadioGroup
        value={selectedOperator || defaultSelection}
        onChange={handleChange}
        aria-label='Operator Select'
        name='operatorSelect'
      >
        <FormControlLabel
          value={defaultSelection}
          control={<Radio color='primary' />}
          label={t('OperatorSelector.all')}
          classes={{ root: classes.label }}
        />
        {operators.map((o) => (
          <FormControlLabel
            key={o.uuid}
            value={o.uuid}
            control={<Radio color='primary' />}
            label={o.name}
            classes={{ root: classes.label }}
          />
        ))}
      </RadioGroup>
    </StyledFormControl>
  )
}

export default OperatorSelector
