import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Paper, Tabs, Tab } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { uniqueId } from 'lodash'

import tabConfig from 'components/Sales/tabConfig'
import { useDesktop } from 'hooks'
/* -------------------------------------------------------------------------- */

const SalesTabBar = () => {
  const { t } = useTranslation()
  let navigate = useNavigate()
  const location = useLocation()
  const isDesktop = useDesktop()
  const tabNum = tabConfig.findIndex((tab) => tab.path === location.pathname)
  const [tab, setTab] = useState(tabNum ? tabNum : 0)

  useEffect(() => {
    setTab(tabNum)
  }, [location.pathname, tabNum])

  const a11yProps = (index) => ({
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  })

  const handleChange = (_, newTab) => {
    setTab(newTab)
    navigate(
      {
        pathname: tabConfig[newTab].path,
      },
      { replace: true, state: { from: location } },
    )
  }

  return (
    <Paper>
      <Tabs
        value={tab}
        onChange={handleChange}
        aria-label='Tabs'
        indicatorColor='primary'
        variant='scrollable'
        textColor='primary'
      >
        {tabConfig.map((tab, index) => (
          <Tab
            key={uniqueId()}
            label={
              // i18next-extract-mark-context-next-line ["month", "year"]
              t('SalesTabBar.tabs', { context: tab.label })
            }
            {...a11yProps(index)}
            style={{ textTransform: 'none', minWidth: isDesktop ? '160px' : '90px' }}
          />
        ))}
      </Tabs>
    </Paper>
  )
}

export default SalesTabBar
