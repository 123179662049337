import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { LocalParking as LocalParkingIcon } from '@mui/icons-material'
import { isEmpty } from 'lodash'

import { getCarParksAct } from 'redux/Actions'
import { BasicCardLoadable } from 'fragments/Loadables'
import CarParksMultipleSelect from 'fragments/Forms/Formik/CarParksMultipleSelect'
import PlaceHolderText from 'fragments/PlaceHolderText'
import { isPending } from 'redux/Status'
/* -------------------------------------------------------------------------- */

const CarParksCard = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { isGettingAdmin, isReGettingAdmin, statusCreatingAdmin, admin } = useSelector((state) => state.admin)
  const carParks = useSelector((state) => state.carParks.carParks)
  const { isCreate, isEdit, formikProps } = props
  const setFieldValue = formikProps?.setFieldValue

  const operatorUuid = admin.operatorUuid
  const carParkUuids = admin.carParkUuids ?? []
  const carParksList = carParkUuids.map((uuid) => {
    return {
      uuid: uuid,
      name: carParks.find((cp) => cp.uuid === uuid)?.name ?? null,
    }
  })

  useEffect(() => {
    return () => {
      if (setFieldValue) {
        setFieldValue('carParkUuids', [])
      }
    }
  }, [setFieldValue])

  useEffect(() => {
    dispatch(getCarParksAct({ operatorUuid }))
  }, [dispatch, operatorUuid])

  const carParkList = () =>
    isEmpty(carParksList) ? (
      <PlaceHolderText text={t('CarParksCard.noCarParks')} />
    ) : (
      carParksList.map((carPark) => {
        return (
          <Grid key={carPark?.uuid} item xs={12}>
            {carPark?.name ?? <PlaceHolderText text={t('CarParksCard.unknownCarPark')} />}
          </Grid>
        )
      })
    )

  const carParksSelect = () => <CarParksMultipleSelect name='carParkUuids' />

  return (
    <BasicCardLoadable
      title={t('CarParksCard.title')}
      action={<LocalParkingIcon />}
      isLoading={isGettingAdmin || isReGettingAdmin || isPending(statusCreatingAdmin)}
    >
      <Grid container spacing={1}>
        {isCreate || isEdit ? carParksSelect() : carParkList()}
      </Grid>
    </BasicCardLoadable>
  )
}

CarParksCard.propTypes = {
  formikProps: PropTypes.shape(),
  isCreate: PropTypes.bool,
  isEdit: PropTypes.bool,
}

CarParksCard.defaultProps = {
  formikProps: null,
  isCreate: false,
  isEdit: false,
}

export default CarParksCard
