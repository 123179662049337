import { GET_APP_USERS } from 'redux/Types'
import { unpackAppUsers } from 'api/appUserMgmt/appUserMgmtUnpackers'
import { BODY_PAGINATION } from 'api/backendConstants'
/* -------------------------------------------------------------------------- */

const INIT_STATE = {
  appUsers: [],
  isGettingAppUsers: false,
  errGettingAppUsers: {
    httpStatusCode: null,
    errorCode: null,
    message: null,
  },
  lastSuccessDate: new Date(),
  pagination: {
    totalCount: 0,
    page: 0,
    rows: 25,
  },
}

export default (state = INIT_STATE, action) => {
  const { type } = action
  switch (type) {
    case GET_APP_USERS.REQUEST: {
      const { page, rows } = action
      return {
        ...state,
        isGettingAppUsers: true,
        errGettingAppUsers: INIT_STATE.errGettingAppUsers,
        pagination: {
          ...state.pagination,
          page: page ?? state.pagination.page,
          rows: rows ?? state.pagination.rows,
        },
      }
    }
    case GET_APP_USERS.SUCCESS: {
      const { data } = action
      const { CONTENT_ARR: APPUSERS, TOTAL_ELEMENTS, PAGE_NUMBER, PAGE_SIZE } = BODY_PAGINATION
      const appusers = data[APPUSERS]
      return {
        ...state,
        isGettingAppUsers: false,
        appUsers: unpackAppUsers(appusers),
        lastSuccessDate: new Date(),
        pagination: {
          totalCount: data?.[TOTAL_ELEMENTS] ?? state.pagination.totalCount,
          page: data?.[PAGE_NUMBER] ?? state.pagination.page,
          rows: (data?.[PAGE_SIZE] ?? state.pagination.rows) || 10,
        },
      }
    }
    case GET_APP_USERS.ERROR: {
      const { error } = action
      return {
        ...state,
        isGettingAppUsers: false,
        errGettingAppUsers: error,
        pagination: INIT_STATE.pagination,
      }
    }
    default:
      return { ...state }
  }
}
