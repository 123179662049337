import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import * as PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'
import clsx from 'clsx'
import { Hidden, Drawer } from '@mui/material'

import Topbar from 'layouts/AppLayout/Topbar/Topbar'
import Sidebar from 'layouts/AppLayout/Sidebar/Sidebar'
import { useDesktop, usePersistedState } from 'hooks'
import { useTheme } from '@mui/material'
const PREFIX = 'AppLayout'

const classes = {
  appContainer: `${PREFIX}-appContainer`,
  headerContainer: `${PREFIX}-headerContainer`,
  desktopHeader: `${PREFIX}-desktopHeader`,
  desktopHeaderCollapsed: `${PREFIX}-desktopHeaderCollapsed`,
  sidebarContainer: `${PREFIX}-sidebarContainer`,
  mobileSidebar: `${PREFIX}-mobileSidebar`,
  desktopSidebar: `${PREFIX}-desktopSidebar`,
  desktopSidebarCollapsed: `${PREFIX}-desktopSidebarCollapsed`,
  drawerMobile: `${PREFIX}-drawerMobile`,
  drawerDesktop: `${PREFIX}-drawerDesktop`,
  desktopCollapsed: `${PREFIX}-desktopCollapsed`,
  topBarSpacer: `${PREFIX}-topBarSpacer`,
  content: `${PREFIX}-content`,
  footer: `${PREFIX}-footer`,
}

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.appContainer}`]: {
    display: 'flex',
  },

  [`& .${classes.headerContainer}`]: {
    top: 0,
    left: 0,
    right: 0,
    position: 'absolute',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  [`& .${classes.desktopHeader}`]: {
    left: 'auto',
    width: `calc(100% - ${theme.sidebar.width}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  [`& .${classes.desktopHeaderCollapsed}`]: {
    width: `calc(100% - ${theme.sidebar.widthCollapsed}px)`,
  },

  [`& .${classes.sidebarContainer}`]: {
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      width: theme.sidebar.width,
      flexShrink: 0,
    },
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  [`& .${classes.mobileSidebar}`]: {
    width: 0,
  },

  [`& .${classes.desktopSidebar}`]: {
    width: theme.sidebar.width,
  },

  [`& .${classes.desktopSidebarCollapsed}`]: {
    [theme.breakpoints.up('md')]: {
      width: theme.sidebar.widthCollapsed,
    },
  },

  [`& .${classes.drawerMobile}`]: {
    width: theme.sidebar.width,
  },

  [`& .${classes.drawerDesktop}`]: {
    width: '100%',
    position: 'absolute',
  },

  [`& .${classes.desktopCollapsed}`]: {
    overflowX: 'hidden',
  },

  [`& .${classes.topBarSpacer}`]: theme.mixins.toolbar,

  [`& .${classes.content}`]: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },

  [`& .${classes.footer}`]: {
    height: '100%',
    display: 'flex',
    alignItems: 'flex-end',
  },
}))

const AppLayout = (props) => {
  const isDesktop = useDesktop()
  const theme = useTheme()

  const [isMobileSidebarOpen, toggleMobileSidebar] = useState(false)
  const [isDesktopSidebarOpen, toggleDesktopSidebar] = usePersistedState('isDesktopSidebarOpen', true)

  const { children } = props

  const isCollapsed = isDesktop ? !isDesktopSidebarOpen : !isMobileSidebarOpen

  const handleMobileSidebarToggle = () => toggleMobileSidebar((isMobileSidebarOpen) => !isMobileSidebarOpen)

  const handleSidebarToggle = () =>
    isDesktop
      ? toggleDesktopSidebar((isDesktopSidebarOpen) => !isDesktopSidebarOpen)
      : toggleMobileSidebar((isMobileSidebarOpen) => !isMobileSidebarOpen)

  useEffect(() => {
    if (isDesktop) {
      toggleMobileSidebar(false)
    }
  }, [isDesktop])

  return (
    <Root>
      {process.env.REACT_APP_GA_TRACKING === 'true' && (
        <Helmet>
          <script
            async
            src={`${process.env.REACT_APP_GA_TRACKING_URL}${process.env.REACT_APP_GA_TRACKING_ID}`}
          ></script>
          <script>
            {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${process.env.REACT_APP_GA_TRACKING_ID}', { 'anonymize_ip': true });
      `}
          </script>
        </Helmet>
      )}
      <div className={classes.appContainer}>
        <div
          className={clsx(
            classes.headerContainer,
            isDesktop && classes.desktopHeader,
            isDesktop && !isDesktopSidebarOpen && classes.desktopHeaderCollapsed,
          )}
        >
          <Topbar isCollapsed={isCollapsed} toggleSidebar={handleSidebarToggle} />
        </div>
        <div
          className={clsx(classes.sidebarContainer, {
            [classes.mobileSidebar]: !isDesktop,
            [classes.desktopSidebar]: isDesktop,
            [classes.desktopSidebarCollapsed]: isDesktop && !isDesktopSidebarOpen,
          })}
        >
          {/* DESKTOP */}
          <Hidden mdUp implementation='css'>
            <Drawer
              variant='temporary'
              open={isMobileSidebarOpen}
              onClose={handleMobileSidebarToggle}
              PaperProps={{
                sx: {
                  width: theme.sidebar.width,
                },
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              <Sidebar isCollapsed={isCollapsed} isDesktop={isDesktop} onSelect={() => toggleMobileSidebar(false)} />
            </Drawer>
          </Hidden>
          {/* MOBILE */}
          <Hidden mdDown implementation='css'>
            <Drawer
              variant='permanent'
              classes={{
                paper: clsx(classes.drawerDesktop, { [classes.desktopCollapsed]: !isDesktopSidebarOpen }),
              }}
            >
              <Sidebar isCollapsed={isCollapsed} isDesktop={isDesktop} />
            </Drawer>
          </Hidden>
          {/* END */}
        </div>
        <main className={classes.content}>
          <div className={classes.topBarSpacer} />
          {children}
        </main>
      </div>
    </Root>
  )
}

AppLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
}

export default AppLayout
