import React from 'react'
import { Grid, Link as MuiLink } from '@mui/material'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { LocalActivity as LocalActivityIcon } from '@mui/icons-material'

import InfoRows from 'fragments/Cards/InfoRows'
import { BasicCardLoadable } from 'fragments/Loadables'
import { APP, QUERY_PARAMS } from 'routes/routes'
import { getQueryString } from 'util/core'
import { getOfferTypeAsString } from 'util/custom'
import { Ticket } from 'services/tickets/tickets-service'
/* -------------------------------------------------------------------------- */

type TicketInfoCardProps = {
  ticket?: Ticket
  isLoading: boolean
}

export default function TicketInfoCard(props: TicketInfoCardProps) {
  const { ticket, isLoading } = props
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const location = useLocation()

  const ticketName =
    ticket?.offerVersion?.localizedName?.[language as 'en' | 'de'] ||
    ticket?.offerVersion?.localizedGeneratedName?.[language as 'en' | 'de']

  const toOffer = {
    pathname: APP.OFFER,
    search: getQueryString({
      [QUERY_PARAMS.UUID]: ticket?.offerVersion?.offerUuid,
      [QUERY_PARAMS.VERSION]: ticket?.offerVersion?.uuid,
    }),
  }

  const toCarPark = {
    pathname: APP.CAR_PARK_INFO,
    search: getQueryString({ [QUERY_PARAMS.UUID]: ticket?.offerVersion?.carParkUuid }),
  }

  const getOfferNameValue = () =>
    ticketName && (
      <MuiLink component={RouterLink} to={toOffer} state={{ from: location }} target='_blank' underline='hover'>
        {ticketName}
      </MuiLink>
    )

  const getCarParkNameValue = () =>
    ticket?.offerVersion?.carParkUuid && (
      <MuiLink component={RouterLink} to={toCarPark} state={{ from: location }} target='_blank' underline='hover'>
        {ticket?.carParkName}
      </MuiLink>
    )

  const rows = [
    {
      label: t('TicketInfoCard.offer') as string,
      value: getOfferNameValue(),
    },
    {
      label: t('TicketInfoCard.type') as string,
      value: getOfferTypeAsString(t, ticket?.offerVersion?.type, { returnNull: true }),
    },
    {
      label: t('TicketInfoCard.carPark') as string,
      value: getCarParkNameValue(),
    },
  ]

  return (
    <BasicCardLoadable isLoading={isLoading} title={t('TicketInfoCard.info')} action={<LocalActivityIcon />}>
      <Grid container spacing={1}>
        <InfoRows rows={rows} />
      </Grid>
    </BasicCardLoadable>
  )
}
