import React from 'react'
import { styled } from '@mui/material/styles'
import * as PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Chip } from '@mui/material'
import { Help as HelpIcon } from '@mui/icons-material'
const PREFIX = 'IconChip'

const classes = {
  label: `${PREFIX}-label`,
}

const StyledHelpIcon = styled(HelpIcon)({
  [`& .${classes.label}`]: {
    minWidth: 66,
    textAlign: 'center',
  },
})

const IconChip = (props) => {
  const { icon, label, size, chipStyle } = props
  const { t } = useTranslation()

  const getChipLabel = (label) => {
    return <div className={classes.label}>{label ?? t('IconChip.unknown')}</div>
  }
  return <Chip icon={icon} label={getChipLabel(label)} size={size} style={chipStyle} />
}

/* -------------------------------------------------------------------------- */
IconChip.propTypes = {
  icon: PropTypes.node,
  label: PropTypes.string,
  size: PropTypes.string,
  chipStyle: PropTypes.shape({}),
}

IconChip.defaultProps = {
  icon: <StyledHelpIcon style={{ color: 'white' }} />,
  size: 'small',
  label: null,
  chipStyle: {
    color: 'white',
    background: 'grey',
  },
}

export default IconChip
