import { GET_CAR_PARK_RESIDENTIAL_PARKING } from 'redux/Types'
/* -------------------------------------------------------------------------- */

export const getResidentialParkingAssignmentsByCarParkUuidAct = (params) => ({
  type: GET_CAR_PARK_RESIDENTIAL_PARKING.REQUEST,
  carParkUuid: params?.carParkUuid,
  page: params?.page,
  rows: params?.rows,
})

export const getResidentialParkingAssignmentsByCarParkUuidSuccessAct = (data) => ({
  type: GET_CAR_PARK_RESIDENTIAL_PARKING.SUCCESS,
  data,
})

export const getResidentialParkingAssignmentsByCarParkUuidErrorAct = (error) => ({
  type: GET_CAR_PARK_RESIDENTIAL_PARKING.ERROR,
  error,
})
