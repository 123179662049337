import React from 'react'
import { styled } from '@mui/material/styles'
import * as PropTypes from 'prop-types'
import { Grid, Typography, Box, LinearProgress, Link as MuiLink, useTheme, useMediaQuery } from '@mui/material'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

import AuthFooter from 'layouts/AuthLayout/AuthFooter'
const PREFIX = 'AuthLayout'

const classes = {
  childrenContent: `${PREFIX}-childrenContent`,
  demoAccess: `${PREFIX}-demoAccess`,
}

const Root = styled('main')(({ theme }) => ({
  [`& .${classes.childrenContent}`]: {
    maxWidth: 532,
    maxHeight: 582,
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },

  [`& .${classes.demoAccess}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}))

const AuthLayout = (props) => {
  const { t } = useTranslation()

  const theme = useTheme()
  const mdDown = useMediaQuery(theme.breakpoints.down('lg'))
  const smDown = useMediaQuery(theme.breakpoints.down('md'))
  const { children, isLoading } = props

  return (
    <Root>
      <Grid container direction='column' alignItems='center' wrap='nowrap'>
        <Box m={smDown ? 1 : 4} />
        {isLoading && <LinearProgress />}
        <div className={classes.childrenContent}>{children}</div>
        <Box m={2} />
        <Typography variant='subtitle1' className={clsx({ [classes.demoAccess]: mdDown })}>
          {t('AuthLayout.getAccount')}{' '}
          <MuiLink
            href='https://parkmotive.com/kontakt.html'
            id='contact'
            target='_blank'
            rel='noopener noreferrer'
            underline='hover'
          >
            {t('AuthLayout.contactUs')}
          </MuiLink>
        </Typography>
        <AuthFooter />
      </Grid>
    </Root>
  )
}

AuthLayout.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
}

AuthLayout.defaultProps = {
  isLoading: false,
}

export default AuthLayout
