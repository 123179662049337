import React from 'react'
import { Grid, Button } from '@mui/material'
import { FilterList as FilterListIcon } from '@mui/icons-material'
import * as PropTypes from 'prop-types'
import { uniqueId } from 'lodash'
import { useTranslation } from 'react-i18next'

import { BasicCardLoadable } from 'fragments/Loadables'
/* -------------------------------------------------------------------------- */
const FilterGridCard = (props) => {
  const { isLoading, children, resetFilters } = props
  const { t } = useTranslation()

  const action = resetFilters ? (
    <Button type='button' onClick={resetFilters} color='primary' style={{ textTransform: 'none' }}>
      {t('FilterGridCard.resetFilter')}
    </Button>
  ) : (
    <FilterListIcon />
  )

  return (
    <BasicCardLoadable action={action} isLoading={isLoading} title={t('FilterGridCard.filter')}>
      <Grid container justifyContent='flex-start' alignItems='flex-end' spacing={1}>
        {[].concat(children).map((child) => {
          return (
            child && (
              <Grid item key={uniqueId()} xs={12} sm={6} md={3}>
                {child}
              </Grid>
            )
          )
        })}
      </Grid>
    </BasicCardLoadable>
  )
}

/* ----- SETUP -------------------------------------------------------------- */

FilterGridCard.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  resetFilters: PropTypes.func,
}

FilterGridCard.defaultProps = {
  resetFilters: null,
}

export default FilterGridCard
