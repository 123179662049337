import React from 'react'
import { Route, Routes } from 'react-router-dom'

import AuthLayout from 'layouts/AuthLayout/AuthLayout'
import LoginContainer from 'components/Login/LoginContainer'
import ResetPasswordRequestContainer from 'components/Password/ResetPasswordRequestContainer'
import ResetPasswordContainer from 'components/Password/ResetPasswordContainer'
import ActivateAdminContainer from 'components/Admin/Activate/ActivateAdminContainer'
import ROUTES from 'routes/routes'
/* -------------------------------------------------------------------------- */

const AuthRoutes = () => (
  <AuthLayout>
    <Routes>
      <Route path={ROUTES.AUTH.RESET_PW_REQUEST} element={<ResetPasswordRequestContainer />} />
      <Route path={ROUTES.AUTH.RESET_PW} element={<ResetPasswordContainer />} />
      <Route path={ROUTES.AUTH.ACTIVATE_ADMIN} element={<ActivateAdminContainer />} />
      <Route path={`${ROUTES.AUTH.SIGN_IN}/*`} element={<LoginContainer />} />
      <Route path={`/${ROUTES.CATCH_ALL}`} element={<LoginContainer />} />
    </Routes>
  </AuthLayout>
)

export default AuthRoutes
